<template>
  <v-card>
    <v-toolbar style="background-color: #1daf80; color: #fff">
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>
        <span class="headline">Nova Consulta</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items> </v-toolbar-items>
    </v-toolbar>
    <div class="row justify-content-center mt-5">
      <div class="col-lg-10 form-group clearfix mb-0">
        <v-select
          style="padding-top: 0 !important"
          :items="petList"
          v-model="petId"
          label="Selecione o Pet"
        />
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-10 form-group clearfix mb-0">
        <v-select
          style="padding-top: 0 !important"
          :items="procedimentos"
          v-model="procedimentoId"
          label="Selecione o Procedimento"
        />
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-10 form-group clearfix mb-0">
        <v-textarea
          label="Observação"
          v-model="observacao"
          placeholder=" "
          rows="3"
        ></v-textarea>
      </div>
    </div>

    <v-card-actions>
      <div class="row justify-content-center">
        <div class="col-10 text-end">
          <v-btn
            class="mb-2"
            outlined
            color="#1daf80"
            :disabled="validarCadastro"
            @click="cadastarConsulta"
          >
            Cadastrar
          </v-btn>
        </div>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import PacienteService from "@/services/paciente_service.js";
import PetsService from "@/services/pets_service.js";
import AgendaEspecialidadeService from "@/services/agendamento_especialidade_recepcao_service.js";
import VuexSnackbarMixin from "@/mixins/vuex_snackbar_mixin.js";

export default {
  mixins: [VuexSnackbarMixin],
  components: {},
  props: {
    horarioProp: Object,
  },
  data: () => ({
    loadingInicial: false,
    idCliente: 0,
    clientes: [],
    petId: 0,
    petList: [],
    especialidadeId: 0,
    procedimentoId: 0,
    procedimentos: [],
    dialogCadastrarPet: false,
    dialogCadastroTutor: false,
    validarCadastro: true,
    search: "",
    observacao: "",
    horarioId: 0,
    services: {
      pacienteService: new PacienteService(),
      petService: PetsService.build(),
      agendaEspecialidadeService: AgendaEspecialidadeService.build(),
    },
    pesquisaTutor: 1,
    pesquisaTutorLista: [
      { value: 1, text: "Nome Pet / CPF" },
      { value: 2, text: "Id Pet" },
    ],
  }),
  mounted() {
    this.getPetsByIdTutor();
    this.idTutor = JSON.parse(sessionStorage.vuex).usuario.dados.clienteId;
    this.especialidadeId = this.horarioProp.id_especialidade;
    this.unidadeId = this.horarioProp.id_unidade;
    this.unidadeId = this.horarioProp.id_unidade;
    this.horarioId = this.horarioProp.id;
    this.getProcedimentos();
  },
  computed: {
    allowCadastrarBtn() {
      return 1;
    },
    allowCadastrarTutorBtn() {
      return true;
    },
  },
  watch: {
    petId() {
      this.validarform();
    },
    procedimentoId() {
      this.validarform();
    },
  },
  methods: {
    validarform() {
      if (this.petId != 0 && this.procedimentoId != 0) {
        this.validarCadastro = false;
      }
    },
    async getPetsByIdTutor() {
      await this.services.petService
        .getPetsByCliente()
        .then(async (response) => {
          if (response.status !== 200) return;
          const { data } = await response.json();
          const { pets } = data;
          this.petList = pets.map((pet) => {
            return {
              text: pet.nome_pet,
              value: parseInt(pet.id_pet),
            };
          });
        })
        .finally(() => {});
    },
    async getPetsByFilter() {
      let filterId = false;
      this.pets = [];
      const paginationParams = {
        page: 1,
        per_page: 1000,
        // search: filterId ? this.searchId : this.search,
        search: this.search,
        filterId,
      };
      await this.services.petService
        .getPetsPaginatedList(paginationParams)
        .then(async (response) => {
          if (response.status !== 200) return;
          const { data } = await response.json();
          this.pets = data.pets;
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {});
    },
    async getProcedimentos() {
      await this.services.agendaEspecialidadeService
        .getProcesamentobyUnidadeEspecialidade(
          this.unidadeId,
          this.especialidadeId
        )
        .then(async (response) => {
          if (response.status !== 200) return;
          const { data } = await response.json();
          this.procedimentos = data.procedimentos.map(
            ({ id_procedimento, procedimento }) => {
              return {
                text: procedimento,
                value: parseInt(id_procedimento),
              };
            }
          );
        });
    },
    getModel() {
      return {
        id_agenda_especialidade: parseInt(this.horarioId),
        id_procedimento: this.procedimentoId,
        id_pet: this.pet_selecionado.id_pet,
        observacao: this.observacao,
      };
    },
    async cadastarConsulta() {
      await this.services.agendaEspecialidadeService
        .postAgendamentoRecepcao(this.getModel())
        .then((response) => {
          if (response.status !== 200) {
            this.$_ACTIONS_showSnackbarMessage({
              message: "Erro ao agendar consulta",
              color: "error",
            });
            return;
          }
          this.$_ACTIONS_showSnackbarMessage({
            message: "Consulta agendada com sucesso",
            color: "sucess",
          });
          this.$emit("close");
        });
    },
    fecharLista() {
      this.pets = [];
    },
    enviarValor(item) {
      this.pet_selecionado = item;
    },
  },
};
</script>

<style scoped>
.list-overflow {
  max-height: 300px;
  overflow-y: auto;
}
.border-list {
  box-shadow: 0px 5px 10px #0000001f;
}
</style>
