<template>
  <div class="row justify-content-center mt-5 p-5">
    <div class="col-6">
      <h3 class="text-left mb-5">Produtos</h3>
      <v-data-table
        :headers="headersUnidade"
        :items="listaProdServ"
        class="elevation-1"
        :loading="loadingProdUnidade"
        v-on:update:sort-by="options.ordenacao = false"
        :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
        :loading-text="'Carregando...'"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="addProdServLoja(item.id_produto)"
            title="Adicionar"
          >
            fa-plus
          </v-icon>
        </template>
      </v-data-table>
    </div>
    <div class="col-6">
      <h3 class="text-left mb-5">Produtos Loja</h3>
      <v-data-table
        :headers="headersProd"
        :items="listaProdServLoja"
        class="elevation-1"
        :loading="loadingProdLoja"
        v-on:update:sort-by="options.ordenacao = false"
        :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
        :loading-text="'Carregando...'"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            class="mr-2"
            :class="item.id_lojaProdStatus == 1 ? 'btn-ativo' : 'btn-inativo'"
            :title="item.id_lojaProdStatus == 1 ? 'Desativar' : 'Ativar'"
            @click="
              chageStatusProdServLoja(item.id_lojaProd, item.id_lojaProdStatus)
            "
          >
            fas fa-toggle-on
          </v-icon>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import Mixin from "@/mixins/vuex_mixin.js";
import ProdutosServicosService from "../../services/produtos_servicos_service";
import AuthService from "../../services/auth_service";
import ValidationService from "../../services/validation_service";

export default {
  mixins: [Mixin],
  props: {
    idUnidade: {
      type: Number,
      required: true,
    },
    idLoja: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      form: false,
      loadingProdUnidade: false,
      loadingProdLoja: false,
      listaProdServ: [],
      listaStatus: [
        { id: 0, desc: "Todos" },
        { id: 1, desc: "Ativo" },
        { id: 2, desc: "Inativo" },
      ],
      statusSelected: 0,
      listaProdServLoja: [],
      services: {
        produtosServicosService: ProdutosServicosService.build(),
        authService: AuthService.build(),
        validationService: ValidationService,
      },
      headersUnidade: [
        {
          text: "Produto",
          align: "start",
          sortable: false,
          value: "nome_produto",
        },
        { text: "Valor", value: "valor_formatado", sortable: false },
        { text: "Tipo", value: "desc_tipo", sortable: false },
        { text: "Status", value: "desc_status", sortable: false },
        { text: "Ações", align: "end", value: "actions", sortable: false },
      ],
      headersProd: [
        {
          text: "Produto",
          align: "start",
          sortable: false,
          value: "nome_produto",
        },
        { text: "Valor", value: "valor_formatado", sortable: false },
        { text: "Tipo", value: "desc_tipo", sortable: false },
        { text: "Status", value: "desc_lojaProdStatus", sortable: false },
        { text: "Ações", align: "end", value: "actions", sortable: false },
      ],
    };
  },
  watch: {
    statusSelected() {
      this.listarProdutosServicosbyLoja();
    },
  },
  mounted() {
    this.listarProdutosServicosbyUnidade();
    this.listarProdutosServicosbyLoja();
  },
  methods: {
    async listarProdutosServicosbyUnidade() {
      this.listaProdServ = [];
      this.loadingProdUnidade = true;
      await this.services.produtosServicosService
        .getProdutosServicosbyUnidadeSemLoja(this.idUnidade)
        .then(async (response) => {
          const { data } = await response.json();
          const { produtos } = data;
          const totalList = produtos.length;
          for (let index = 0; index < totalList; index++) {
            const element = produtos[index];
            this.listaProdServ.push({
              id_produto: element.id,
              nome_produto: element.nome,
              desc_produto: element.descricao,
              valor_produto: element.valor,
              valor_formatado: parseFloat(element.valor).toLocaleString(
                "pt-br",
                {
                  style: "currency",
                  currency: "BRL",
                }
              ),
              id_unidade: element.id_unidade,
              desc_unidade: element.nome_fantasia,
              id_loja: element.id_loja,
              id_status: element.id_status,
              desc_status: element.produto_status,
              id_tipo: element.id_tipo,
              nome_tipo: element.tipo_nome,
              desc_tipo: element.tipo_descricao,
            });
            this.loadingProdUnidade = false;
          }
        })
        .catch((error) => {
          this.loadingProdUnidade = false;
          console.log(error);
        });
    },
    async listarProdutosServicosbyLoja() {
      this.listaProdServLoja = [];
      this.loadingProdLoja = true;
      await this.services.produtosServicosService
        .getProdutosServicosbyLoja(this.idLoja, this.statusSelected)
        .then(async (response) => {
          const { data } = await response.json();
          const { produtos } = data;
          const totalList = produtos.length;
          for (let index = 0; index < totalList; index++) {
            const element = produtos[index];
            this.listaProdServLoja.push({
              id_produto: element.id,
              nome_produto: element.nome,
              desc_produto: element.descricao,
              valor_produto: element.valor,
              valor_formatado: parseFloat(element.valor).toLocaleString(
                "pt-br",
                {
                  style: "currency",
                  currency: "BRL",
                }
              ),
              id_unidade: element.id_unidade,
              desc_unidade: element.nome_fantasia,
              id_loja: element.id_loja,
              id_status: element.id_loja_produto_status,
              desc_status: element.loja_produto_status,
              id_tipo: element.id_tipo,
              nome_tipo: element.tipo_nome,
              desc_tipo: element.tipo_descricao,
              id_lojaProd: element.id_loja_produto,
              id_lojaProdStatus: element.id_loja_produto_status,
              desc_lojaProdStatus: element.loja_produto_status,
            });
            this.loadingProdLoja = false;
          }
        })
        .catch((error) => {
          this.loadingProdLoja = false;
          console.log(error);
        });
    },
    addProdServLoja(id_produto) {
      this.services.produtosServicosService.postAddProdutoLoja(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.$_ACTIONS_showSnackbarMessage({
                message:
                  body.message || this.$global.messages.internalServerError,
                color: "sucess",
              });
            } else {
              this.$_ACTIONS_showSnackbarMessage({
                message:
                  body.message || this.$global.messages.internalServerError,
                color: "error",
              });
            }
            this.listarProdutosServicosbyUnidade();
            this.listarProdutosServicosbyLoja();
          },
          onError: (message) => {
            this.$_ACTIONS_showSnackbarMessage({
              message: message || this.$global.messages.internalServerError,
              color: "error",
            });
            this.$emit("remarcar", { message });
          },
          onEnd: () => {},
        },
        {
          id_produto: id_produto,
          id_loja: this.idLoja,
          id_status: 1,
        }
      );
    },
    chageStatusProdServLoja(id_produto, id_status) {
      this.services.produtosServicosService.postUpdateStatusProdutoLoja(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.$_ACTIONS_showSnackbarMessage({
                message:
                  body.message || this.$global.messages.internalServerError,
                color: "sucess",
              });
            } else {
              this.$_ACTIONS_showSnackbarMessage({
                message:
                  body.message || this.$global.messages.internalServerError,
                color: "error",
              });
            }
            this.listarProdutosServicosbyUnidade();
            this.listarProdutosServicosbyLoja();
          },
          onError: (message) => {
            this.$_ACTIONS_showSnackbarMessage({
              message: message || this.$global.messages.internalServerError,
              color: "error",
            });
            this.$emit("remarcar", { message });
          },
          onEnd: () => {},
        },
        {
          id: parseInt(id_produto),
          id_status: id_status == 1 ? 2 : 1,
        }
      );
    },
  },
};
</script>

<style scoped>
.form-group label {
  text-align: left !important;
  display: block;
}

.form-group .label-genero {
  margin-right: 1rem;
}
</style>
