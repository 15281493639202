<template>
  <div>
    <div class="p-5">
      <v-form ref="form" v-model="form" class="p-lg-3 row" lazy-validation>
        <div class="col-4 form-group clearfix mb-0">
          <v-select
            style="padding-top: 0 !important"
            :items="listUf"
            v-model="idUf"
            label="Selecione Estado"
          />
        </div>
        <div class="col-4 form-group clearfix mb-0">
          <v-select
            style="padding-top: 0 !important"
            :items="listUnidade"
            v-model="unidade"
            label="Selecione Unidade"
          />
        </div>
        <div class="col-md-4 text-right">
          <button
            @click.prevent="cadastrarUnidade"
            class="btn btn-app-primary btn-rounded font-weight-bold"
            :disabled="idUf == '' && unidade == ''"
          >
            <span v-if="!loading"> Associar </span>
            <v-progress-circular
              v-else
              indeterminate
              color="white"
            ></v-progress-circular>
          </button>
        </div>
      </v-form>
    </div>
  </div>
</template>

<script>
import CEPMixin from "@/mixins/cep_mixin.js";
import Mixin from "@/mixins/vuex_mixin.js";
import UnidadeService from "../../services/unidade_service";
import AuthService from "../../services/auth_service";
import ValidationService from "../../services/validation_service";

export default {
  mixins: [CEPMixin, Mixin],
  data() {
    return {
      form: false,
      loading: false,
      idUf: "",
      listUf: [],
      unidade: "",
      listUnidade: [],
      services: {
        unidadeService: UnidadeService.build(),
        authService: AuthService.build(),
        validationService: ValidationService,
      },
    };
  },
  mounted() {
    this.getListUf();
    this.getListUnidade();
  },
  methods: {
    async getListUf() {
      this.services.unidadeService
        .getUf()
        .then(async (response) => {
          const { data } = response;
          data.map((element) => {
            this.listUf.push({ text: element.estado, value: element.id });
          });
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {});
    },
    async getListUnidade() {
      this.services.unidadeService
        .getUnidadeList()
        .then(async (response) => {
          console.log("response");
          console.log(response);
          response.map((element) => {
            this.listUnidade.push({
              text: element.nomeFantasia,
              value: element.unidadeId,
            });
          });
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {});
    },
    zerarErros() {
      Object.keys(this.error).forEach((error) => {
        this.error[error] = "";
      });
    },
    async cadastrarUnidade() {
      const unidadeSelecionada = this.listUnidade.filter(
        (unidade) => unidade.value == this.unidade
      );
      const unidade = {
        id_uf: this.idUf,
        id_unidade: this.unidade,
        nome_unidade: unidadeSelecionada[0].text,
      };
      this.loading = true;
      this.services.unidadeService
        .postCreateUnidadeByUf(unidade)
        .then(async (response) => {
          const body = await response.json();
          const message =
            body.message || this.$global.messages.internalServerError;
          if (response.status !== 200) {
            this.$_ACTIONS_showSnackbarMessage({ message, color: "error" });
            const errors = body.errors;
            this.zerarErros();
            Object.keys(body.errors).forEach((error) => {
              if (errors[error]) {
                this.error[error] = errors[error];
              }
            });
            return;
          }
          this.zerarErros();
          this.$_ACTIONS_showSnackbarMessage({
            message: body.message || this.$global.messages.internalServerError,
            color: "sucess",
          });
          this.$emit("response");
          this.$emit("close");
        })
        .catch((e) => {
          console.log({ e });
          this.$_ACTIONS_showSnackbarMessage({
            message: this.$global.messages.internalServerError,
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.form-group label {
  text-align: left !important;
  display: block;
}

.form-group .label-genero {
  margin-right: 1rem;
}
</style>
