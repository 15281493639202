<template>
  <div>
    <v-card min-height="100vh">
      <v-toolbar style="background-color: #1daf80 !important; color: #fff">
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          <span class="headline">Galeria</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-container v-for="(fotos, i) in lista" :key="i">
          <div
            class="row mb-2 p-1 shadow-lg"
            style="border:solid 1px #EFEFEF; border-radius:5px"
          >
            <div class="row col-12 align-content-end">
              <v-btn
                @click="dialogConfirmDeletar(fotos.id)"
                class="ml-auto"
                small
                icon
              >
                <v-hover v-slot="{ hover }">
                  <v-icon medium :color="hover ? '#F44336' : 'gray'">
                    fas fa-times</v-icon
                  >
                </v-hover>
              </v-btn>
              <confirmation-dialog
                v-if="confirmDeletar"
                :enabled="confirmDeletar"
                :dialogColor="'error'"
                :title="'Deletar classificação'"
                :confirmationMessage="
                  'Você tem certeza que deseja deletar a classificação?'
                "
                :confirmationButtonMessage="'Confirmar'"
                :abortButtonMessage="'Cancelar'"
                :confirmationButtonColor="'error'"
                @abort="confirmDeletar = false"
                @confirm="deletarClassificacao"
              ></confirmation-dialog>
            </div>
            <!-- FOTOS DE ANTES -->
            <div class="col-lg-6">
              <div class="fotos-antes">
                <h3>Antes</h3>
                <v-img
                  height="250"
                  width="150"
                  class="m-auto"
                  lazy-src="https://picsum.photos/id/8/200/300"
                  :src="fotos.imagem_antes"
                ></v-img>
              </div>
            </div>

            <!-- FOTOS DE DEPOIS -->
            <div class="col-lg-6">
              <div class="fotos-depois">
                <h3>Depois</h3>
                <v-img
                  height="250"
                  width="150"
                  class="m-auto"
                  lazy-src="https://picsum.photos/id/8/200/300"
                  :src="fotos.imagem_depois"
                ></v-img>
              </div>
            </div>
            <div class="col-12" style="border: solid 1px gray">
              <h4><u>Descrição</u></h4>
              <p class="m-auto">{{ fotos.descricao_classificacao }}</p>
            </div>
          </div>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import FotoService from "@/services/fotos_service.js";
import ConfirmationDialog from "@/components/dialogs/confirmation";
import Mixin from "@/mixins/vuex_mixin.js";

export default {
  mixins: [Mixin],
  components: {
    ConfirmationDialog,
  },
  props: {
    paciente: String,
  },
  beforeMount() {
    this.buscar_fotos();
  },
  data: () => ({
    lista: [],
    confirmDeletar: false,
    deletedItem: "",
  }),
  methods: {
    searchPhoto(id) {
      const fotoService = new FotoService();
      fotoService.getFoto(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              for (let i = 0; i < this.lista.length; i++) {
                if (body.data[0].id === this.lista[i].antes) {
                  this.lista[i].imagem_antes = body.data[0].imagem;
                  break;
                }
                if (body.data[0].id === this.lista[i].depois) {
                  this.lista[i].imagem_depois = body.data[0].imagem;
                  break;
                }
              }
            }
          },
          onError: (error) => {
            console.log(error);
          },
          onEnd: () => {},
        },
        id
      );
    },
    buscar_fotos: function() {
      // let idMedico = this.$_GETTERS_usuario.id;
      let fotoService = new FotoService();
      fotoService.buscar_fotos_classificadas(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              body.data.classificacao.forEach((c) => {
                const item = {
                  id: c.id,
                  antes: c.antes,
                  depois: c.depois,
                  imagem_antes: "",
                  imagem_depois: "",
                  descricao_classificacao: c.descricao_classificacao,
                };
                this.lista.push(item);
                this.searchPhoto(item.antes);
                this.searchPhoto(item.depois);
              });
            } else if (status == 206) {
              this.$_ACTIONS_showSnackbarMessage({
                message: "Nenhuma classificação encontrada ",
                color: "sucess",
              });
            } else {
              this.$_ACTIONS_showSnackbarMessage({
                message: "Não foi possível carregar as fotos",
                color: "error",
              });
            }
          },
          onError: (error) => {
            console.log(error);
            this.$_ACTIONS_showSnackbarMessage({
              message: "'Não foi possível carregar as fotos",
              color: "error",
            });
            this.$emit("mensagem", { error });
          },
          onEnd: () => {
            this.$emit("carregado", true);
          },
        },
        { paciente: this.paciente }
      );
    },
    dialogConfirmDeletar(id) {
      this.deletedItem = id;
      console.log(this.deletedItem);
      this.confirmDeletar = true;
    },
    deletarClassificacao() {
      this.confirmDeletar = false;
      const fotoService = new FotoService();
      fotoService.deleteFotoClassificada(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.$_ACTIONS_showSnackbarMessage({
                message: body.message,
                color: "sucess",
              });
              this.buscar_fotos();
            }
          },
          onError: (error) => {
            console.log(error);
          },
          onEnd: () => {},
        },
        this.deletedItem
      );
    },
  },
};
</script>
<style scoped>
.btn-style-green {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #1daf80 !important;
  height: 30px !important;
  font-size: 15 px;
  padding: 10px !important;
  border-radius: 50px;
  margin: 20px auto;
}
</style>
