import HTTPService from "@/services/http_service.js";
// import { global } from "@/config/constants";

export default class CreditoClienteService extends HTTPService {
  async getCreditosCliente(unidadeId, clienteId) {
    const data = await this.get(
      `credito/unidade/cliente/${clienteId}/${unidadeId}`
    );

    return data.json();
  }
}
