import HTTPService from "@/services/http_service.js";
import { global } from "@/config/constants";

export default class EspecialidadeService extends HTTPService {
  resource = "especialidade";

  static build() {
    return new EspecialidadeService();
  }

  getEspecialidadePaginatedList({ page, per_page, search, order, desc }) {
    return this.get(this.resource, {
      page,
      per_page,
      search,
      order,
      desc,
    });
  }

  getEspecialidadeList() {
    return this.get(this.resource);
  }

  getEspecialidadeListByUnidadeId(unidadeId) {
    return this.get(`${this.resource}/unidade/${unidadeId}`);
  }

  async register(especialidade) {
    const headers = new Headers();

    const token = JSON.parse(sessionStorage.getItem("vuex")).token;
    headers.append("Authorization", "Bearer " + token);

    const requestOptions = {
      method: "POST",
      body: especialidade,
      headers: headers,
    };
    return await fetch(
      `${global.api.baseURL}/${this.resource}`,
      requestOptions
    ).then(async (response) => {
      return response;
    });
  }

  async editar(especialidadeId, especialidade) {
    const headers = new Headers();
    const token = JSON.parse(sessionStorage.getItem("vuex")).token;
    headers.append("Authorization", "Bearer " + token);

    const requestOptions = {
      method: "POST",
      body: especialidade,
      headers: headers,
    };
    return await fetch(
      `${global.api.baseURL}/${this.resource}/${especialidadeId}`,
      requestOptions
    ).then(async (response) => {
      return response;
    });
  }

  async deletar(especialidadeId) {
    const headers = new Headers();
    const token = JSON.parse(sessionStorage.getItem("vuex")).token;
    headers.append("Authorization", "Bearer " + token);

    const requestOptions = {
      method: "POST",
      headers: headers,
    };
    return fetch(
      `${global.api.baseURL}/${this.resource}/${especialidadeId}/deletar`,
      requestOptions
    ).then(async (response) => {
      return response;
    });
  }
}
