<template>
  <div>
    <div class="row justify-content-center mt-5 p-5">
      <v-form ref="form" class="col-10" v-model="form" lazy-validation>
        <div class="row align-items-end">
          <!-- <div class="col-lg-6 form-group clearfix mb-3">
            <label><strong>Nome Loja</strong></label>
            <v-text-field
              v-model="nomeLoja"
              :type="'text'"
              placeholder="Nome Loja"
              style="padding-top: 0 !important"
            ></v-text-field>
          </div> -->
          <div class="col-lg-6 form-group clearfix mb-3">
            <!-- <div class="col-lg-3 form-group clearfix mb-3"> -->
            <label><strong>Unidade</strong></label>
            <!-- <v-select
            style="padding-top: 0 !important"
            :items="listaUnidades"
            :item-text="desc"
            :item-value="id"
            v-model="unidade"
          /> -->
            <v-autocomplete
              :items="listaUnidades"
              item-text="desc"
              item-value="id"
              v-model="unidade"
              placeholder="Unidade"
            ></v-autocomplete>
          </div>
          <div class="col-lg-6 form-group clearfix mb-3 text-end">
            <!-- <div class="col-lg-3 form-group clearfix mb-3 text-end"> -->
            <label><strong>Status Loja</strong></label>
            <v-switch
              v-model="statusLoja"
              :label="`${statusLoja ? 'Ativa' : 'Inativa'}`"
              style="padding-top: 0 !important"
            ></v-switch>
          </div>
        </div>
        <div class=" row" v-if="false">
          <div class="col-lg-12 form-group clearfix mb-3">
            <label for=""><strong>Produtos e Serviços</strong></label>
            <v-autocomplete
              :items="listaProdServ"
              item-text="desc"
              item-value="id"
              v-model="prodServ"
              chips
              placeholder="Produtos e Serviços"
              multiple
            ></v-autocomplete>
          </div>
        </div>

        <div class="col-md-12 text-right">
          <button
            @click.prevent="cadastrarLoja"
            class="btn btn-app-primary btn-rounded font-weight-bold"
          >
            <span v-if="!loading"> Cadastrar </span>
            <v-progress-circular
              v-else
              indeterminate
              color="white"
            ></v-progress-circular>
          </button>
        </div>
      </v-form>
    </div>
  </div>
</template>

<script>
import CEPMixin from "@/mixins/cep_mixin.js";
import Mixin from "@/mixins/vuex_mixin.js";
import UnidadeService from "../../services/unidade_service";
import ProdutosServicosService from "../../services/produtos_servicos_service";
import LojaService from "../../services/loja_service";
import AuthService from "../../services/auth_service";
import ValidationService from "../../services/validation_service";

export default {
  mixins: [CEPMixin, Mixin],
  data() {
    return {
      form: false,
      loading: false,
      statusLoja: false,
      nomeLoja: "",
      listaUnidades: [],
      unidade: "",
      listaProdServ: [],
      prodServ: "",
      services: {
        unidadeService: UnidadeService.build(),
        produtosServicosService: ProdutosServicosService.build(),
        lojaService: LojaService.build(),
        authService: AuthService.build(),
        validationService: ValidationService,
      },
    };
  },
  mounted() {
    this.listarUnidades();
    // this.listarProdutosServicos();
  },
  methods: {
    async listarUnidades() {
      this.listaUnidades = [];
      await this.services.unidadeService
        .getUnidadeList()
        .then((response) => {
          this.listaUnidades = response.map((u) => ({
            id: u.unidadeId,
            desc: u.nomeFantasia,
          }));
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async listarProdutosServicos() {
      this.listaProdServ = [];
      await this.services.produtosServicosService
        .getProdutosServicos()
        .then((response) => {
          this.listaProdServ = response.map((p) => ({
            id: p.id,
            desc: p.desc,
          }));
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cadastrarLoja() {
      this.loading = true;
      this.services.lojaService.postCreateLoja(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.$_ACTIONS_showSnackbarMessage({
                message:
                  body.message || this.$global.messages.internalServerError,
                color: "sucess",
              });
              this.zerarFormulario();
              this.$emit("response");
              this.$emit("close");
            } else {
              this.$_ACTIONS_showSnackbarMessage({
                message:
                  body.message || this.$global.messages.internalServerError,
                color: "error",
              });
            }
          },
          onError: (message) => {
            this.$_ACTIONS_showSnackbarMessage({
              message: message || this.$global.messages.internalServerError,
              color: "error",
            });
            this.$emit("remarcar", { message });
          },
          onEnd: () => {
            this.loading = false;
          },
        },
        {
          // nome_loja: this.nomeLoja,
          id_unidade: parseInt(this.unidade),
          id_status_loja: this.statusLoja ? 1 : 2,
          // produtosServicos_id: this.prodServ,
        }
      );
    },
    zerarFormulario() {
      this.nomeLoja = "";
      this.unidade = "";
      this.statusLoja = false;
    },
  },
};
</script>

<style scoped>
.form-group label {
  text-align: left !important;
  display: block;
}

.form-group .label-genero {
  margin-right: 1rem;
}
</style>
