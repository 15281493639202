<template>
  <div>
    <div class="p-lg-3">
      <div class="row" justify-content-between>
        <h3 class="mt-0 mb-2 font-weight-bold text-left">
          Relatório de Atendimento
        </h3>
      </div>
    </div>

    <div class="mt-2 mx-1 d-flex" style="gap:1rem">
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="datas"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="computedFormatDate"
            label="Data inicial - Data final"
            prepend-icon="mdi-calendar"
            clearable
            v-bind="attrs"
            v-on="on"
            @click:clear="datas = null"
            outlined
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="datas"
          no-title
          range
          scrollable
          color="green darken-4"
        >
          <v-spacer></v-spacer>
          <v-btn text @click="menu = false"> Cancelar </v-btn>
          <v-btn dark color="success" @click="$refs.menu.save(datas)">
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
      <v-select
        :items="unidades"
        label="Unidade"
        item-value="id"
        v-model="unidadeSelected"
        outlined
      ></v-select>
      <v-select
        :items="tipoFiltro"
        label="Filtrar por"
        item-text="descricao"
        item-value="id"
        v-model="tipoFiltroSelected"
        :rules="[rules.required]"
        outlined
      ></v-select>
      <v-autocomplete
        :items="filtroPesquisa"
        label="Pesquisar"
        item-text="nome"
        item-value="id"
        return-object
        v-model="filtroPesquisaSelected"
        :loading="loadingFiltroPesquisa"
        v-if="tipoFiltroSelected"
        outlined
      ></v-autocomplete>
      <!-- <v-btn small color="success" @click="getAtendimentos" class="mr-2"
        >Buscar</v-btn
      > -->
      <v-btn
        color="primary"
        style="height: 54px; font-size: 15px;"
        @click="exportarPdfAtendimentos"
        >Exportar PDF</v-btn
      >
      <!-- <v-speed-dial
        v-model="fab"
        :direction="'bottom'"
        :open-on-hover="false"
        :transition="transition"
      >
        <template v-slot:activator>
          <v-btn
            v-model="fab"
            color="blue darken-2"
            small
            dark
            style="width: 7rem;"
          >
            <v-icon v-if="fab">
              mdi-close
            </v-icon>
            <span v-else>
              Exportar
            </span>
          </v-btn>
        </template>
        <v-btn dark color="green" style="width: 7rem;" @click="exportarPDF">
          PDF
        </v-btn>
        <v-btn dark color="indigo" style="width: 7rem;" @click="exportarExcel">
          EXCEL
        </v-btn>
      </v-speed-dial> -->
    </div>
  </div>
</template>
<script>
import UnidadeService from "../services/unidade_service";
import TipoProcedimentoService from "../services/tipo_procedimento_service";
import ProcedimentoService from "../services/procedimento_service";
import RelatorioAtendimentosService from "../services/relatorio_atendimentos_service";
import Mixin from "@/mixins/vuex_mixin.js";

export default {
  mixins: [Mixin],
  data() {
    return {
      loading: false,
      menu: false,
      datas: null,
      tipoFiltro: [{ id: 100, descricao: "Profissional" }],
      tipoFiltroSelected: "",
      filtroPesquisa: [],
      filtroPesquisaSelected: {},
      loadingFiltroPesquisa: false,
      unidades: [],
      unidadeSelected: "",
      options: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 15,
        ordenacao: false,
        desc: false,
      },
      totalList: 10,
      perfil: null,
      usuarioId: null,
      fab: false,
      services: {
        unidadeService: UnidadeService.build(),
        tipoProcedimentoService: TipoProcedimentoService.build(),
        procedimentoService: ProcedimentoService.build(),
        relatorioAtendimentosService: RelatorioAtendimentosService.build(),
      },
      rules: {
        required: (value) => !!value || "Campo obrigatório.",
      },
    };
  },
  computed: {
    computedFormatDate() {
      const datasFormatadas =
        this.datas &&
        this.datas.map((item) =>
          item
            .split("-")
            .reverse()
            .join("/")
        );
      return datasFormatadas;
    },
  },
  watch: {
    async tipoFiltroSelected() {
      this.loadingFiltroPesquisa = true;
      if (this.tipoFiltroSelected == 100) {
        this.filtroPesquisa = await this.services.relatorioAtendimentosService
          .getProfissionaisAssistentesMedicos(this.unidadeSelected)
          .then((resp) => resp.json())
          .then((resp) => resp.data.profissionais);
      } else {
        this.filtroPesquisa = await this.services.procedimentoService
          .getProcedimentosPorUnidadeEprocedimento(
            this.unidadeSelected,
            this.tipoFiltroSelected
          )
          .then((resp) => {
            console.log(resp);
            return resp.map((item) => {
              return {
                id: item.procedimento_id,
                nome: item.procedimento_descricao,
              };
            });
          });
      }
      this.loadingFiltroPesquisa = false;
    },
    async unidadeSelected() {
      this.loadingFiltroPesquisa = true;
      if (this.tipoFiltroSelected == 100) {
        this.filtroPesquisa = await this.services.relatorioAtendimentosService
          .getProfissionaisAssistentesMedicos(this.unidadeSelected)
          .then((resp) => resp.json())
          .then((resp) => resp.data.profissionais);
      } else {
        this.filtroPesquisa = await this.services.procedimentoService
          .getProcedimentosPorUnidadeEprocedimento(
            this.unidadeSelected,
            this.tipoFiltroSelected
          )
          .then((resp) => {
            console.log(resp);
            return resp.map((item) => {
              return {
                id: item.procedimento_id,
                nome: item.procedimento_descricao,
              };
            });
          });
      }
      this.loadingFiltroPesquisa = false;
    },
  },
  mounted() {
    this.setUnidades();
    this.unidadeSelected = JSON.parse(
      sessionStorage.getItem("vuex")
    ).unidade.id.toString();
    this.perfil = JSON.parse(sessionStorage.getItem("vuex")).perfil.id;
    this.usuarioId = JSON.parse(sessionStorage.getItem("vuex")).usuario.id;
    this.setServicos();
  },
  methods: {
    setUnidades() {
      this.services.unidadeService
        .getUnidadeByUsuarioLogado()
        .then((response) => {
          return response.json();
        })
        .then((response) => {
          this.unidades = response.data.map((unidade) => {
            return { text: unidade.unidade, id: unidade.id_unidade };
          });
        })
        .then(() => {});
    },
    async setServicos() {
      await this.services.tipoProcedimentoService
        .getTipoProcedimentos()
        .then((resp) => resp.json())
        .then((resp) =>
          resp.data.tipo_procedimentos.filter((p) => p.id != 1 && p.id != 7)
        )
        .then((resp) => resp.map((p) => this.tipoFiltro.push(p)));
      console.log(this.tipoFiltro);
    },
    getAtendimentos() {
      console.log(this.computedFormatDate);
      console.log(this.unidadeSelected);
      console.log(this.tipoFiltroSelected);
    },
    exportarPdfAtendimentos() {
      if (!this.datas) {
        this.$_ACTIONS_showSnackbarMessage({
          message: "Selecione uma data para o relatório",
          color: "error",
        });
        return;
      }

      if (!this.tipoFiltroSelected) {
        this.$_ACTIONS_showSnackbarMessage({
          message: "Selecione um filtro para o relatório",
          color: "error",
        });
        return;
      }

      const dataInicial = this.datas[0].split("-").join("");
      const dataFinal = this.datas[1].split("-").join("");

      if (this.tipoFiltroSelected == 100) {
        this.services.relatorioAtendimentosService
          .pdfRelatorioAtendimentosProfissional(
            dataInicial,
            dataFinal,
            this.unidadeSelected,
            this.filtroPesquisaSelected.id_usuario,
            0
          )
          .then((resp) => {
            if (resp.status) {
              this.$_ACTIONS_showSnackbarMessage({
                message: "Falha ao carregar",
                color: "error",
              });
              return;
            }
            this.$_ACTIONS_showSnackbarMessage({
              message: "Dados encontrados com sucesso! Gerando PDF...",
              color: "sucess",
            });
            let link = document.createElement("a");
            // link.target = "_blank";
            link.download = `${dataInicial}-${dataFinal}.pdf`;
            let url = window.URL.createObjectURL(resp);
            link.href = url;
            link.click();
          })
          .catch(() => {
            this.$_ACTIONS_showSnackbarMessage({
              message: "Falha ao carregar",
              color: "error",
            });
          });
      } else {
        this.services.relatorioAtendimentosService
          .pdfRelatorioAtendimentosProfissional(
            dataInicial,
            dataFinal,
            this.unidadeSelected,
            0,
            this.filtroPesquisaSelected.id
          )
          .then((resp) => {
            if (resp.status) {
              this.$_ACTIONS_showSnackbarMessage({
                message: "Falha ao carregar",
                color: "error",
              });
              return;
            }
            this.$_ACTIONS_showSnackbarMessage({
              message: "Dados encontrados com sucesso! Gerando PDF...",
              color: "sucess",
            });
            let link = document.createElement("a");
            // link.target = "_blank";
            link.download = `${dataInicial}-${dataFinal}.pdf`;
            let url = window.URL.createObjectURL(resp);
            link.href = url;
            link.click();
          })
          .catch(() => {
            this.$_ACTIONS_showSnackbarMessage({
              message: "Falha ao carregar",
              color: "error",
            });
          });
      }
    },
  },
};
</script>
