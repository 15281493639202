import HTTPService from "@/services/http_service.js";

export default class ProdutosServicosService extends HTTPService {
  resource = "produto";
  resources = "produtos";
  resourceLoja = "loja/produtos";

  static build() {
    return new ProdutosServicosService();
  }
  /**
        @returns {Promise<Response>}
     */

  // Fluxo Produto

  // Lista Produtos Paginado
  getProdutosServicosPaginatedList({ page, per_page, search, order, desc }) {
    return this.get(`${this.resources}`, {
      page,
      per_page,
      search,
      order,
      desc,
    });
  }

  // Lista Produtos
  getProdutosServicos() {
    return this.get(`${this.resources}`);
  }

  // Retorna Produto pelo ID
  getProdutosServicosById(prodServ_id) {
    return this.get(`${this.resource}/${prodServ_id}`).then((resp) =>
      resp.json()
    );
  }
  // Cadastrar novo Produto
  postCreateProdutosServicos(responseFunctions, model) {
    this.jsonRequest(responseFunctions, "POST", `${this.resource}/new`, model);
  }
  // Alterar Status Produto
  postUpdateStatusProdutosServicos(responseFunctions, model) {
    this.jsonRequest(responseFunctions, "POST", `produto/changestatus`, model);
  }
  // Editar Produto
  postUpdateProdutosServicos(responseFunctions, model) {
    this.jsonRequest(
      responseFunctions,
      "POST",
      `${this.resource}/update`,
      model
    );
  }

  // Fluxo Produtos Loja

  // Lista Produtos por Loja
  getProdutosServicosbyLoja(id_loja, idStatus = 0) {
    return this.get(`${this.resources}/loja/${id_loja}/${idStatus}`);
  }

  // Lista Produtos por Loja  Ativa (usar no cliente)
  getProdutosServicosbyLojaAtiva(id_loja, idStatus = 0) {
    console.log("Teste");
    return this.get(`${this.resourceLoja}/${id_loja}/${idStatus}`);
  }

  // Lista Produtos por Unidade
  getProdutosServicosbyUnidade(id_unidade) {
    return this.get(`${this.resources}/unidade/${id_unidade}`);
  }

  // Lista Produtos Sem loja por Unidade
  getProdutosServicosbyUnidadeSemLoja(id_unidade, id_status = 0) {
    return this.get(
      `${this.resources}/unidade/semloja/${id_unidade}/${id_status}`
    );
  }

  // Adicionar Produto na Loja
  postAddProdutoLoja(responseFunctions, model) {
    this.jsonRequest(responseFunctions, "POST", `loja/produto/new`, model);
  }

  // Alterar Status do Produto na Loja
  postUpdateStatusProdutoLoja(responseFunctions, model) {
    this.jsonRequest(
      responseFunctions,
      "POST",
      `loja/produto/changestatus`,
      model
    );
  }
}
