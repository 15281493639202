<template>
  <v-card>
    <v-toolbar style="background-color: #1daf80; color: #fff">
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>
        <span class="headline">Cadastrar Centro de Custo</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items> </v-toolbar-items>
    </v-toolbar>
    <div class="p-5">
      <form class="p-lg-3 row">
        <div class="col-lg-12 form-group clearfix mb-3">
          <label for="nome">Descrição do Centro de Custo</label>
          <v-text-field
            v-model="centroCustoDescricao"
            placeholder="Descrição do Centro de Custo"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>
        <div class="col-md-12 text-right">
          <button
            @click.prevent="cadastrarCentroCusto"
            class="btn btn-app-primary btn-rounded font-weight-bold"
          >
            <span v-if="!loading"> Cadastrar </span>
            <v-progress-circular
              v-else
              indeterminate
              color="white"
            ></v-progress-circular>
          </button>
        </div>
      </form>
    </div>
  </v-card>
</template>

<script>
import Mixin from "@/mixins/vuex_mixin.js";
import CentroCustoService from "../../services/centro_custo_service";

export default {
  mixins: [Mixin],
  data: () => ({
    formCadastro: "",
    centroCustoDescricao: "",
    loading: false,
    services: {
      centroCustoService: CentroCustoService.build(),
    },
    rules: {
      required: (value) => !!value || "Campo obrigatório.",
    },
  }),
  mounted() {
    this.formCadastro = document.forms[0];
  },
  methods: {
    cadastrarCentroCusto() {
      this.loading = true;
      this.services.centroCustoService
        .register(this.getFormData())
        .then(({ status }) => {
          this.loading = false;
          this.mostrarFeedback(status);
          if (status === 200) {
            this.resetForm();
            this.$emit("response");
          }
        })
        .catch((error) => {
          this.loading = false;
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    mostrarFeedback(status) {
      this.$_ACTIONS_showSnackbarMessage({
        message:
          status === 200
            ? "Centro de Custo cadastrado com sucesso!"
            : status === 400
            ? "Preencha os dados Corretamente"
            : this.$global.messages.internalServerError,
        color: status === 200 ? "sucess" : "error",
      });
    },
    resetForm() {
      this.centroCustoDescricao = "";
    },
    getFormData() {
      const formData = new FormData();
      formData.append("descricao", this.centroCustoDescricao);
      return formData;
    },
  },
};
</script>
