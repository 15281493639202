<template>
  <div>
    <div class="p-lg-3">
      <div class="row" justify-content-between>
        <h3 class="mt-0 mb-2 font-weight-bold text-left">Procedimentos</h3>
      </div>
    </div>

    <v-data-table
      v-on:update:sort-by="options.ordenacao = true"
      :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
      :search="search"
      :headers="headers"
      :items="procedimentos"
      :options.sync="options"
      :server-items-length="totalList"
      :loading="loading"
      sort-by="descricao"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <!-- DIALOG CADASTRO -->
          <v-dialog v-model="dialogCadastrar" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="allowCadastrarBtn"
                dark
                class="m-2 bg-verde"
                rounded
                v-bind="attrs"
                v-on="on"
              >
                Novo Procedimento
              </v-btn>
            </template>
            <modal-cadastro
              v-if="dialogCadastrar"
              @close="closeDialogCadastrar"
              @response="atualizarProcedimentoPosCadastro()"
            />
          </v-dialog>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Pesquisar"
            single-line
            hide-details
            class="mx-2"
          />
          <!-- DIALOG DE EXCLUSÃO-->
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card class=" p-2 px-4">
              <v-card-title class="headline" style="word-break: normal;"
                >Tem certeza de que deseja excluir este Procedimento?
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="closeDelete">Cancelar</v-btn>
                <v-btn color="error" rounded @click="deleteItemConfirm"
                  >Deletar</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- DIALOG EDIÇÃO -->
          <v-dialog
            v-if="dialogEdicao"
            v-model="dialogEdicao"
            max-width="500px"
          >
            <modal-edicao
              v-if="dialogEdicao"
              :procedimento="editedProcedimento"
              @close="dialogEdicao = false"
              @response="atualizarProcedimentoPosEdicao($event)"
            />
          </v-dialog>
          <!-- DIALOG IR PARA PROCEDIMENTO VALOR -->
          <v-dialog
            v-if="dialogProcedimentoValor"
            v-model="dialogProcedimentoValor"
            max-width="450px"
          >
            <v-card class=" p-2 px-4">
              <v-card-title class="d-flex justify-center">
                <span
                  >Deseja cadastrar um valor para <br />
                  este procedimento?</span
                >
              </v-card-title>

              <v-card-actions class="d-flex justify-center my-3">
                <v-btn text rounded @click="dialogProcedimentoValor = false"
                  >NÃO</v-btn
                >
                <v-btn
                  class="bg-verde"
                  dark
                  rounded
                  @click="redirectToProcedimentoValor()"
                  >SIM</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          v-if="allowCadastrarBtn"
          small
          class="mr-2"
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon v-if="allowDeletarBtn" small @click="deleteItem(item)">
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:no-data>
        <p class="text-center">Nenhum registro encontrado</p>
        <v-btn color="#1daf80" dark @click="atualizarProcedimentos">
          Atualizar
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import ProcedimentoService from "@/services/procedimento_service.js";
import modalCadastro from "@/components/procedimentos/modal_cadastro";
import modalEdicao from "@/components/procedimentos/modal_edicao";
import Mixin from "@/mixins/vuex_snackbar_mixin.js";
import AuthService from "../services/auth_service";
import endpoints from "../router/endpoints";

export default {
  mixins: [Mixin],
  components: { modalCadastro, modalEdicao },
  data: () => ({
    dialogCadastrar: false,
    dialogEdicao: false,
    dialogDelete: false,
    editedProcedimento: {},
    search: "",
    totalList: 10,
    options: {
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
      ordenacao: false,
      desc: false,
    },
    headers: [
      {
        text: "Nome",
        align: "start",
        sortable: true,
        value: "descricao",
      },
      {
        text: "Tipo",
        align: "center",
        value: "tipo_procedimento",
        sortable: false,
      },
      { text: "Ações", align: "end", value: "actions", sortable: false },
    ],
    procedimentos: [],
    loading: false,
    services: {
      authService: AuthService.build(),
    },
    dialogProcedimentoValor: false,
  }),

  watch: {
    dialog(val) {
      val || this.closeDialogCadastrar();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
      handler() {
        this.options.desc = this.options.sortDesc[0] ?? false;
        this.atualizarProcedimentos();
      },
      deep: true,
    },
    search(value, oldValue) {
      if (value.length > 2) {
        this.options.page = 1;
        this.atualizarProcedimentos();
      }
      if (value.length === 0 && oldValue.length > 0) {
        this.options.page = 1;
        this.atualizarProcedimentos();
      }
    },
  },

  mounted() {
    this.atualizarProcedimentos();
  },
  computed: {
    allowCadastrarBtn() {
      return this.services.authService.temPermissao(
        endpoints["ProcedimentoController::POST->registerProcedimento"]
      );
    },
    allowEditarBtn() {
      return this.services.authService.temPermissao(
        endpoints["ProcedimentoController::POST->updateProcedimento/$1"]
      );
    },
    allowDeletarBtn() {
      return this.services.authService.temPermissao(
        endpoints["ProcedimentoController::POST->deleteProcedimento/$1"]
      );
    },
  },
  methods: {
    redirectToProcedimentoValor() {
      this.$router.replace("procedimento-valor");
    },
    atualizarProcedimentoPosCadastro() {
      this.atualizarProcedimentos();
      this.closeDialogCadastrar();
      this.dialogProcedimentoValor = true;
    },
    atualizarProcedimentoPosEdicao() {
      this.dialogEdicao = false;
      this.atualizarProcedimentos();
    },
    atualizarProcedimentos() {
      this.loading = true;
      const procedimentoService = new ProcedimentoService();
      this.loading = true;
      this.procedimentos = [];
      const onSucess = (status) => (body) => {
        if (status === 200) {
          this.totalList = body.data.pagination.num_rows;
          this.procedimentos = body.data.procedimentos.map((procedimento) => {
            return procedimento;
          });
        } else {
          console.log(body);
        }
      };
      const onError = (error) => {
        console.log(error);
      };
      const onEnd = () => {
        this.loading = false;
      };
      procedimentoService.getAll(
        onSucess,
        onError,
        onEnd,
        this.options.page,
        this.options.itemsPerPage,
        this.search,
        this.options.ordenacao,
        this.options.desc
      );
    },
    editItem(item) {
      this.editedProcedimento = JSON.parse(JSON.stringify(item));
      this.dialogEdicao = true;
    },

    deleteItem(item) {
      this.editedProcedimento = JSON.parse(JSON.stringify(item));
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();
      for (let i = 0, len = this.procedimentos.length; i < len; i++) {
        if (this.procedimentos[i].id === this.editedProcedimento.id) {
          const procedimentoService = new ProcedimentoService();
          procedimentoService.deleteProcedimento(
            {
              onSucess: (status) => (body) => {
                this.$_ACTIONS_showSnackbarMessage({
                  message:
                    body.message || this.$global.messages.internalServerError,
                  color: status === 200 ? "sucess" : "error",
                });
                status === 200 && this.procedimentos.splice(i, 1);
              },
              onError: () => {
                this.$_ACTIONS_showSnackbarMessage({
                  message: this.$global.messages.internalServerError,
                  color: "error",
                });
              },
              onEnd: () => {},
            },
            this.editedProcedimento
          );
          break;
        }
      }
    },

    closeDialogCadastrar() {
      this.dialogCadastrar = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>
