import { mapActions, mapGetters } from "vuex";

export default {
    computed: {
        ...mapGetters(
            {
                $_GETTER_snackbar: 'getSnackbar',
            }
        ),
    },
    methods: {
        ...mapActions({
            $_ACTIONS_showSnackbarMessage: 'showSnackbarMessage',
        }),
    }
}