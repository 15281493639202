<template>
  <div>
    <v-card class="ma-sm-4 ma-0" elevation="2">
      <v-card-title>{{ titulo }}</v-card-title>
      <v-card-text>
        <v-list disabled v-if="compromissos.length > 0">
          <v-list-item-group>
            <template v-for="compromisso in compromissos">
              <v-list-item :key="compromisso.paciente">
                <template>
                  <slot name="card-compromisso" :compromisso="compromisso"/>
                </template>
              </v-list-item>
            </template>
          </v-list-item-group>
        </v-list>
        <div v-else>
          <p>{{messageOnEmpty}}</p>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props:{
    compromissos: {type: Array, required: true},
    titulo: {type: String, required: true},
    messageOnEmpty: { type: String, default: 'Não há compromissos desse tipo marcados para hoje.'}
  }
}
</script>

<style scoped>
.titulo-cards-fila span {
  margin-right: 1rem;
  font-size: 1rem;
}

.titulo-cards-fila hr {
  display: block;
  width: 100%;
  color: #fff;
  margin: 0;
}
</style>