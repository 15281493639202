<template>
  <v-card>
    <v-card-title>
      <span class="headline">Edição de exame</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="nome" label="Nome"></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text @click="$emit('close')">
        Cancelar
      </v-btn>
      <v-btn class="btn-app-primary" style="color: #fff !important;" :loading="loading" text @click="edit" :disabled="!allowEdition">
        Salvar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ExameService from '@/services/exame_service.js'
import Mixin from "@/mixins/vuex_mixin.js";

export default {
  mixins:[Mixin],
  mounted() {
    this.nome = this.exame.nome
  },
  data: () => ({
    nome: '',
    loading: false
  }),
  props: {
    exame: {
      type: Object,
      required: true,
      validator: obj => {
        return obj.id && obj.nome
      }
    }
  },
  methods: {
    edit() {
      const exame = {}
      Object.assign(exame, this.exame)
      exame.nome = this.nome
      // exame.alterado_por = this.$_GETTERS_usuario.id
      const exameService = new ExameService()
      this.loading = true
      exameService.updateNomeExame({
        onSucess: status => body => {
          this.$_ACTIONS_showSnackbarMessage({
            message: body.message || this.$global.messages.internalServerError,
            color: status === 200 ? 'sucess' : 'error'
          })
        },
        onError: () => {
          this.$_ACTIONS_showSnackbarMessage({
            message: this.$global.messages.internalServerError,
            color: 'error'
          })
        },
        onEnd: () => {
          this.loading = false
          this.$emit('response', exame)
        }
      }, exame)
    }
  },
  computed: {
    allowEdition () {
      return true;
    }
  }
}
</script>