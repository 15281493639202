<template>
    <div>
        <div class="px-2 text-right">
            <v-btn
                text
                @click="editandoAlterarSenha = !editandoAlterarSenha"
            >
                <v-icon v-if="editandoAlterarSenha">
                mdi-close
                </v-icon>
                <v-icon v-else>
                mdi-pencil
                </v-icon>
            </v-btn>
        </div>
        <v-form ref="form" v-model="valid" lazy-validation>
            <div class="row justify-center">
                <div class="d-flex col-md-12">
                    <div class="col-4">
                        <v-text-field 
                            v-model="senha_atual" 
                            label="Senha atual"
                            placeholder=" "
                            :append-icon="showSenhaAtual ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="showSenhaAtual ? 'text' : 'password'"
                            :rules="validationSenhaAtual"
                            :disabled="!editandoAlterarSenha" 
                            style="padding-top: 0 !important;"
                            @click:append="showSenhaAtual = !showSenhaAtual"
                        ></v-text-field>
                    </div>
                    <div class="col-4">
                        <v-text-field 
                            label="Nova senha"
                            v-model="nova_senha" 
                            :append-icon="showConfirmNovaSenha ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="showConfirmNovaSenha ? 'text' : 'password'"
                            :rules="validationNovaSenha"
                            :disabled="!editandoAlterarSenha" 
                            style="padding-top: 0 !important;"
                            placeholder=" "
                            @click:append="showConfirmNovaSenha = !showConfirmNovaSenha"
                        ></v-text-field>
                    </div>
                    <div class="col-4">
                        <v-text-field 
                            label="Confirmar nova senha"
                            v-model="confirmar_senha" 
                            :append-icon="showNovaSenha ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="showNovaSenha ? 'text' : 'password'"
                            :rules="validationConfirmarSenha"
                            :disabled="!editandoAlterarSenha" 
                            placeholder=" "
                            style="padding-top: 0 !important;"
                            @click:append="showNovaSenha = !showNovaSenha"
                        ></v-text-field>
                    </div>
                </div>
            </div>
            <div class="px-2 text-right">
                <v-btn
                    color="success"
                    :disabled="!editandoAlterarSenha"
                    :loading="loading"
                    @click="salvar"
                >
                    Salvar
                </v-btn>
            </div>
        </v-form>
    </div>
</template>

<script>
import UsuarioService from '@/services/usuario_service.js'
import VuexUsuarioMixin from '@/mixins/vuex_usuario_mixin.js'
import VuexSnackbarMixin from '@/mixins/vuex_snackbar_mixin.js'
import ValidationService from "@/services/validation_service.js";


export default {
    mixins: [VuexUsuarioMixin, VuexSnackbarMixin],
    data: () => ({
        valid: false,
        validationService: {},
        showSenhaAtual: false,
        showNovaSenha: false,
        showConfirmNovaSenha: false,
        loading:false,
        senha_atual:'',
        nova_senha:'',
        confirmar_senha:'',
        editandoAlterarSenha: null,
    }),
    beforeMount () {
        this.validationService = ValidationService
    },
    computed: {
        validationSenhaAtual () {
            return [
                this.validationService.required('Informe sua senha atual'),
            ];
        },
        validationNovaSenha () {
            return [
                this.validationService.required('Informe sua nova senha'),
                this.validationService.doesNotMatch(this.senha_atual, 'A nova senha deve ser diferente da atual.'),
                this.validationService.minLength(6, 'Sua senha deve ter, no mínimo, 6 caracteres')
            ];
        },
        validationConfirmarSenha () {
            return [
                this.validationService.required('Confirme sua nova senha'),
                this.validationService.match(this.nova_senha, 'Os campos não correspondem.'),
            ];
        }
    },
    methods: {
        salvar () {
            if (!this.$refs.form.validate() || !this.valid) {
                return;
            }
            const usuario_service = new UsuarioService
            this.loading = true
            usuario_service.alterarSenha({
                onSucess: status => body => {
                    this.$_ACTIONS_showSnackbarMessage({
                        message: body.message || this.$global.messages.internalServerError,
                        color: status === 200 ? 'sucess' : 'error'
                    })
                    if (status === 200) {
                        this.editandoAlterarSenha = !this.editandoAlterarSenha
                        this.$refs.form.resetValidation();
                        this.senha_atual = ''
                        this.nova_senha = ''
                        this.confirmar_senha = ''
                    }
                },
                onError: (error) => {
                    console.error(error)
                    this.$_ACTIONS_showSnackbarMessage({
                        message: this.$global.messages.internalServerError,
                        color: 'error'
                    })
                },
                onEnd: () => {
                    this.loading = false
                },
                }, {'senha_atual':this.senha_atual, 'nova_senha':this.nova_senha}, this.$_GETTERS_usuario.id)

        },
    },
}
</script>