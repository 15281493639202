import HTTPService from "@/services/http_service.js";

export default class LegacyVetusService extends HTTPService {
  token = JSON.parse(sessionStorage.getItem("vuex")).token;

  static build() {
    return new LegacyVetusService();
  }

  getClientbyCPF(cpf) {
    const model = {
      cpf: cpf,
      token: this.token,
    };
    // return this.post(`${this.resource}/findClient`, { ...model });
    return this.postVetus(`findClient`, model);
  }

  getPetbyClientID(id) {
    const model = {
      id_cliente: id,
      token: this.token,
    };
    return this.postVetus(`findPetsByClient`, model);
  }

  getPetbyID(id) {
    const model = {
      id_pet: id,
      token: this.token,
    };
    return this.postVetus(`findPetsByClient`, model);
  }

  getFilebyPetID(id) {
    const model = {
      id_pet: id,
      token: this.token,
    };
    return this.postVetus(`findArquivosListByPetId`, model);
  }

  getAnamnesebyPetID(id) {
    const model = {
      id_pet: id,
      token: this.token,
    };
    return this.postVetus(`findAnamnesesPetById`, model);
  }

  getAnamneseDetailbyID(id) {
    const model = {
      id_anamnese: id,
      token: this.token,
    };
    return this.postVetus(`findAnamneseDetalheById`, model);
  }

  getAnamneseInternacaobyID(id) {
    const model = {
      id_anamnese_internacao: id,
      token: this.token,
    };
    return this.postVetus(`findAnamneseInternacaoById`, model);
  }
}
