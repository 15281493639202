import HTTPService from "@/services/http_service.js";
import { global } from "@/config/constants";

export default class TipoEstornoService extends HTTPService {
  resource = "tipo-extorno";

  static build() {
    return new TipoEstornoService();
  }

  async register(tipo_estorno) {
    const headers = new Headers();

    const token = JSON.parse(sessionStorage.getItem("vuex")).token;
    headers.append("Authorization", "Bearer " + token);

    const requestOptions = {
      method: "POST",
      body: tipo_estorno,
      headers: headers,
    };
    return await fetch(
      `${global.api.baseURL}/${this.resource}`,
      requestOptions
    ).then(async (response) => {
      return response;
    });
  }

  getTipoEstornoPaginatedList({ page, per_page, search, order, desc }) {
    return this.get(this.resource, {
      page,
      per_page,
      search,
      order,
      desc,
    });
  }

  async editarTipoEstorno(tipoEstornoId, tipoEstorno) {
    const headers = new Headers();
    const token = JSON.parse(sessionStorage.getItem("vuex")).token;
    headers.append("Authorization", "Bearer " + token);

    const requestOptions = {
      method: "POST",
      body: tipoEstorno,
      headers: headers,
    };
    return await fetch(
      `${global.api.baseURL}/${this.resource}/${tipoEstornoId}`,
      requestOptions
    ).then(async (response) => {
      return response;
    });
  }
}
