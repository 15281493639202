<template>
  <v-card>
    <v-toolbar style="background-color: #1daf80; color: #fff">
      <v-btn icon dark @click="close()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>
        <span class="headline">Novo Procedimento Especialidade</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items> </v-toolbar-items>
    </v-toolbar>
    <div class="p-5" v-if="!loading">
      <form class="p-lg-3 row">
        <div class="col-lg-12 form-group clearfix mb-3">
          <label>Tipo Procedimento</label>
          <v-select
            style="padding-top: 0 !important"
            :items="tipoProcedimentoList"
            :rules="[rules.required]"
            v-model="tipoProcedimentoId"
            item-text="tipoProcedimentoDescricao"
            item-value="tipoProcedimentoId"
          />
        </div>

        <div class="col-lg-12 form-group clearfix mb-3">
          <label>Especialidade</label>
          <v-select
            style="padding-top: 0 !important"
            :items="especialidadesList"
            :rules="[rules.required]"
            v-model="especialidadeId"
            item-text="especialidadeDescricao"
            item-value="especialidadeId"
          />
        </div>

        <div class="col-lg-12 form-group clearfix mb-3">
          <label for="nome">Procedimento</label>
          <v-autocomplete
            class="mb-0 mt-0"
            v-model="procedimentoId"
            :items="procedimentosListByTipoProcedimento"
            :loading="loadingProcedimento"
            color="#1daf80"
            hide-no-data
            hide-details
            :search-input.sync="search"
            label="Buscar por nome"
            item-text="procedimentoDescricao"
            item-value="procedimentoId"
          >
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title
                  v-html="data.item.procedimentoDescricao"
                ></v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
          <!-- <v-select
            :items="procedimentosList"
            v-model="procedimentoId"
            :loading="loadingProcedimento"
            :rules="[rules.required]"
            style="padding-top: 0 !important"
          ></v-select> -->
        </div>

        <div class="col-md-12 text-right">
          <button
            @click.prevent="cadastroProcedimentoEspecialidade"
            class="btn btn-app-primary btn-rounded font-weight-bold"
          >
            <span v-if="!loading"> Salvar </span>
            <v-progress-circular
              v-else
              indeterminate
              color="white"
            ></v-progress-circular>
          </button>
        </div>
      </form>
    </div>
    <div v-else>
      <v-progress-circular
        :size="70"
        :width="5"
        class="my-5"
        indeterminate
        color="green"
      ></v-progress-circular>
    </div>
  </v-card>
</template>

<script>
import Mixin from "@/mixins/vuex_mixin.js";
import ProcedimentoService from "../../services/procedimento_service.js";
import TipoProcedimentoService from "../../services/tipo_procedimento_service.js";
import EspecialidadeService from "../../services/especialidade_service.js";
import ProcedimentoEspecialidadeService from "../../services/procedimento_especialidade_service.js";

export default {
  mixins: [Mixin],

  data: () => ({
    search: "",
    procedimentoId: 0,
    dataVigor: "",
    singleSelect: false,
    loading: false,
    formCadastro: "",
    novoEspecialidade: "",
    loadingProcedimento: false,
    tipoProcedimentoId: 0,
    especialidadeId: 0,
    unidadeId: JSON.parse(sessionStorage.vuex).unidade.id,
    procedimentosList: [],
    tipoProcedimentoList: [],
    especialidadesList: [],
    procedimentosListByTipoProcedimento: [],
    tipoProcedimentoService: TipoProcedimentoService.build(),
    especialidadeService: EspecialidadeService.build(),
    procedimentoService: ProcedimentoService.build(),
    procedimentoEspecialidadeService: ProcedimentoEspecialidadeService.build(),
    rules: {
      required: (value) => !!value || "Campo obrigatório.",
    },
  }),
  props: {},
  watch: {
    tipoProcedimentoId(tipoProcedimentoId) {
      this.procedimentosListByTipoProcedimento = this.procedimentosList.filter(
        (procedimento) => {
          return procedimento.tipoProcedimentoId == tipoProcedimentoId;
        }
      );
    },
  },
  async mounted() {
    this.loading = true;
    this.setProcedimentoAndTipoProcedimentoAndEspecialidade().finally(() => {
      this.loading = false;
    });
  },
  methods: {
    async setProcedimentoAndTipoProcedimentoAndEspecialidade() {
      this.procedimentosList = [];
      this.tipoProcedimentoList = [];
      const [
        procedimentos,
        tipoProcedimentosResponse,
        especialidadesResponse,
      ] = await Promise.all([
        this.procedimentoService.findAll(),
        this.tipoProcedimentoService.getTipoProcedimentos(),
        this.especialidadeService.getEspecialidadeList(),
      ]);
      console.log(procedimentos);
      console.log(tipoProcedimentosResponse);
      this.procedimentosList = procedimentos.map((procedimento) => {
        return {
          procedimentoId: procedimento.id,
          procedimentoDescricao: procedimento.descricao,
          tipoProcedimentoId: procedimento.tipoProcedimentoId,
          tipoProcedimentoDescricao: procedimento.tipoProcedimento,
        };
      });
      const dataTipoProcedimento = await tipoProcedimentosResponse.json();
      this.tipoProcedimentoList = dataTipoProcedimento.data.tipo_procedimentos.map(
        (tipoProcedimento) => {
          return {
            tipoProcedimentoId: tipoProcedimento.id,
            tipoProcedimentoDescricao: tipoProcedimento.descricao,
          };
        }
      );
      const dataEspedialidades = await especialidadesResponse.json();
      this.especialidadesList = dataEspedialidades.data.especialidades.map(
        (especialidade) => {
          return {
            especialidadeId: especialidade.id,
            especialidadeDescricao: especialidade.descricao,
          };
        }
      );
    },
    async getProcedimentos() {
      this.loadingProcedimento = true;
      await this.procedimentoService
        .findAll()
        .then(async (response) => {
          this.procedimentosList = response.map((procedimento) => {
            return {
              procedimentoId: procedimento.id,
              procedimentoDescricao: procedimento.descricao,
              tipoProcedimentoId: procedimento.id_tipo_procedimento,
              tipoProcedimentoDescricao: procedimento.tipo_procedimento,
            };
          });
        })
        .finally(() => {
          this.loadingProcedimento = false;
        });
    },
    async getTipoProcedimentos() {
      await this.tipoProcedimentoService
        .getTipoProcedimentos()
        .then(async (response) => {
          console.log(response);
          const { data } = await response.json();
          console.log(data);
          this.tipoProcedimentoList = data.tipo_procedimentos.map(
            (tipoProcedimento) => {
              return {
                tipoProcedimentoId: tipoProcedimento.id,
                tipoProcedimentoDescricao: tipoProcedimento.descricao,
              };
            }
          );
        })
        .finally(() => {
          this.loadingProcedimento = false;
        });
    },
    async cadastroProcedimentoEspecialidade() {
      this.loading = true;
      await this.procedimentoEspecialidadeService
        .cadastroProcedimentoEspecialidade(this.getFormData())
        .then((response) => {
          if (response.status === 200) {
            this.$emit("response");
            this.procedimentoId = 0;
            this.tipoProcedimentoId = 0;
            this.especialidadeId = 0;
          } else {
            this.$_ACTIONS_showSnackbarMessage({
              message: this.$global.messages.internalServerError,
              color: "error",
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getFormData() {
      const formData = new FormData();
      formData.append("id_tipo_procedimento", this.tipoProcedimentoId);
      formData.append("id_procedimento", this.procedimentoId);
      formData.append("id_especialidade", this.especialidadeId);
      formData.append("id_unidade", this.unidadeId);
      return formData;
    },
    close() {
      this.procedimentoId = 0;
      this.tipoProcedimentoId = 0;
      this.especialidadeId = 0;
      this.$emit("close");
    },
  },
  computed: {},
};
</script>
