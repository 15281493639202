<template>
  <div>
    <div class="p-lg-3">
      <div class="row justify-content-between">
        <h3 class="mt-0 mb-2 font-weight-bold text-left">
          Disponibilidade
        </h3>
      </div>
    </div>
    {{ valor }}
    <div class="row">
      <div class="col-12 d-flex justify-content-center">
        <v-select
          class="col-3 mt-4"
          label="Especialidades"
          style="padding-top: 0 !important"
          :items="listaEspecialidades"
          v-model="especialidadeId"
        />
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="datas"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              class="col-5"
              v-model="computedFormatDate"
              label="Data inicial - Data final"
              prepend-icon="mdi-calendar"
              clearable
              v-bind="attrs"
              v-on="on"
              @click:clear="datas = null"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="datas"
            no-title
            range
            scrollable
            color="#1daf80"
          >
            <v-spacer></v-spacer>
            <v-btn text @click="menu = false"> Cancelar </v-btn>
            <v-btn dark color="#1daf80" @click="$refs.menu.save(datas)">
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
        <v-btn
          class="ml-2 mt-3"
          outlined
          color="#1daf80"
          :disabled="disablePesquisa"
          @click="pesquisaDisponibilidade"
        >
          buscar
          <v-icon class="ml-2" small>
            mdi-magnify
          </v-icon>
        </v-btn>
      </div>
    </div>
    <div class="row" v-if="disponibilidades.length >0">
      <div class="col-12">
        <v-data-table
          v-on:update:sort-by="options.ordenacao = true"
          :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
          :search="search"
          :headers="headers"
          :items="disponibilidades"
         
          class="elevation-1"
          :loading="loading"
          :loading-text="'Carregando...'"
        >
          <template v-slot:item.actions="{ item }">
            <v-icon
              v-if="item.ativo == 1"
              small
              class="mr-2"
              color="#00730d"
              @click="toggleAtivo(item)"
              title="Desabilitar"
            >
              mdi-lock
            </v-icon>
            <v-icon
              v-if="item.ativo == 0"
              small
              color="#730000"
              class="mr-2"
              @click="toggleAtivo(item)"
              title="habilitar"
            >
              mdi-lock-open-variant
            </v-icon>
          </template>
          <template v-slot:no-data>
            <p class="text-center">Nenhum registro encontrado</p>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>

<script>
import VuexSnackbarMixin from "@/mixins/vuex_snackbar_mixin.js";
import EspecialidadeService from "@/services/especialidade_service.js";
import DisponibilidadeService from "@/services/disponibilidade_service.js";

export default {
  mixins: [VuexSnackbarMixin],
  components: {},

  data: () => ({
    dialogCriarDisponibilidade: false,
    services: {
      especialidadeService: EspecialidadeService.build(),
      disponibilidadeService: DisponibilidadeService.build(),
    },
    listaEspecialidades: [],
    especialidadeId: null,
    disablePesquisa: true,
    menu: false,
    datas: [],
    disponibilidades: [
    ],
    search: "",
    loading: false,
    totalList: 10,
    options: {
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
      order: false,
      desc: false,
    },
    headers: [
      { text: "dia", value: "dia", sortable: false },
      { text: "Início", value: "horaInicio", sortable: false },
      { text: "Fim", value: "horaFinal", sortable: false },
      { text: "", align: "end", value: "actions", sortable: false },
    ],
  }),
  async mounted() {
    this.getEspecialidades();
  },
  watch: {
    datas() {
      if (this.datas.length == 2) {
        this.validarPesquisa();
        this.dataSort();
      }
    },
    especialidadeId() {
      this.validarPesquisa();
    },
  },
  computed: {
    allowCriarDisponibilidade() {
      return true;
    },
    computedFormatDate() {
      const datasFormatadas =
        this.datas &&
        this.datas.map((item) =>
          item
            .split("-")
            .reverse()
            .join("/")
        );
      return datasFormatadas;
    },
  },
  methods: {
    async getEspecialidades() {
      await this.services.especialidadeService
        // .getEspecialidadeList()
        .getEspecialidadeListByUnidadeId(
          JSON.parse(sessionStorage.vuex).unidade.id
        )
        .then(async (response) => {
          if (response.status !== 200) return;
          const { data } = await response.json();
          this.listaEspecialidades = data.map((pet) => {
            return {
              text: pet.descricao,
              value: pet.id_especialidade,
            };
          });
        });
    },
    async getDisponibilidades() {
      await this.services.especialidadeService
        .getDisponibilidadesByEspecialidade(
          JSON.parse(sessionStorage.vuex).unidade.id
        )
        .then(async (response) => {
          if (response.status !== 200) return;
          const { data } = await response.json();
          this.listaEspecialidades = data.map((pet) => {
            return {
              text: pet.descricao,
              value: pet.id_especialidade,
            };
          });
        });
    },
    async toggleAtivo(item) {
      console.log('item')
      console.log(item)
        this.services.disponibilidadeService
        .postUpdateStatusDisponibilidade(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
             this.$_ACTIONS_showSnackbarMessage({
            message:
              status === 200
                ? `Disponibilidade alterada com sucesso.`
                : this.$global.messages.internalServerError,
            color: status === 200 ? "sucess" : "error",
          });
            } else {
              this.$_ACTIONS_showSnackbarMessage({
                message:
                  body.message || this.$global.messages.internalServerError,
                color: "error",
              });
            }
          },
          onError: (message) => {
            this.$_ACTIONS_showSnackbarMessage({
              message: message || this.$global.messages.internalServerError,
              color: "error",
            });
            this.loading = false;
          },
          onEnd: () => {
            this.pesquisaDisponibilidade();
            
          },
        },
        {
          id_agenda: parseInt(item.id),
          ativo: item.ativo == 0 ? 1 : 0,
        }
      );
    },
    async pesquisaDisponibilidade() {
       this.datas.sort();
       let dataInicio = this.datas[0].replaceAll("-", "")
       let dataFim = this.datas[1].replaceAll("-", "")
      await this.services.disponibilidadeService
        .getDisponibilidade(
          JSON.parse(sessionStorage.vuex).unidade.id,this.especialidadeId ,dataInicio ,dataFim
        )
        .then(async (response) => {
          if (response.status !== 200) return;
          const { data } = await response.json();
          if(data){

            this.disponibilidades = data.map((disponibilidade) => {
              let arrDia = disponibilidade.data_disponibilidade.split('-')
            let arrHoraInicial = disponibilidade.hora_inicial.split(':')
            let arrHoraFinal = disponibilidade.hora_final.split(':')
            let dia = `${arrDia[2]}/${arrDia[1]}/${arrDia[0]}`
            let horaInicial = `${arrHoraInicial[0]}:${arrHoraInicial[1]}`
            let horaFinal = `${arrHoraFinal[0]}:${arrHoraFinal[1]}`
            return {id: disponibilidade.id, dia: dia, horaInicio: horaInicial, horaFinal: horaFinal, ativo: disponibilidade.ativo };
          });
            }else{
              this.disponibilidades = []
               this.$_ACTIONS_showSnackbarMessage({
              message: 'Nenhuma disponibilidade encontrada!',
              color: "error",
            });
            }
        }) .catch((e) => {
          console.error(e);
           this.$_ACTIONS_showSnackbarMessage({
              message: this.$global.messages.internalServerError,
              color: "error",
            });
        })
        .finally(() => {
            this.loading = false;});
    },


    validarPesquisa() {
      if (this.datas.length == 2 && this.especialidadeId != null) {
        this.disablePesquisa = false;
      } else {
        this.disablePesquisa = true;
      }
    },
  },
};
</script>

<style>
.btn-app-primary {
  background-color: #1daf80 !important;
  border-color: #1daf80;
  color: white !important;
}
</style>
