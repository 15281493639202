import HTTPService from "@/services/http_service.js";

export default class FilaClienteService extends HTTPService {
  resource = "fila-cliente";

  static build() {
    return new FilaClienteService();
  }

  async getListByCliente(idCliente, { page, per_page }) {
    return await this.get(this.resource, {
      cliente: idCliente,
      page,
      per_page,
    });
  }
}
