import HTTPService from '@/services/http_service.js'
export default class PerfilService extends HTTPService {
    static build () {
        return new PerfilService();
    }
    /**
     @returns {Promise<Response>}
     */
    getPerfilPaginatedList ({page, per_page, search, order, desc}) {
        return this.get('perfil',{page, per_page, search, order, desc})
    }

    /**
     @returns {Promise<{perfilId: Number, descricao: String}[]>}
     */
    async getPerfilList () {
        try {
            const response = await this.get('perfil')
            if (response.status !== 200) return [];
            const {data} = await response.json();
            return data.perfis.map(({perfil_id,perfil_nome}) => ({
                perfilId: ~~perfil_id,
                descricao: perfil_nome || ''
            }));
        } catch (e) {
           return [];
        }
    }

    /**
     @returns {Promise<Response>}
     */
    cadastrarPerfil({descricao, permissoes}) {
        return this.post('perfil', {
            descricao,
            permissao: permissoes
        })
    }

    /**
     * @param {number} perfilId
     * @param {Array<Number>} permissao
     * @param {String} descricao
     * @returns {Promise<Response>}
     */
    editarPerfil(perfilId, {descricao, permissao}) {
        return this.post('perfil/' + perfilId, {descricao, permissao})
    }

    /**
     * @param {number} perfilId
     * @returns {Promise<{permissoes: {endpointPath: String, ativo, endpointController: String, endpointId, endpointAction: String, permissaoId, endpointMethod: String}[], descricao: String, perfilId: Number}>}
     */
    async getDetalhesByPerfilId(perfilId) {
        try {
            const response = await this.get('perfil/' + perfilId);
            if (response.status !== 200 && response.status !== 206) return null;
            const {data} = await response.json();
            return {
                perfilId: ~~data.perfil_id,
                descricao: data.perfil_nome || '',
                permissoes: (data.permissoes || []).map(p => ({
                   permissaoId: +p.permissao_id,
                   ativo: !!~~p.ativo,
                   endpointId: +p.endpoint_id,
                   endpointMethod: p.endpoint_method || '',
                   endpointController: p.endpoint_controller || '',
                   endpointAction: p.endpoint_action || '',
                   endpointPath: p.endpoint_path || ''
                }))
            }
        } catch (e) {
            console.error(e);
            return null;
        }
    }

    /**
     @returns {Promise<Response>}
     */
    deletarPerfil(perfilId) {
        console.log({perfilId})
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({status: 200, message: 'O perfil foi deletado com sucesso.'});
            }, 1500)
        })
    }
}
