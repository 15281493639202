import HTTPService from '@/services/http_service.js'
export default class RacaService extends HTTPService {
    static build () {
        return new RacaService();
    }
    /**
     @returns {Promise<{racas: {racaId: Number, nome: String, especieId: Number, especieDescricao: String}[], pagination: {num_rows: Number}}>}
     */
    async getRacaPaginatedList ({page, per_page, search, order, desc}) {
        try {
            const response = await this.get('raca', {page, per_page, nome: search, order, desc})
            if (response.status !== 200) return {racas: [], pagination: null};
            const {data} = await response.json();
            let {racas, pagination} = data;
            racas = racas.map(raca => ({
                racaId: ~~raca.id,
                nome: raca.nome || '',
                especieId: ~~raca.id_especie,
                especieDescricao: raca.especie
            }));
            return {racas, pagination};
        } catch (e) {
            return {pagination: null, racas: []};
        }
    }

    /**
     @returns {Promise<{racaId: Number, nome: String, especieId: Number, especieDescricao: String}[]>}
     */
    async getRacaList () {
        try {
            const response = await this.get('raca')
            if (response.status !== 200) return [];
            const {data} = await response.json();
            return data.racas.map(raca => ({
                racaId: ~~raca.id,
                nome: raca.nome || '',
                especieId: ~~raca.id_especie,
                especieDescricao: raca.especie
            }));
        } catch (e) {
           return [];
        }
    }

    /**
         @returns {Promise<Number>}
     */
    async cadastrarRaca({nome, especieId}) {
        try {
            const response = await this.post('raca', {
                nome,
                id_especie: especieId
            })
            const {data} = await response.json();
            return ~~(data || {}).id_raca;
        } catch (e) {
           return 0;
        }
    }

    /**
     * @param {number} racaId
     * @param {Number} especieId
     * @param {String} nome
     * @returns {Promise<Boolean>}
     */
    async editarRaca(racaId, {nome, especieId}) {
        try {
            const {status} = await this.post('raca/' + racaId, {
                nome,
                id_especie: especieId
            })
            return status === 200;
        } catch (e) {
            console.error({e});
            return false;
        }
    }

    /**
     * @param {number} racaId
     * @returns {Promise<{racaId,nome,especieId,especieDescricao}>}
     */
    async getDetalhesByRacaId(racaId) {
        try {
            const response = await this.get('raca/' + racaId);
            if (response.status !== 200) return {};
            const {data} = await response.json();
            return {
                racaId: ~~data.id,
                nome: data.nome || '',
                especieId: ~~data.id_especie,
                especieDescricao: ~~data.id_especie,
            }
        } catch (e) {
            console.error(e);
            return {};
        }
    }

    /**
     * @param {Number} racaId
     * @returns {Promise<Boolean>}
     */
    async deletarRaca(racaId) {
        try {
            const response = await this.post(`raca/${racaId}/deletar`, {})
            return response.status === 200;
        } catch (e) {
            console.error({e});
            return false;
        }
    }
}
