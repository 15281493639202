<template>
  <fila-compromisso :compromissos="compromissosPessoais" :titulo="'Compromissos pessoais'" message-on-empty="Não há nenhum compromisso pessoal marcado para hoje.">
    <template v-slot:card-compromisso="{ compromisso }">
      <card-compromisso-pessoal
          :hora-inicial="compromisso.horarioInicio"
          :hora-final="compromisso.horarioFinal"
          :nome-paciente="compromisso.nomePaciente"
          :procedimento="compromisso.procedimento"
      />
    </template>
  </fila-compromisso>
</template>

<script>
import FilaCompromisso from "@/components/dashboards/dashboard_medico/compromissos/templates/fila_compromisso";
import CardCompromissoPessoal from "@/components/dashboards/dashboard_medico/compromissos/card_compromisso_pessoal";
export default {
  components: { FilaCompromisso, CardCompromissoPessoal },
  props: {
    compromissosPessoais: { type: Array, required: true }
  }
}
</script>