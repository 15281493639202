import HTTPService from "@/services/http_service.js";
import { global } from "@/config/constants";

export default class EspecieService extends HTTPService {
  resource = "especie";

  static build() {
    return new EspecieService();
  }

  /**
        @returns {Promise<{especieId: Number, nome: String}[]>}
     */
  getEspeciesPaginatedList({ page, per_page, search, order, desc }) {
    return this.get(this.resource, {
      page,
      per_page,
      search,
      order,
      desc,
    });
  }

  async register(especie) {
    const headers = new Headers();

    const token = JSON.parse(sessionStorage.getItem("vuex")).token;
    headers.append("Authorization", "Bearer " + token);

    const requestOptions = {
      method: "POST",
      body: especie,
      headers: headers,
    };
    return await fetch(
      `${global.api.baseURL}/${this.resource}`,
      requestOptions
    ).then(async (response) => {
      console.log(response);
      return response;
    });
  }

  async editarEspecie(especieId, especie) {
    const headers = new Headers();
    const token = JSON.parse(sessionStorage.getItem("vuex")).token;
    headers.append("Authorization", "Bearer " + token);

    const requestOptions = {
      method: "POST",
      body: especie,
      headers: headers,
    };
    return await fetch(
      `${global.api.baseURL}/${this.resource}/${especieId}`,
      requestOptions
    ).then(async (response) => {
      console.log(response);
      return response;
    });
  }
}
