<template>
  <div>
    <div v-if="pergunta.id_tipo_pergunta===1" class="justify-center">
      <form-field-tipo-text @rchange="emitChanges" :pergunta="pergunta" :disabled="disabled"/>
    </div>

    <div v-if="pergunta.id_tipo_pergunta===2">
      <form-field-tipo-paragrafo @rchange="emitChanges" :pergunta="pergunta" :disabled="disabled"/>
    </div>
      
     <div v-if="pergunta.id_tipo_pergunta===3">
      <form-field-tipo-radio @rchange="emitChanges" :pergunta="pergunta" :disabled="disabled"/>
    </div>

    <div v-if="pergunta.id_tipo_pergunta===4">
      <form-field-tipo-check @rchange="emitChanges" :pergunta="pergunta" :disabled="disabled"/>
    </div>

   <!-- 
    <div v-if="pergunta.id_tipo_pergunta===5">
      <form-field-tipo-text :pergunta="pergunta" :disabled="disabled"/>
    </div>

    <div v-if="pergunta.id_tipo_pergunta===6">
      <form-field-tipo-text :pergunta="pergunta" :disabled="disabled"/>
    </div>

    <div v-if="pergunta.id_tipo_pergunta===7">
      <form-field-tipo-text :pergunta="pergunta" :disabled="disabled"/>
    </div> -->
  </div>
</template>
<script>
import Mixin from "@/mixins/vuex_snackbar_mixin.js";
import FormFieldTipoText from "@/components/prontuarios/consulta_campo_text.vue";
import FormFieldTipoParagrafo from "@/components/prontuarios/consulta_campo_paragrafo.vue";
import FormFieldTipoRadio from "@/components/prontuarios/consulta_campo_radio.vue";
import FormFieldTipoCheck from "@/components/prontuarios/consulta_campo_check.vue";

export default {
  mixins: [Mixin],
  props:{
    pergunta:Object,
    disabled:Boolean
  },
  components:{
    FormFieldTipoText,
    FormFieldTipoParagrafo,
    FormFieldTipoRadio,
    FormFieldTipoCheck
  },
  data: () => ({

  }),
  watch: {
  },
  methods: {
    emitChanges(change) {
      this.$emit("rchange", {pergunta: this.pergunta, alteracao: change})
    }
  }
};
</script>
<style scoped>
.v-progress-circular {
  margin: 1rem;
}
label{
  font-weight: 900;
  font-size: 1.2rem;
}
</style>