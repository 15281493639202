<template>
  <div>
    <div class="p-lg-3">
      <div class="row justify-content-between">
        <h3 class="mt-0 mb-2 font-weight-bold text-left">Internação</h3>
      </div>
    </div>
    <v-row class="mt-2 px-2" align="center">
      <v-select
        :items="unidades"
        label="Unidade"
        item-value="id"
        v-model="unidadeSelected"
        outlined
        class="col-3"
      ></v-select>
      <v-select
        :items="filtrosInternacao"
        label="Filtrar"
        item-value="id"
        v-model="filtroInternacao"
        outlined
        class="col-3 ml-2"
      ></v-select>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Nome do pet"
        class="col-3 ml-2"
        outlined
      ></v-text-field>
      <v-btn
        class="bg-verde mt-n6 ml-4"
        dark
        rounded
        @click="listarInternados(unidadeSelected)"
        >Buscar</v-btn
      >
    </v-row>
    <div>
      <!-- <v-data-table
        :headers="headersInternacao"
        :items="internacaoList"
        class="elevation-1 mt-5"
        :loading="loadingInternados"
        :options.sync="options"
        :server-items-length="totalList"
      > -->
      <v-data-table
        :headers="headersInternacao"
        :items="internacaoList"
        class="elevation-1 mt-5"
        :loading="loadingInternados"
      >
        <template v-slot:item.nome_cliente="{ item }">
          {{
            item.nome_cliente
              .split(" ")
              .slice(0, 4)
              .join(" ")
          }}
        </template>
        <template v-slot:item.data_entrada_internacao="{ item }">
          {{
            item.data_entrada_internacao &&
              item.data_entrada_internacao
                .split(" ")[0]
                .split("-")
                .reverse()
                .join("/") +
                " - " +
                item.data_entrada_internacao.split(" ")[1]
          }}
        </template>
        <template v-slot:item.data_saida_internacao="{ item }">
          {{
            item.data_saida_internacao &&
              item.data_saida_internacao
                .split(" ")[0]
                .split("-")
                .reverse()
                .join("/") +
                " - " +
                item.data_saida_internacao.split(" ")[1]
          }}
        </template>
        <template v-slot:item.actions="{ item }">
          <div class="d-flex items-center">
            <v-btn
              class="py-4 mr-2"
              text
              title="REQUISIÇÕES"
              x-small
              @click="openDialogRequisicao(item)"
            >
              <v-icon>mdi-clipboard-arrow-up-outline</v-icon>
            </v-btn>

            <v-btn
              class="py-4 mr-2 bg-verde"
              text
              dark
              title="ATENDER"
              x-small
              v-if="[2, 3].includes(perfilUsuario)"
              @click="atender(item)"
            >
              <v-icon>mdi-clipboard-text-play</v-icon>
            </v-btn>
            <v-btn
              class="py-4 mr-2"
              text
              title="PRONTUÁRIOS"
              x-small
              @click="listProntuarios(item)"
            >
              <v-icon color="primary">fas fa-file-medical-alt </v-icon>
            </v-btn>

            <v-btn
              x-small
              :dark="!loadingInternados"
              rounded
              color="warning"
              class="p-2 w-50"
              :disabled="loadingInternados"
              @click="showDialogTipoSaida(item)"
              v-if="(item.codigo == 2) & [2, 3].includes(perfilUsuario)"
            >
              Alta Médica
            </v-btn>
            <v-btn
              x-small
              :dark="!loadingInternados"
              rounded
              color="error"
              class="p-2 w-50"
              :disabled="loadingInternados"
              @click="iniciarAltaHospitalar(item)"
              v-if="(item.codigo == 3) & [2, 3].includes(perfilUsuario)"
            >
              Alta Hospitalar
            </v-btn>
          </div>
        </template>
      </v-data-table>
      <v-dialog
        v-model="dialogConfirmarRequisicaoSaida"
        v-if="dialogConfirmarRequisicaoSaida"
        width="400px"
      >
        <v-card class="p-1">
          <h3 class="p-3" v-if="tipoSaida == 'obito'">
            Confirma a saída deste pet por óbito?
          </h3>
          <h3 class="p-3" v-else>
            Deseja gerar alta médica para este pet?
          </h3>
          <div class="pb-3 d-flex justify-space-around w-50 m-auto">
            <v-btn
              :disabled="disableBtn"
              color="error"
              text
              rounded
              @click="dialogConfirmarRequisicaoSaida = false"
              >Não</v-btn
            >
            <v-btn
              :disabled="disableBtn"
              class="bg-verde"
              :dark="!disableBtn"
              rounded
              @click="prepareFormAltaInternacao()"
              >Sim</v-btn
            >
          </div>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogRequisicao"
        v-if="dialogRequisicao"
        transition="dialog-bottom-transition"
        scrollable
        fullscreen
      >
        <v-card>
          <v-toolbar class="modal-nav-ajustes"
            ><v-btn icon dark @click="dialogRequisicao = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Requisições</v-toolbar-title>
          </v-toolbar>
          <v-divider></v-divider>
          <requisicao-modal
            v-if="dialogRequisicao"
            @close="dialogRequisicao = false"
            :petId="petId"
            :tutorId="clienteId"
          >
          </requisicao-modal>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogTipoSaidaInternacao"
        v-if="dialogTipoSaidaInternacao"
        transition="dialog-bottom-transition"
        width="500px"
      >
        <v-card>
          <v-card-title>
            <div class="m-auto">
              <h3>Escolha o tipo de saída</h3>
            </div>
          </v-card-title>
          <v-card-text>
            <div class="d-flex flex-column px-4 pb-2 w-75 m-auto">
              <v-btn
                dark
                class="mb-3 bg-verde"
                @click="showdialogConfirmarSaida('alta')"
                >Alta médica</v-btn
              >
              <v-btn
                dark
                class="bg-verde"
                @click="showdialogConfirmarSaida('obito')"
                >Óbito</v-btn
              >
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogFormAltaInternacao"
        v-if="dialogFormAltaInternacao"
        transition="dialog-bottom-transition"
        width="600px"
      >
        <v-card>
          <v-toolbar class="modal-nav-ajustes mb-5"
            ><v-btn icon dark @click="dialogFormAltaInternacao = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title v-if="this.internacao.codigo == 3"
              >Anamnese - Alta Hospitalar</v-toolbar-title
            >
            <v-toolbar-title v-else>Anamnese - Alta Internação</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <div
              v-for="(input, index) in form.perguntas"
              :key="`pergunta${index}`"
            >
              <div class="col-12 d-flex justify-center">
                <form-consulta-fill
                  :pergunta="input"
                  v-if="input"
                  @resposta="respondido($event)"
                  class="col-12"
                />
              </div>
            </div>
          </v-card-text>
          <v-card-actions>
            <div class="w-100 d-flex justify-end">
              <v-btn
                :dark="!disableBtn"
                class="bg-verde mb-2"
                :disabled="disableBtn"
                @click="salvarFormAltaInternacao"
              >
                Salvar
              </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        max-width="500px"
        persistent
        fullscreen
        v-if="dialogAtender"
        v-model="dialogAtender"
      >
        <modal-atender
          v-if="dialogAtender"
          :petCodigo="internacao.id_pet"
          :petNome="internacao.nome_pet"
          :unidadeId="unidadeSelected"
          @close="dialogAtender = false"
        />
      </v-dialog>
      <v-dialog
        v-model="dialogAtenderMedico"
        v-if="dialogAtenderMedico"
        max-width="600px"
      >
        <v-card>
          <v-flex>
            <v-toolbar class="bg-verde" dark>
              <v-toolbar-title>Iniciar Atendimento</v-toolbar-title>
              <v-spacer />
              <v-btn icon dark @click="dialogAtenderMedico = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
          </v-flex>
          <v-card-text class="pb-5">
            <div class="p-2">
              <div class="col-lg-12">
                <div class="mx-auto">
                  <h3>
                    Escolha a requisição
                  </h3>
                  <div class="mt-8">
                    <v-autocomplete
                      v-model="procedimento"
                      :items="procedimentos"
                      :loading="procedimentoLoading"
                      background-color="white"
                      label="Procedimentos"
                      item-text="procedimento"
                      return-object
                      color="black"
                    >
                      <template v-slot:item="{ item }">
                        <v-list-item-content>
                          <v-list-item-title v-html="item.procedimento" />
                          <v-list-item-subtitle
                            class="mt-1"
                            v-html="
                              item.tipo_procedimento +
                                ' - ' +
                                item.convenio_tipo +
                                ` ${item.data_requisicao &&
                                  '( ' +
                                    item.data_requisicao
                                      .split('-')
                                      .reverse()
                                      .join('-') +
                                    ' )'}`
                            "
                          />
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                    <v-btn @click="iniciarAtendimento"
                      >Iniciar atendimento</v-btn
                    >
                  </div>
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogProntuario"
        v-if="dialogProntuario"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <prontuarios
          :consultas="prontuarios"
          :pacienteInfo="pacienteInfo"
          @close="dialogProntuario = false"
        />
      </v-dialog>
    </div>
  </div>
</template>

<script>
import UnidadeService from "../services/unidade_service";
import RequisicaoModal from "@/components/recepcao_medico/requisicaoModal/index.vue";
import InternacaoService from "../services/internacao_service";
import FormConsultaFill from "@/components/consultas/formulario_consulta_fill.vue";
import ModalAtender from "@/components/fila_atendimento/modal_atender";
import RequisicaoExameService from "@/services/requisicao_exame_service.js";
import AgendamentoService from "@/services/agendamento_service";
import Prontuarios from "@/components/prontuarios/prontuarios.vue";
import PetsService from "@/services/pets_service";
import Mixin from "@/mixins/vuex_mixin.js";

export default {
  components: {
    RequisicaoModal,
    FormConsultaFill,
    ModalAtender,
    Prontuarios,
  },
  mixins: [Mixin],
  data() {
    return {
      dialogTipoSaidaInternacao: false,
      perfilUsuario: "",
      unidades: [],
      unidadeSelected: "",
      services: {
        unidadeService: UnidadeService.build(),
        internacaoService: InternacaoService.build(),
        requisicaoExameService: RequisicaoExameService.build(),
        agendamentoService: AgendamentoService.build(),
        petService: PetsService.build(),
      },
      options: {
        page: 1,
        itemsPerPage: 15,
      },
      totalList: 0,
      filtroParams: {
        executado: "",
        status: "",
      },
      search: "",
      internacaoList: [],
      headersInternacao: [
        {
          text: "ID Pet",
          sortable: false,
          value: "id_pet",
        },
        { text: "Pet", value: "nome_pet" },
        { text: "Cliente", value: "nome_cliente" },
        { text: "Data - Hora de entrada", value: "data_entrada_internacao" },
        { text: "Data - Hora de Alta Médica", value: "data_saida_internacao" },
        // { text: "Criado por", value: "nome_criador" },
        { text: "Status", value: "nome_status_requisicao" },
        { text: "Ações", value: "actions", align: "center" },
      ],
      loadingInternados: false,
      filtrosInternacao: [
        { id: 0, text: "Aguardando Entrada" },
        { id: 1, text: "Internado" },
        { id: 2, text: "Alta Médica" },
        { id: 3, text: "Todos" },
      ],
      filtroInternacao: "",
      dialogConfirmarRequisicaoSaida: false,
      dialogRequisicao: false,
      dialogFormAltaInternacao: false,
      unidadeLogado: "",
      petId: "",
      clienteId: "",
      tipoSaida: "",
      formId: "",
      form: {},
      resp: [],
      disableBtn: false,
      form_resp: {
        id_formulario: 0,
        titulo: "",
        descricao: "",
        respostas: [],
      },
      internacao: {},
      dialogAtender: false,
      dialogAtenderMedico: false,
      procedimento: 0,
      procedimentos: [],
      procedimentoLoading: false,
      dialogProntuario: false,
      prontuarios: [],
      pacienteInfo: {},
    };
  },
  mounted() {
    this.perfilUsuario = JSON.parse(sessionStorage.getItem("vuex")).perfil.id;
    this.setUnidadeLogado();
    this.setUnidades();
    this.unidadeSelected = this.unidadeLogado.toString();
    this.listarInternados(this.unidadeSelected);
  },
  watch: {
    options: {
      handler() {
        this.listarInternados(this.unidadeSelected);
      },
      deep: true,
    },
    search(value, oldValue) {
      if (value.length > 2) {
        this.options.page = 1;
        this.listarInternados(this.unidadeSelected);
      }
      if (value.length === 0 && oldValue.length > 0) {
        this.options.page = 1;
        this.listarInternados(this.unidadeSelected);
      }
    },
    filtroInternacao(value) {
      if (value == 0) {
        this.filtroParams = {
          executado: 1,
          status: 1,
        };
      } else if (value == 1) {
        this.filtroParams = {
          executado: 2,
          status: 1,
        };
      } else if (value == 2) {
        this.filtroParams = {
          executado: "",
          status: 2,
        };
      } else {
        this.filtroParams = {
          executado: "",
          status: "",
        };
      }
    },
  },
  methods: {
    respondido(e) {
      if (this.resp.length < 1) {
        this.resp.push({ id_pergunta: e.id_pergunta, resposta: e.resposta });
      } else {
        let respEvent = this.resp
          .map((p) => p.id_pergunta)
          .indexOf(e.id_pergunta);
        if (respEvent >= 0) {
          this.resp[respEvent] = {
            id_pergunta: e.id_pergunta,
            resposta: e.resposta,
          };
        } else {
          this.resp.push({ id_pergunta: e.id_pergunta, resposta: e.resposta });
        }
      }
    },
    listarInternados(unidadeId) {
      this.loadingInternados = true;

      const params = {
        page: this.options.page,
        per_page: this.options.itemsPerPage,
        pet: this.search,
        executado: this.filtroParams.executado,
        status: this.filtroParams.status,
      };

      this.internacaoList = [];
      this.services.internacaoService
        .listarInternados(params, unidadeId)
        .then((resp) => {
          if (resp.status == 200) {
            return resp.json();
          } else if (resp.status == 206) {
            this.internacaoList = [];
          }
          this.loadingInternados = false;
        })
        .then((resp) => {
          this.internacaoList = resp.data.internacao;
          this.totalList = resp.data.internacao.length;
          // this.totalList = resp.data.pagination.num_rows;
          this.loadingInternados = false;
        });
    },
    setUnidadeLogado() {
      this.unidadeLogado = JSON.parse(
        sessionStorage.getItem("vuex")
      ).unidade.id;
    },
    setUnidades() {
      this.services.unidadeService
        .getUnidadeByUsuarioLogado()
        .then((response) => {
          return response.json();
        })
        .then((response) => {
          this.unidades = response.data.map((unidade) => {
            return { text: unidade.unidade, id: unidade.id_unidade };
          });
        })
        .then(() => {});
    },
    showDialogTipoSaida(internacao) {
      this.petId = internacao.id_pet;
      this.clienteId = internacao.cliente_id;
      this.dialogTipoSaidaInternacao = true;
    },
    requisitarSaidaInternacao() {
      this.services.internacaoService
        .requisitarSaidaInternacao(
          this.clienteId,
          this.petId,
          this.unidadeSelected
        )
        .then((resp) => {
          console.log(resp);
          this.dialogConfirmarRequisicaoSaida = false;
          this.listarInternados(this.unidadeSelected);
        });
    },
    openDialogRequisicao(item) {
      this.petId = item.id_pet;
      this.clienteId = item.cliente_id;
      this.dialogRequisicao = true;
    },
    gerarAlta(item) {
      console.log(item);
    },
    showdialogConfirmarSaida(tipoSaida) {
      this.tipoSaida = tipoSaida;
      this.dialogTipoSaidaInternacao = false;
      this.dialogConfirmarRequisicaoSaida = true;
    },
    async prepareFormAltaInternacao() {
      this.disableBtn = true;
      const tipoSaida = this.tipoSaida == "obito" ? 2 : 1;
      this.formId = await this.services.internacaoService
        .getIdFormularioAltaMedica(tipoSaida)
        .then((resp) => {
          if (resp.status == 200) {
            return resp.json();
          }
        })
        .then((resp) => resp.data[0].id)
        .catch(() => {
          this.disableBtn = false;
          this.loadingInternados = false;
          this.$_ACTIONS_showSnackbarMessage({
            message: "Nenhum formulário encontrado para Alta Médica",
            color: "error",
          });
        });

      this.loadingForm(this.formId);
    },
    loadingForm(formId) {
      this.form = {};
      this.services.internacaoService
        .getFormulario(formId)
        .then((resp) => {
          if (resp.status == 200) {
            return resp.json();
          } else {
            this.disableBtn = false;
            this.dialogConfirmarRequisicaoSaida = false;
            this.$_ACTIONS_showSnackbarMessage({
              message: this.$global.messages.internalServerError,
              color: "error",
            });
          }
        })
        .then((resp) => {
          console.log(resp);
          this.form.titulo = resp.data.titulo;
          this.form.descricao = resp.data.descricao;
          this.form.id_medico = resp.data.id_medico;
          this.form.perguntas = resp.data.perguntas
            .filter((pergunta) => pergunta.ativo)
            .sort((a, b) => {
              if (a.ordem < b.ordem) return -1;
              if (a.ordem > b.ordem) return 1;
              return 0;
            });
        })
        .then(() => {
          this.disableBtn = false;
          this.dialogConfirmarRequisicaoSaida = false;
          this.dialogFormAltaInternacao = true;
          this.loadingInternados = false;
        });
    },
    salvarFormAltaInternacao() {
      this.disableBtn = true;
      this.form_resp.titulo = "titulo";
      this.form_resp.descricao = "descricao";
      this.form_resp.id_formulario = parseInt(this.formId);
      this.form_resp.respostas = this.resp;
      this.services.internacaoService.registerAltaMedica(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.form_resp = {};
              this.resp = [];
              this.$_ACTIONS_showSnackbarMessage({
                message:
                  this.internacao.codigo == 3
                    ? "Alta hospitalar salva com sucesso"
                    : "Alta médica salva com sucesso",
                color: "sucess",
              });
              this.dialogFormAltaInternacao = false;
              this.disableBtn = false;
              this.listarInternados(this.unidadeSelected);
            } else {
              this.$_ACTIONS_showSnackbarMessage({
                message:
                  body.message || this.$global.messages.internalServerError,
                color: "error",
              });
              this.disableBtn = false;
            }
          },
          onError: (message) => {
            this.$_ACTIONS_showSnackbarMessage({
              message: message || this.$global.messages.internalServerError,
              color: "error",
            });
            this.disableBtn = false;
          },
          onEnd: () => {},
        },
        this.internacao.codigo == 3 ? 3 : this.tipoSaida == "obito" ? 2 : 1,
        this.petId,
        this.clienteId,
        this.unidadeSelected,
        this.form_resp
      );
    },
    iniciarAltaHospitalar(internacao) {
      this.petId = internacao.id_pet;
      this.clienteId = internacao.cliente_id;
      this.internacao = internacao;
      this.prepareFormAltaHospitalar();
    },
    async prepareFormAltaHospitalar() {
      this.loadingInternados = true;

      this.formId = await this.services.internacaoService
        .getIdFormularioAltaMedica(3)
        .then((resp) => {
          if (resp.status == 200) {
            return resp.json();
          }
        })
        .then((resp) => resp.data[0].id)
        .catch(() => {
          this.disableBtn = false;
          this.loadingInternados = false;
          this.$_ACTIONS_showSnackbarMessage({
            message: "Nenhum formulário encontrado para Alta Hospitalar",
            color: "error",
          });
          return "";
        });

      if (this.formId) {
        this.loadingForm(this.formId);
      }
    },
    async atender(item) {
      console.log(item);
      this.internacao = item;

      if (this.perfilUsuario == 3) {
        this.dialogAtender = true;
      } else if (this.perfilUsuario == 2) {
        await this.listarProcedimentos();
        this.dialogAtenderMedico = true;
      }
    },
    async listarProcedimentos() {
      this.procedimentoLoading = true;
      const requisicoesPet = await this.services.requisicaoExameService.getRequisicoesAbertasAtendimentoMedico(
        this.internacao.id_pet,
        this.unidadeSelected
      );

      if (requisicoesPet.data) {
        this.procedimentos = requisicoesPet.data;
      }

      this.procedimentoLoading = false;
    },
    async iniciarAtendimento() {
      if (!this.procedimento?.procedimento_id) {
        this.$_ACTIONS_showSnackbarMessage({
          message: "Escolha um procedimento.",
          color: "error",
        });
        return;
      }

      const body = {
        petId: this.internacao.id_pet,
        unidadeId: this.unidadeSelected,
        medicoId: ~~JSON.parse(sessionStorage.getItem("vuex")).usuario.dados
          .medicoId,
        procedimentoId: this.procedimento.procedimento_id,
        tipoProcedimentoId: this.procedimento.tipo_procedimento_id,
        requisicaoId: this.procedimento.id,
      };
      console.log(body);
      await this.services.agendamentoService.agendarConsultaPrimeiroSlotLivre(
        body
      );
      await this.$router.replace({ name: "dashboard" });
    },
    async listProntuarios(item) {
      await this.services.petService
        .getConsultasbyUnidade(item.id_pet, this.unidadeSelected)
        .then(async (response) => {
          const data = await response.json();
          if (response.status !== 200) {
            this.$_ACTIONS_showSnackbarMessage({
              message: data.message,
              color: response.status === 200 ? "sucess" : "error",
            });
          }
          this.pacienteInfo = {
            nome: item.nome_cliente,
            nome_pet: item.nome_pet,
            id_pet: item.id_pet,
            id_cliente: item.cliente_id,
          };
          this.prontuarios = data.data.consultas;
          this.dialogProntuario = true;
        });
    },
  },
};
</script>

<style></style>
