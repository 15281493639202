<template>
  <v-card>
    <v-card-title class="headline" style="word-break: normal"
      >Tem certeza de que deseja excluir esse horário?
    </v-card-title>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="#1daf80" text @click="$emit('close')">Cancelar</v-btn>
      <v-btn
        :loading="loading"
        class="btn-danger"
        text
        style="color: #fff !important"
        @click="deleteItemConfirm"
        >Deletar</v-btn
      >
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script>
import AgendaService from "@/services/agenda_service.js";
import VuexSnackbarMixin from "@/mixins/vuex_snackbar_mixin.js";
export default {
  mixins: [VuexSnackbarMixin],
  props: {
    horario: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    loading: false,
  }),
  beforeMount() {
    this.TIPO_PROCEDIMENTO_MIXIN_atualizarLista();
  },
  methods: {
    deleteItemConfirm() {
      const agendaService = new AgendaService();
      this.loading = true;
      agendaService.excluirAgenda(
        {
          onSucess: (status) => (body) => {
            this.$_ACTIONS_showSnackbarMessage({
              message:
                body.message || this.$global.messages.internalServerError,
              color: status === 200 ? "sucess" : "error",
            });
            if (status === 200) {
              this.$emit("response");
            }
          },
          onError: () => {
            this.$_ACTIONS_showSnackbarMessage({
              message: this.$global.messages.internalServerError,
              color: "error",
            });
          },
          onEnd: () => {
            this.loading = false;
          },
        },
        this.horario.id
      );
    },
  },
};
</script>
