<template>
  <div>
    <div class="row mt-8 justify-center">
      <div class="col-10">
        <v-card>
          <div class="headerStyle py-2 px-5 mb-4">
            <p class="text-center fontStyle">Pet</p>
          </div>
          <div class="d-flex justify-content-between px-5 pb-3">
            <div><strong>Nome:</strong> {{ pet.nome }}</div>
            <div><strong>Nascimento:</strong> {{ pet.datadenascimento }}</div>
            <div><strong>Cadastro:</strong> {{ pet.datadecadastro }}</div>
          </div>
        </v-card>
      </div>
    </div>
    <div class="row mt-8 justify-center">
      <div class="col-10">
        <v-card>
          <div class="headerStyle py-2 px-5 mb-4">
            <p class="text-center fontStyle">Anamnese</p>
          </div>
          <div class="d-flex justify-content-between px-5 pb-3">
            <div><strong>Data:</strong> {{ anamnese.data }}</div>
            <div><strong>Comanda Id:</strong> {{ anamnese.comanda_id }}</div>
            <div>
              <strong>Responsavel Id:</strong> {{ anamnese.responsavel_id }}
            </div>
            <div>
              <strong>Internação Id:</strong>
              {{ anamnese.id_anamnese_internacao }}
            </div>
          </div>
        </v-card>
      </div>
    </div>
    <div class="row mt-8 justify-center">
      <div class="col-10">
        <h3 class="text-center">Anamnese Internação</h3>
        <div class="styleDetalhes">
          <span><strong>Id: </strong>{{ anamneseInternacao.id }}</span>
          <span
            ><strong>Anamnese: </strong>{{ anamneseInternacao.anamnese }}</span
          >
          <span><strong>Data: </strong>{{ anamneseInternacao.data }}</span>
          <span
            ><strong>Debito Urinario: </strong
            >{{ anamneseInternacao.debitourinario }}</span
          >
          <span><strong>Dieta: </strong>{{ anamneseInternacao.dieta }}</span>
          <span><strong>Dor: </strong>{{ anamneseInternacao.dor }}</span>
          <span><strong>Emese: </strong>{{ anamneseInternacao.emese }}</span>
          <span
            ><strong>Exames Requisitados: </strong
            >{{ anamneseInternacao.examesrequisitados }}</span
          >
          <span><strong>Fezes: </strong>{{ anamneseInternacao.fezes }}</span>
          <span
            ><strong>Frequencia Cardiaca: </strong
            >{{ anamneseInternacao.frequenciacardiaca }}</span
          >
          <span
            ><strong>Frequencia Respiratoria: </strong
            >{{ anamneseInternacao.frequenciarespiratoria }}</span
          >
          <span
            ><strong>Glasglow: </strong>{{ anamneseInternacao.glasglow }}</span
          >
          <span
            ><strong>Glicemia: </strong>{{ anamneseInternacao.glicemia }}</span
          >
          <span
            ><strong>Mucosas: </strong>{{ anamneseInternacao.mucosas }}</span
          >
          <span
            ><strong>Oxigenioterapia: </strong
            >{{ anamneseInternacao.oxigenioterapia }}</span
          >
          <span><strong>Paspam: </strong>{{ anamneseInternacao.paspam }}</span>
          <span
            ><strong>Percentual Desidratacao: </strong
            >{{ anamneseInternacao.percentualdesidratacao }}</span
          >
          <span
            ><strong>Provade Carga: </strong
            >{{ anamneseInternacao.provadecarga }}</span
          >
          <span
            ><strong>Pulso Arterial: </strong
            >{{ anamneseInternacao.pulsoarterial }}</span
          >
          <span
            ><strong>Reposicao: </strong
            >{{ anamneseInternacao.reposicao }}</span
          >
          <span
            ><strong>Sedacao: </strong>{{ anamneseInternacao.sedacao }}</span
          >
          <span
            ><strong>Temperatura: </strong
            >{{ anamneseInternacao.temperatura }}</span
          >
          <span
            ><strong>Tratamento: </strong
            >{{ anamneseInternacao.tratamento }}</span
          >
          <span><strong>Urina: </strong>{{ anamneseInternacao.urina }}</span>
          <span
            ><strong>Profissional Id: </strong
            >{{ anamneseInternacao.profissional_id }}</span
          >
          <span
            ><strong>Responsavel Id: </strong
            >{{ anamneseInternacao.responsavel_id }}</span
          >
          <span
            ><strong>Anamnese Id: </strong
            >{{ anamneseInternacao.id_anamnese }}</span
          >
          <span
            ><strong>Data Exclusao: </strong
            >{{ anamneseInternacao.data_exclusao }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DadosCadastraisMixin from "@/mixins/dados_cadastrais_mixin.js";
import LegacyVetusService from "../../services/legacy_vetus";

export default {
  mixins: [DadosCadastraisMixin],
  props: {
    pet: Object,
    anamnese: Object,
    anamneseInternacao: Object,
  },
  data() {
    return {
      services: {
        legacyVetusService: LegacyVetusService.build(),
      },
      loading: true,
      headers: [
        {
          text: "Nome",
          align: "start",
          sortable: true,
          value: "descricao",
        },
        { text: "Ações", align: "end", value: "actions", sortable: false },
      ],
      // Listas
      // anamneseInternacao: {
      //   id: 0,
      //   anamnese: "",
      //   data: "",
      //   debitourinario: 0,
      //   dieta: "",
      //   dor: "",
      //   emese: 0,
      //   examesrequisitados: "",
      //   fezes: 0,
      //   frequenciacardiaca: 0,
      //   frequenciarespiratoria: 0,
      //   glasglow: 0,
      //   glicemia: 0,
      //   mucosas: 0,
      //   oxigenioterapia: 0,
      //   paspam: 0,
      //   percentualdesidratacao: 0,
      //   provadecarga: 0,
      //   pulsoarterial: 0,
      //   reposicao: 0,
      //   sedacao: "",
      //   temperatura: 0,
      //   tratamento: "",
      //   urina: 0,
      //   profissional_id: 0,
      //   responsavel_id: 0,
      //   id_anamnese: 0,
      //   data_exclusao: false,
      // },
    };
  },
  mounted() {},
  computed: {},
  methods: {
    async getAnamneseInternacao() {
      this.loading = true;
      await this.services.legacyVetusService
        .getAnamneseInternacaobyID(this.anamnese.id_anamnese_internacao)
        .then(async (response) => {
          const data = await response.json();
          data[0].data_exclusao = this.convertDate(data[0].data_exclusao);
          data[0].data = this.convertDate(data[0].data);
          this.anamneseInternacao = data[0];
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    convertDate(date) {
      if (date) {
        const data = new Date(date);
        return (
          data
            .getDay()
            .toString()
            .padStart(2, "0") +
          "/" +
          data
            .getMonth()
            .toString()
            .padStart(2, "0") +
          "/" +
          data.getFullYear()
        );
      } else {
        return "--/--/----";
      }
    },
  },
};
</script>

<style scoped>
.form-group label {
  text-align: left !important;
  display: block;
}

.form-group .label-genero {
  margin-right: 1rem;
}

.headerStyle {
  background-color: #e0e0e0;
}

.fontStyle {
  font-weight: bolder;
  margin-bottom: 0px;
  font-size: 1.1rem;
}
.styleDetalhes {
  display: flex;
  flex-direction: column;
  gap: 2;
}
.styleDetalhes span {
  text-align: left;
  margin-bottom: 19px;
  box-shadow: 0px 3px 4px -1px rgba(0, 0, 0, 0.1);
}
</style>
