<template>
  <div @click="$emit(active? 'unselect': 'select')" style="cursor: pointer">
    <card-fila
        :nome-paciente="nomePaciente"
        :paciente_id="paciente_id"
        :hora-inicial="horaInicial"
        :hora-final="horaFinal"
        :procedimento="procedimento"
        :tipo-procedimento="tipoProcedimento"
        :cor="!isBefore(horaFinal)?cor:'rgb(154, 154, 154)'"
        @mouseover="cor = corHover"
        @mouseout="cor = corHoverOut"
    />
  </div>
</template>

<script>
import CardFila from "./card_fila";
export default {
  components: { CardFila },
  data: () => ({
    cor: 'rgb(62, 104, 42)',
    corHover: 'rgb(62, 104, 42)',
    corHoverOut: 'rgb(62, 104, 42)',
  }),
  props: {
    nomePaciente: {type: String, required: true},
    paciente_id: {type: String, required: true},
    horaInicial: {type: String, required: true},
    horaFinal: {type: String, required: true},
    procedimento: {type: String, required: true},
    tipoProcedimento: {type: String, required: true},
    active: {type: Boolean, required: true},
  },
  methods: {

    isBefore(hour) {
      const now = new Date()
      const consulta_hour = new Date()
      const consulta_hour_parts = hour.split(':')
      consulta_hour.setHours(consulta_hour_parts[0])
      consulta_hour.setMinutes(consulta_hour_parts[1])

      return consulta_hour < now
    }

  }
}
</script>