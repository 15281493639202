<template>
  <div class="p-5 d-flex flex-colunm col-12">
    <v-form ref="form" v-model="valid" lazy-validation class="col-12">
      <div class="d-flex flex-column col-12">
        <label for=""><strong>Título</strong></label>
        <v-text-field
          v-model="form_inputs.titulo"
          placeholder="Ex: Consulta Básica"
          required
          class="col-6 m-auto"
        ></v-text-field>
      </div>
      <div class="d-flex flex-column col-12">
        <label for=""><strong>Observação</strong></label>
        <v-text-field
          v-model="form_inputs.descricao"
          placeholder="Ex: Formulário para uma primeira consulta"
          required
          class="col-6 m-auto"
        ></v-text-field>
      </div>
      <div class="d-flex justify-content-center col-12">
        <v-switch
          v-model="atendimentoAssistente"
          label="Formulário de atendimento do assistente"
          inset
        ></v-switch>
      </div>

      <!-- PERGUNTAS -->
      <div
        class="col-12 d-flex flex-column"
        v-for="(input, index) in form_inputs.perguntas"
        :key="`pergunta${index}`"
      >
        <label for=""
          ><strong>Pergunta {{ index + 1 }}</strong></label
        >
        <div class="col-12 d-flex justify-center">
          <form-field-fill :pergunta="input" v-if="input" class="col-10" />
          <div class="col-2">
            <v-btn class="red" color="error" @click="removerPergunta(index)">
              <i class="fas fa-times"></i>
            </v-btn>
          </div>
        </div>
      </div>
      <div>
        <formulario-build @addPergunta="addPergunta($event)" />
      </div>
      <div>
        <label for=""><strong>Procedimentos</strong></label>
        <v-autocomplete
          v-model="procedimentos_form"
          :items="procedimentos"
          item-text="descricao"
          item-value="id"
          chips
          label="procedimentos"
          multiple
        ></v-autocomplete>
      </div>
      <v-btn
        color="success"
        :loading="loadingNovoFormulario"
        class="ml-3"
        @click="salvarFormulario()"
      >
        Salvar formulário
      </v-btn>
    </v-form>
  </div>
</template>
<script>
import FormularioBuild from "@/components/formularios/formulario_campo_builder.vue";
import FormFieldFill from "@/components/formularios/formulario_campo_fill.vue";
import FormularioService from "@/services/formulario_service.js";
import ProcedimentoService from "@/services/procedimento_service.js";
import Mixin from "@/mixins/vuex_snackbar_mixin.js";

export default {
  mixins: [Mixin],
  components: {
    FormularioBuild,
    FormFieldFill,
  },
  beforeMount() {
    this.lista_procedimentos();
    this.perguntinha = this.form_inputs.perguntas;
    console.log(this.perguntinha);
  },
  data: () => ({
    procedimentos: [],
    procedimentos_form: [],
    loading_procedimentos: false,
    loadingNovoFormulario: false,
    valid: true,
    form_inputs: {
      titulo: "",
      descricao: "",
      id_medico: 1,
      perguntas: [],
    },
    perguntinha: "",
    atendimentoAssistente: false,
  }),
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    removerPergunta(index) {
      this.form_inputs.perguntas.splice(index, 1);
    },
    addPergunta(e) {
      this.form_inputs.perguntas.push(e);
    },
    salvarFormulario() {
      if (this.form_inputs.titulo === "") {
        this.$_ACTIONS_showSnackbarMessage({
          message: "Campo título é obrigatório",
          color: "error",
        });
        return;
      }
      if (!this.atendimentoAssistente & (this.procedimentos_form.length < 1)) {
        this.$_ACTIONS_showSnackbarMessage({
          message: "Procedimento é um campo obrigatório",
          color: "error",
        });
        return;
      }
      if (this.form_inputs.descricao === "") {
        this.$_ACTIONS_showSnackbarMessage({
          message: "Campo descrição é obrigatório",
          color: "error",
        });
        return;
      }
      if (this.form_inputs.perguntas.length < 1) {
        this.$_ACTIONS_showSnackbarMessage({
          message: "O formulário deve conter no mínimo uma pergunta ",
          color: "error",
        });
        return;
      }
      const formulario = this.prepararFormulario(this.form_inputs);
      const formularioService = new FormularioService();
      this.loadingNovoFormulario = true;
      formularioService.register(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.$_ACTIONS_showSnackbarMessage({
                message: body.message || "Formulário cadastrado com sucesso",
                color: "sucess",
              });
              this.$emit("close", true);
            } else {
              this.$_ACTIONS_showSnackbarMessage({
                message: this.$global.messages.internalServerError,
                color: "error",
              });
            }
          },
          onError: (error) => {
            this.$emit("submit", { error });
          },
          onEnd: () => {
            this.loadingNovoFormulario = false;
          },
        },
        formulario
      );
    },
    prepararFormulario(form) {
      let perguntas = [];
      form.perguntas.map((pergunta, i) => {
        let opcoes_toarray = [];
        if (pergunta.opcoes) {
          for (let index = 0; index < pergunta.opcoes.length; index++) {
            opcoes_toarray.push(pergunta.opcoes[index].descricao);
          }
          perguntas[i] = {
            texto_pergunta: pergunta.texto_pergunta,
            id_tipo_pergunta: pergunta.id_tipo_pergunta,
            obrigatorio: pergunta.obrigatorio,
            opcoes: opcoes_toarray,
            ordem: pergunta.ordem,
            ativo: pergunta.ativa,
          };
        } else {
          delete form.perguntas[i].opcoes;
          perguntas[i] = form.perguntas[i];
        }
      });
      const formulario = {
        titulo: form.titulo != "" ? form.titulo : "titulo",
        descricao: form.descricao != "" ? form.descricao : "descricao",
        id_medico: 1,
        procedimentos: this.procedimentos_form,
        atendimento: this.atendimentoAssistente,
        perguntas: perguntas,
      };
      return formulario;
    },
    lista_procedimentos() {
      this.loading_procedimentos = true;
      const procedimentoService = new ProcedimentoService();
      procedimentoService.getList({
        onSucess: (status) => (body) => {
          if (status === 200) {
            this.procedimentos = body.data.procedimentos;
          } else {
            this.procedimentos = [];
          }
        },
        onError: (error) => {
          console.error(error);
          this.procedimentos = [];
        },
        onEnd: () => {
          this.loading_procedimentos = false;
        },
      });
    },
  },
};
</script>
