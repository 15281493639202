import { mapActions, mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      $_GETTERS_usuario: "getUsuario",
      $_GETTERS_petAtendido: "getpetAtendido",
      $_GETTERS_petEncaminhar: "getpetEncaminhar",
      $_GETTERS_token: "getToken",
      $_GETTER_snackbar: "getSnackbar",
      $_GETTER_perfil: "getPerfil",
      $_GETTER_Unidade: "getUnidade",
    }),
  },
  methods: {
    ...mapActions({
      $_ACTIONS_setUsuario: "setUsuario",
      $_ACTIONS_setPetAtendido: "setpetAtendido",
      $_ACTIONS_setPetEncaminhar: "setPetEncaminhar",
      $_ACTIONS_setToken: "setToken",
      $_ACTIONS_perfil: "setPerfil",
      $_ACTIONS_setUnidade: "setUnidade",
      $_ACTIONS_showSnackbarMessage: "showSnackbarMessage",
    }),
  },
};
