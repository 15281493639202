<template>
  <div>
    <div class="p-lg-3">
      <div class="row" justify-content-between>
        <h3 class="mt-0 mb-2 font-weight-bold text-left">Pets por unidade</h3>
      </div>
    </div>
    <v-row align="center" class="my-3">
      <v-col class="d-flex col-3">
        <v-select
          outlined
          :items="unidades"
          label="Unidade"
          item-value="id"
          v-model="unidadeSelected"
        ></v-select>
      </v-col>
      <v-col class="d-flex">
        <v-speed-dial
          v-model="fab"
          :direction="direction"
          :open-on-hover="hover"
          :transition="transition"
        >
          <template v-slot:activator>
            <v-btn
              class="mb-7 btnWid"
              style="width: 9rem; height: 3.2rem"
              dark
              color="primary"
            >
              <v-icon v-if="fab"> mdi-close </v-icon>
              <span v-else> Exportar </span>
            </v-btn>
          </template>

          <div class="mt-n5">
            <v-btn dark color="green" class="btnWid" @click="pdfRelatorio">
              PDF
            </v-btn>
            <v-btn dark color="indigo" class="btnWid" @click="excelRelatorio">
              EXCEL
            </v-btn>
          </div>
        </v-speed-dial>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Mixin from "@/mixins/vuex_mixin.js";
import UnidadeService from "../services/unidade_service";
import PetService from "../services/pets_service";

export default {
  mixins: [Mixin],
  data() {
    return {
      menu: false,
      unidades: [],
      unidadeSelected: "",
      loadingPdf: false,
      direction: "bottom",
      fab: false,
      hover: false,
      services: {
        unidadeService: UnidadeService.build(),
        petService: PetService.build(),
      },
      options: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 15,
        ordenacao: false,
        desc: false,
      },
    };
  },
  beforeMount() {
    this.setUnidades();
  },
  computed: {},
  methods: {
    setUnidades() {
      this.services.unidadeService
        .getUnidadeByUsuarioLogado()
        .then((response) => {
          return response.json();
        })
        .then((response) => {
          this.unidades = response.data.map((unidade) => {
            return { text: unidade.unidade, id: unidade.id_unidade };
          });
        });
    },
    pdfRelatorio() {
      this.loadingPdf = true;
      const unidade = this.unidadeSelected ? this.unidadeSelected : "";

      if (unidade.length < 1) {
        this.$_ACTIONS_showSnackbarMessage({
          message: "Preencha os campos corretamente",
          color: "error",
        });
        this.loadingPdf = false;
        return;
      }

      this.services.petService
        .pdfPetsUnidadeRelatorio(unidade)
        .then((resp) => {
          console.log(resp);
          if (resp.status) {
            this.$_ACTIONS_showSnackbarMessage({
              message: "Falha ao carregar",
              color: "error",
            });
            return;
          }
          this.$_ACTIONS_showSnackbarMessage({
            message: "Dados encontrados com sucesso! Gerando PDF...",
            color: "sucess",
          });
          let link = document.createElement("a");
          // link.target = "_blank";
          link.download = `relatorio-pet-unidade.pdf`;
          let url = window.URL.createObjectURL(resp);
          link.href = url;
          link.click();
        })
        .catch(() => {
          this.$_ACTIONS_showSnackbarMessage({
            message: "Falha ao carregar",
            color: "error",
          });
        })
        .finally(() => {
          this.loadingPdf = false;
        });
    },
    excelRelatorio() {
      this.loadingPdf = true;
      const unidade = this.unidadeSelected ? this.unidadeSelected : "";

      if (unidade.length < 1) {
        this.$_ACTIONS_showSnackbarMessage({
          message: "Preencha os campos corretamente",
          color: "error",
        });
        this.loadingPdf = false;
        return;
      }

      this.services.petService
        .excelPetsUnidadeRelatorio(unidade)
        .then((resp) => {
          console.log(resp);
          if (resp.status) {
            this.$_ACTIONS_showSnackbarMessage({
              message: "Falha ao carregar",
              color: "error",
            });
            return;
          }
          this.$_ACTIONS_showSnackbarMessage({
            message: "Dados encontrados com sucesso! Gerando PDF...",
            color: "sucess",
          });
          let link = document.createElement("a");
          // link.target = "_blank";
          link.download = `relatorio-pet-unidade.xlsx`;
          let url = window.URL.createObjectURL(resp);
          link.href = url;
          link.click();
        })
        .catch(() => {
          this.$_ACTIONS_showSnackbarMessage({
            message: "Falha ao carregar",
            color: "error",
          });
        })
        .finally(() => {
          this.loadingPdf = false;
        });
    },
  },
};
</script>

<style>
.btnWid {
  width: 9rem;
}
</style>
