import HTTPService from "./http_service";
// import { global } from "@/config/constants";

export default class EstornoService extends HTTPService {
  resource = "extorno";

  static build() {
    return new EstornoService();
  }

  async setEstorno(responseFunctions, estorno) {
    // const headers = new Headers();
    // const token = JSON.parse(sessionStorage.getItem("vuex")).token;
    // headers.append("Authorization", "Bearer " + token);
    // headers.append("Content-Type", "application/json");

    // const requestOptions = {
    //   method: "POST",
    //   body: estorno,
    //   headers: headers,
    // };

    // return await fetch(
    //   `${global.api.baseURL}/${this.resource}`,
    //   requestOptions
    // ).then(async (response) => {
    //   return response;
    // });

    this.jsonRequest(responseFunctions, "POST", this.resource, estorno);
    // return await this.post(this.resource, estorno).then((resp) => resp);
  }

  async getEstornoByComandaId(comandaId) {
    const resp = await this.get(`${this.resource}/${comandaId}`);
    return resp.json();
  }

  async getReciboEstorno(estornoId) {
    const resp = await this.get(`recibo/${this.resource}/${estornoId}`);
    return resp.json();
  }
}
