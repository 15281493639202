<template>
  <v-card>
    <v-toolbar style="background-color: #1daf80; color: #fff">
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>
        <span class="headline">Agendar Pet</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items> </v-toolbar-items>
    </v-toolbar>
    <div class="p-5">
      <calendario :pet="pet" />
    </div>
  </v-card>
</template>

<script>
import Mixin from "@/mixins/vuex_mixin.js";
import Calendario from "@/components/pets/calendario";

export default {
  mixins: [Mixin],
  components: {
    Calendario,
  },
  data: () => ({}),
  props: {
    pet: {
      type: Object,
      required: true,
    },
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {},
};
</script>
