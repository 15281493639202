<template>
  <div class="d-flex align-items-center col-12">
    <v-textarea
      class="col-9"
      rows="3"
      :disabled="disabled"
      v-model="pergunta.texto_pergunta"
      :required="pergunta.obrigatorio"
      :messages="pergunta.obrigatorio ? 'obrigatória' : 'não obrigatória'"
    ></v-textarea>

    <div class="col-2 mr-4">
      <v-switch
        v-model="mudar_obrigatoria"
        :disabled="disabled"
        label="Obrigatória"
      ></v-switch>
      <v-switch
        v-model="ativa"
        :disabled="disabled"
        :label="ativa ? 'Ativa' : 'Inativa'"
      ></v-switch>
    </div>

    <v-text-field
      type="number"
      v-model="ordem"
      :disabled="disabled"
      label="Ordem"
      class="col-1 ml-2"
    ></v-text-field>
  </div>
</template>
<script>
import Mixin from "@/mixins/vuex_snackbar_mixin.js";

export default {
  mixins: [Mixin],
  props: {
    pergunta: Object,
    disabled: Boolean,
  },
  beforeMount() {
    this.mudar_obrigatoria = this.pergunta.obrigatorio;
    this.ordem = this.pergunta.ordem;
    this.ativa = this.pergunta.ativo == 1 ? true : false;
  },
  data: () => ({
    mudar_obrigatoria: null,
    ordem: 0,
    ativa: null,
  }),
  watch: {
    mudar_obrigatoria(value) {
      this.pergunta.obrigatorio = value;
    },
    ordem(value) {
      this.pergunta.ordem = value;
    },
    ativa(value) {
      this.pergunta.ativo = value ? 1 : 0;
    },
  },
};
</script>
<style scoped>
.v-progress-circular {
  margin: 1rem;
}
</style>
