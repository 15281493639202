<template>
  <div>
    <!-- filtro -->
    <div class="my-2">
      <v-row justify="center">
        <v-expansion-panels focusable>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h5>Filtrar</h5>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <filtroAgendamento
                @filtro="filtrarCalendario($event)"
                @limparFiltro="limparFiltro"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
    </div>
    <v-sheet height="64">
      <v-toolbar flat>
        <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday"
          >Hoje</v-btn
        >
        <v-btn fab text small color="grey darken-2" @click="prev">
          <v-icon small> mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn fab text small color="grey darken-2" @click="next">
          <v-icon small> mdi-chevron-right </v-icon>
        </v-btn>
        <v-toolbar-title v-if="$refs.calendar">
          {{ $refs.calendar.title }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-menu bottom right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
              <span>{{ calendario.labels[calendario.typeView] }}</span>
              <v-icon right>mdi-menu-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="calendario.typeView = 'day'">
              <v-list-item-title>{{ calendario.labels.day }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="calendario.typeView = 'week'">
              <v-list-item-title>{{
                calendario.labels.week
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="calendario.typeView = 'month'">
              <v-list-item-title>{{
                calendario.labels.month
              }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>
    </v-sheet>
    <v-sheet height="600">
      <v-progress-linear
        v-if="loading"
        indeterminate
        color="primary"
      ></v-progress-linear>
      <v-calendar
        v-show="!loading"
        ref="calendar"
        v-model="calendario.focus"
        color="primary"
        :events="horarios"
        :event-color="getEventColor"
        :type="calendario.typeView"
        @click:event="showEvent"
        @click:more="viewDay"
        @click:date="viewDay"
        v-on:moved="chengEvent"
      >
      </v-calendar>
    </v-sheet>

    <!-- MODAL - AGENDA -->
    <v-dialog v-model="dialog" max-width="700px">
      <modal-agendar-paciente
        v-if="modalMarcarAgendamento"
        @close="dialog = false"
        :pet="pet"
        :detalhesAgenda="detalhesAgenda"
        @submit="tratarRespostaAgendar"
      />
      <modal-detalhes-agenda
        v-if="modalVerDetalhesAgendamento"
        :detalhes="detalhesAgenda"
        @close="dialog = false"
        @desmarcar="tratarRespostaDesmarcar"
      />
      <modal-horario-desabilitado
        v-if="modalHabilitarHorario"
        @close="dialog = false"
        :HorarioVencido="vencido"
      />
    </v-dialog>
  </div>
</template>

<script>
import AgendaService from "@/services/agenda_service.js";
import ModalHorarioDesabilitado from "./modal_horario_desabilitado";
import ModalAgendarPaciente from "./modal_agendar_paciente";
import ModalDetalhesAgenda from "./modal_detalhes_agenda";
import FiltroAgendamento from "@/components/pets/filtro_agendamento.vue";
import Mixin from "@/mixins/vuex_mixin.js";
import DadosCadastraisMixin from "@/mixins/dados_cadastrais_mixin.js";
import MedicoService from "../../services/medico_service";

export default {
  mixins: [Mixin, DadosCadastraisMixin],
  components: {
    ModalHorarioDesabilitado,
    ModalAgendarPaciente,
    ModalDetalhesAgenda,
    FiltroAgendamento,
  },
  props: {
    pet: {
      type: Object,
      require: true,
    },
  },
  data: () => ({
    focusCalendario: "",
    dialog: false,
    loading: false,
    corHorarioCancelado: "grey",
    corHorarioOcupado: "primary",
    corHorarioLivre: "#1daf80",
    typeViewCalendario: "month",
    labelsCalendario: {
      month: "Mês",
      week: "Semana",
      day: "Dia",
    },
    calendario: {
      labels: {
        month: "Mês",
        week: "Semana",
        day: "Dia",
      },
      focus: "",
      typeView: "month",
    },
    modalMarcarAgendamento: false,
    modalVerDetalhesAgendamento: false,
    modalHabilitarHorario: false,
    horarios: [],
    detalhesAgenda: {},
    vencido: true,
    params: {},
    medicos: [],
    loadingListarMedicos: false,
    medicoService: MedicoService.build(),
  }),
  async mounted() {
    console.log(this.pet);
    this.loadingListarMedicos = true;
    this.medicos = await this.medicoService.getMedicoListByUnidadeId(
      this.$_GETTER_Unidade.id
    );
    this.loadingListarMedicos = false;
    let data = new Date();
    this.pesquisa_periodo(data);
    this.atualizarCalendario();
    this.$refs.calendar.checkChange();
  },
  methods: {
    pesquisa_periodo(data) {
      let ano = data.getFullYear();
      let mes = data.getMonth();
      let di = new Date(ano, mes, -8);
      let df = new Date(ano, mes + 1, +8);
      this.params = {
        data_inicio: di.toISOString().substr(0, 10),
        data_fim: df.toISOString().substr(0, 10),
      };
    },
    viewDay({ date }) {
      this.calendario.focus = date;
      this.calendario.typeView = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.calendario.focus = "";
    },
    chengEvent(e) {
      this.pesquisa_periodo(
        new Date(e.year, e.month - 1 === 0 ? 12 : e.month - 1, 1)
      );
      this.atualizarCalendario();
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent(context) {
      const eventParsed = context.eventParsed;
      const dataHorarioSelecionado =
        eventParsed.start.date + " " + eventParsed.start.time;
      const horarioObj = this.horarios.find((horario) => {
        return horario.start === dataHorarioSelecionado;
      });
      const vencido = horarioObj.vencido;
      const cancelado = horarioObj.cancelado;
      const marcado = horarioObj.marcado;
      this.dialog = true;
      this.detalhesAgenda = null;
      this.modalHabilitarHorario = false;
      this.modalVerDetalhesAgendamento = false;
      this.modalMarcarAgendamento = false;
      let modal = marcado
        ? cancelado
          ? "modalMarcarAgendamento"
          : "modalVerDetalhesAgendamento"
        : vencido
        ? "modalHabilitarHorario"
        : "modalMarcarAgendamento";
      this[modal] = true;

      if (marcado) {
        this.detalhesAgenda = {
          idAgenda: horarioObj.idAgenda,
          procedimento: horarioObj.procedimento,
          idPaciente: horarioObj.idPaciente,
          tipoProcedimento: horarioObj.name,
          idTipoProcedimento: horarioObj.idTipoProcedimento,
          idAgendamento: horarioObj.idAgendamento,
          horarioInicial: horarioObj.horarioInicial,
          horarioFinal: horarioObj.horarioFinal,
          data: horarioObj.data,
          vencido: horarioObj.vencido,
          medico: horarioObj.medico,
          petNome: horarioObj.petNome,
        };
      } else if (!vencido) {
        this.detalhesAgenda = horarioObj;
      }
    },
    tratarRespostaAgendar(event) {
      if (event.error) {
        this.$_ACTIONS_showSnackbarMessage({
          message:
            "Um erro aconteceu durante o processamento da sua solicitação.",
          color: "error",
        });
      } else {
        const requestWasSucessfull = event.status === 200;
        this.$_ACTIONS_showSnackbarMessage({
          message: event.message,
          color: requestWasSucessfull ? "sucess" : "error",
        });
        this.dialog = !requestWasSucessfull;
        if (requestWasSucessfull) {
          const { request } = event;
          const { procedimento } = event;
          for (let i = 0, len = this.horarios.length; i < len; i++) {
            if (this.horarios[i].idAgenda === this.detalhesAgenda.idAgenda) {
              this.horarios[i].marcado = true;
              this.horarios[i].cancelado = false;
              this.horarios[i].idPaciente = request.id_paciente;
              this.horarios[i].idCliente = request.id_cliente;
              this.horarios[i].idAgendamento = request.id_agendamento;
              this.horarios[i].procedimento = procedimento;
              this.horarios[i].color = this.corHorarioOcupado;
              break;
            }
          }
        }
      }
    },
    tratarRespostaDesmarcar(event) {
      if (event.error) {
        this.$_ACTIONS_showSnackbarMessage({
          message:
            "Um erro aconteceu durante o processamento da sua solicitação.",
          color: "error",
        });
      } else {
        const requestWasSucessfull = event.status === 200;
        this.$_ACTIONS_showSnackbarMessage({
          message: event.message,
          color: requestWasSucessfull ? "sucess" : "error",
        });
        this.dialog = !requestWasSucessfull;
        if (requestWasSucessfull) {
          for (let i = 0, len = this.horarios.length; i < len; i++) {
            if (this.horarios[i].idAgenda === this.detalhesAgenda.idAgenda) {
              this.horarios[i].marcado = false;
              this.horarios[i].idPaciente = 0;
              this.horarios[i].idAgendamento = 0;
              this.horarios[i].procedimento = "";
              this.horarios[i].color = this.corHorarioLivre;
              this.horarios[i].cancelado = true;
              break;
            }
          }
        }
      }
    },
    atualizarCalendario() {
      console.log("atualizarCalendario");
      const agendaService = new AgendaService();
      const idMedico = this.params.medico_id || null;
      this.loading = true;
      this.horarios = [];
      agendaService.getAgendaList(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.horarios = [];

              body.data.agendas.forEach((horario) => {
                const horarioAgenda = {
                  // name: horario.tipo_procedimento,
                  idAgenda: horario.id,
                  procedimento: horario.procedimento,
                  start: horario.data + " " + horario.hora_inicio,
                  data: horario.data,
                  horarioInicial: horario.hora_inicio,
                  horarioFinal: horario.hora_final,
                  idAgendamento: horario.id_agendamento,
                  idPaciente: horario.id_paciente,
                  idTipoProcedimento: horario.id_tipo_procedimento,
                  end: horario.data + " " + horario.hora_final,
                  color: horario.id_agendamento
                    ? this.definir_cor(true, horario.data, horario.cancelado_em)
                    : this.definir_cor(
                        false,
                        horario.data,
                        horario.cancelado_em
                      ),
                  timed: false,
                  marcado: !!horario.id_agendamento,
                  cancelado: !(
                    horario.cancelado_em === "" ||
                    horario.cancelado_em === "0000-00-00 00:00:00"
                  ),
                  vencido: this.horarioVencido(horario.data),
                  medicoId: ~~horario.id_medico,
                  medico:
                    this.medicos.find(
                      (medico) => medico.medicoId === ~~horario.id_medico
                    ) || {},
                  clienteNome: horario.nome_cliente || "",
                  petNome: horario.nome_pet || "",
                };
                horarioAgenda.name = `${horario.tipo_procedimento} | ${horarioAgenda.medico.nome}`;
                if (horarioAgenda.clienteNome || horarioAgenda.petNome) {
                  horarioAgenda.name += ` | ${horarioAgenda.clienteNome} - ${horarioAgenda.petNome}`;
                }
                this.horarios.push(horarioAgenda);
              });
            } else {
              console.error("error");
            }
          },
          onError: () => {
            this.$_ACTIONS_showSnackbarMessage({
              message: this.$global.messages.internalServerError,
              color: "error",
            });
          },
          onEnd: () => {
            this.loading = false;
          },
        },
        idMedico,
        this.params
      );
    },
    horarioVencido(data) {
      let dataHorario = new Date(data).toISOString().split("T")[0];
      let dataHj = new Date().toISOString().split("T")[0];
      return dataHorario < dataHj;
    },
    definir_cor(ocupado, data, cancelado_em) {
      let dataHorario = new Date(data).toISOString().split("T")[0];
      let dataHj = new Date().toISOString().split("T")[0];
      if (
        ocupado &&
        (cancelado_em === "" || cancelado_em === "0000-00-00 00:00:00")
      ) {
        return this.corHorarioOcupado;
      } else if (dataHorario < dataHj) {
        return this.corHorarioCancelado;
      } else {
        return this.corHorarioLivre;
      }
    },
    filtrarCalendario(event) {
      console.log({ event });
      this.loading = true;
      const procedimento = event[0];
      const paciente = event[1];
      const data = event[2];
      const medicoId = event[3];
      this.params = {};
      if (data || medicoId) {
        if (procedimento) {
          this.params = {
            data_inicio: data,
            data_fim: data,
            id_procedimento: procedimento,
          };
        }
        if (paciente) {
          this.params = {
            data_inicio: data,
            data_fim: data,
            id_paciente: paciente,
          };
        }
        if (procedimento && paciente) {
          this.params = {
            data_inicio: data,
            data_fim: data,
            id_procedimento: procedimento,
            id_paciente: paciente,
          };
        }
        if (medicoId) {
          this.params.medico_id = medicoId;
        }
        console.log({ params: this.params });
        this.atualizarCalendario();
      }
      if (paciente) {
        this.horarios = this.horarios.filter(function(horario) {
          return horario.idPaciente == paciente;
        });
        this.loading = false;
      }
      if (procedimento) {
        this.horarios = this.horarios.filter(function(horario) {
          return horario.procedimento == procedimento;
        });
        this.loading = false;
      }
      if (data) {
        this.calendario.typeView = "day";
        this.calendario.focus = data;
      }
      // this.loading = false;
    },
    limparFiltro() {
      let data = new Date();
      this.pesquisa_periodo(data), this.atualizarCalendario();
      this.calendario.typeView = "month";
    },
  },
};
</script>
