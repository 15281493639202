<template>
  <div>
    <div class="p-lg-3">
      <div class="row justify-content-between">
        <h3 class="mt-0 mb-2 font-weight-bold text-left">
          Lojas
        </h3>
      </div>
    </div>
    <div class="p-lg-3">
      <div class="row justify-content-between">
        <!-- DIALOG DE CADASTRO -->
        <v-dialog
          v-model="dialogCadastro"
          max-width="500px"
          persistent
          fullscreen
          transition="dialog-bottom-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="allowCadastrarBtn"
              color="#1daf80"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
            >
              Nova Loja
            </v-btn>
          </template>
          <v-card>
            <v-toolbar style="background-color: #1daf80; color: #fff">
              <v-btn icon dark @click="dialogCadastro = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>
                <span class="headline">Cadastrar loja</span>
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items> </v-toolbar-items>
            </v-toolbar>
            <nova-loja
              v-if="dialogCadastro"
              @close="dialogCadastro = false"
              @response="getListaLoja"
            />
          </v-card>
        </v-dialog>
      </div>
    </div>

    <!-- <v-data-table
      v-on:update:sort-by="options.ordenacao = false"
      :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
      :search="search"
      :headers="headers"
      :items="lojas"
      :options.sync="options"
      :server-items-length="totalList"
      class="elevation-1"
      :loading="loading"
      :loading-text="'Carregando...'"
    > -->
    <v-data-table
      :headers="headers"
      :items="lojas"
      class="elevation-1"
      :loading="loading"
      v-on:update:sort-by="options.ordenacao = false"
      :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
      :loading-text="'Carregando...'"
    >
      <template v-slot:top v-if="false">
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Pesquisar"
            single-line
            hide-details
          ></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          class="mr-2"
          title="Gerenciar Produtos"
          @click="editLojaProdutos(item.id_loja, item.id_unidade)"
        >
          fas fa-cogs
        </v-icon>
        <v-icon
          small
          class="mr-2"
          :class="item.id_status == 1 ? 'btn-ativo' : 'btn-inativo'"
          :title="item.id_status == 1 ? 'Desativar' : 'Ativar'"
          @click="editStatusItem(item.id_loja, item.id_unidade, item.id_status)"
        >
          fas fa-toggle-on
        </v-icon>
        <!-- <v-icon
          v-if="allowEditarBtn"
          small
          class="mr-2"
          @click="editItem(item.loja_id)"
        >
          fas fa-edit
        </v-icon> -->
      </template>
      <template v-slot:no-data>
        <p class="text-center">Nenhum registro encontrado</p>
        <v-btn color="#1daf80" dark @click="getListaLoja">
          Atualizar
        </v-btn>
      </template>
    </v-data-table>
    <!-- DIALOG DE EDIÇÃO -->
    <v-dialog
      v-model="dialogEdicao"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar style="background-color: #1daf80; color: #fff">
          <v-btn icon dark @click="dialogEdicao = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            <span class="headline">Editar loja</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items> </v-toolbar-items>
        </v-toolbar>
        <edicao-loja
          v-if="dialogEdicao"
          :visualizar="lojaId"
          :key="render"
          @close="dialogEdicao = false"
          @response="getListaLoja"
        />
      </v-card>
    </v-dialog>
    <!-- Dialog Loja Produtos -->
    <v-dialog
      v-model="dialogLojaProd"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar style="background-color: #1daf80; color: #fff">
          <v-btn icon dark @click="dialogLojaProd = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            <span class="headline">Produtos Loja</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items> </v-toolbar-items>
        </v-toolbar>
        <produto-loja
          v-if="dialogLojaProd"
          :idLoja="lojaId"
          :idUnidade="unidadeId"
          :key="render"
          @close="dialogLojaProd = false"
          @response="getListaLoja"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import EdicaoLoja from "@/components/loja/edicao_loja.vue";
import NovaLoja from "@/components/loja/nova_loja.vue";
import ProdutoLoja from "@/components/loja/produto_loja.vue";
import Mixin from "@/mixins/vuex_mixin.js";
import UnidadeService from "../services/unidade_service";
import LojaService from "../services/loja_service";
import AuthService from "../services/auth_service";
import endpoints from "../router/endpoints";

export default {
  mixins: [Mixin],
  components: {
    EdicaoLoja,
    ProdutoLoja,
    NovaLoja,
  },

  data: () => ({
    render: 0,
    dialogCadastro: false,
    dialogEdicao: false,
    dialogLojaProd: false,
    loading: false,
    search: "",
    totalList: 10,
    options: {
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
      ordenacao: false,
      desc: false,
    },
    lojas: [],
    headers: [
      // {
      //   text: "Loja",
      //   align: "start",
      //   sortable: false,
      //   value: "loja_nome",
      // },
      { text: "id", value: "id_loja", sortable: false },
      { text: "Unidade", value: "desc_unidade", sortable: false },
      { text: "Status", value: "desc_status", sortable: false },
      { text: "Ações", align: "end", value: "actions", sortable: false },
    ],
    services: {
      unidadeService: UnidadeService.build(),
      lojaService: LojaService.build(),
      authService: AuthService.build(),
    },
  }),

  computed: {
    allowCadastrarBtn() {
      return this.services.authService.temPermissao(
        endpoints["UnidadeController::POST->register"]
      );
    },
    allowEditarBtn() {
      return this.services.authService.temPermissao(
        endpoints["UnidadeController::POST->updateUnidadeData/$1"]
      );
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    options: {
      handler() {
        this.options.desc = this.options.sortDesc[0] ?? false;
        this.getListaLoja();
      },
      deep: true,
    },
    search(value, oldValue) {
      if (value.length > 2) {
        this.options.page = 1;
        this.getListaLoja();
      }
      if (value.length == 0 && oldValue.length > 0) {
        this.options.page = 1;
        this.getListaLoja();
      }
    },
  },

  mounted() {
    this.getListaLoja();
  },

  methods: {
    forceRender() {
      this.render += 1;
    },
    getListaLoja() {
      this.loading = true;
      this.lojas = [];
      this.services.lojaService
        .getLojas()
        // .getUnidadePaginatedList({
        //   page: this.options.page,
        //   per_page: this.options.itemsPerPage,
        //   search: this.search,
        //   order: this.options.ordenacao,
        //   desc: this.options.desc,
        // })
        .then(async (response) => {
          if (response.status !== 200) return;
          const { data } = await response.json();
          // const { lojas, pagination } = data;
          // this.totalList = pagination.num_rows;
          const { lojas } = data;
          this.lojas = [];
          this.totalList = lojas.length;
          for (let index = 0; index < this.totalList; index++) {
            const element = lojas[index];
            this.lojas.push({
              id_loja: element.id,
              id_status: element.id_status,
              desc_status: element.status_loja,
              id_unidade: element.id_unidade,
              desc_unidade: element.nome_fantasia,
            });
          }
        })
        .catch((error) => {
          console.error({ error });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    editItem(id) {
      this.lojaId = id;
      this.render = 1;
      this.dialogEdicao = true;
    },
    editLojaProdutos(id_loja, id_unidade) {
      this.lojaId = id_loja;
      this.unidadeId = id_unidade;
      this.render = 1;
      this.dialogLojaProd = true;
    },
    editStatusItem(id_loja, id_unidade, id_status) {
      this.services.lojaService.postUpdateStatusLoja(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.$_ACTIONS_showSnackbarMessage({
                message:
                  body.message || this.$global.messages.internalServerError,
                color: "sucess",
              });
            } else {
              this.$_ACTIONS_showSnackbarMessage({
                message:
                  body.message || this.$global.messages.internalServerError,
                color: "error",
              });
            }
          },
          onError: (message) => {
            this.$_ACTIONS_showSnackbarMessage({
              message: message || this.$global.messages.internalServerError,
              color: "error",
            });
          },
          onEnd: () => {
            this.getListaLoja();
          },
        },
        {
          id: id_loja,
          id_unidade: id_unidade,
          id_status_loja: id_status == 2 ? 1 : 2,
        }
      );
    },
    close() {
      this.dialogCadastro = false;
      this.dialogEdicao = false;
      this.getListaLoja();
    },
  },
};
</script>
<style>
.btn-ativo {
  color: #1a8c1a !important;
}
.btn-inativo {
  color: #ae1111 !important;
  transform: rotate(180deg);
}
</style>
