<template>
  <div>
    <v-card>
      <v-card-title class="headline" style="word-break: normal"
        >Editar procedimento</v-card-title
      >
      <!-- CAMPO TIPO DE PROCEDIMENTO -->
      <v-select
        v-model="TIPO_PROCEDIMENTO_MIXIN_tipo_procedimento"
        :items="TIPO_PROCEDIMENTO_MIXIN_tipos_procedimento"
        :loading="TIPO_PROCEDIMENTO_MIXIN_loading"
        item-text="descricao"
        item-value="id"
        solo
      >
      </v-select>
      <v-card-actions class="justify-end">
        <v-btn text @click="$emit('close')">Cancelar</v-btn>
        <v-btn
          :loading="loading"
          text
          @click="atualizarHorario"
          class="btn-app-primary"
          style="color: #fff !important"
          >Salvar</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import AgendaService from "@/services/agenda_service.js";
import VuexSnackbarMixin from "@/mixins/vuex_snackbar_mixin.js";
import TipoProcedimentoMixin from "@/mixins/tipo_procedimento_mixin.js";
export default {
  mixins: [VuexSnackbarMixin, TipoProcedimentoMixin],
  props: {
    horario: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.TIPO_PROCEDIMENTO_MIXIN_atualizarLista()
  },
  data: () => ({
    loading: false,
  }),
  methods: {
    atualizarHorario() {
      const agendaService = new AgendaService();
      this.loading = true;
      agendaService.atualizarTipoProcedimentoDeAgenda(
        {
          onSucess: (status) => (body) => {
            this.$_ACTIONS_showSnackbarMessage({
              message:
                body.message || this.$global.messages.internalServerError,
              color: status === 200 ? "sucess" : "error",
            });
            this.$emit("response");
          },
          onError: (error) => {
            console.error(error);
            this.$_ACTIONS_showSnackbarMessage({
              message: this.$global.messages.internalServerError,
              color: "error",
            });
          },
          onEnd: () => {
            this.loading = false;
          },
        },
        this.TIPO_PROCEDIMENTO_MIXIN_tipo_procedimento,
        this.horario.id
      );
    },
  },
};
</script>