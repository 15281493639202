<template>
  <div>
    <div class="p-lg-3">
      <div class="row" justify-content-between>
        <h3 class="mt-0 mb-2 font-weight-bold text-left">Acompanhar Filas</h3>
      </div>
    </div>
    <v-row class="mb-2">
      <v-select
        :items="escolherFiltro"
        label="Buscar por"
        item-text="filtro"
        item-value="id"
        v-model="tipoFiltro"
        class="col-3 my-5"
      ></v-select>
      <v-autocomplete
        class="col-4 my-5"
        :items="filas"
        label="Selecionar fila"
        item-text="Nome"
        v-model="filaSelecionada"
        return-object
        :loading="loadingFilas"
        v-if="tipoFiltro == 1"
      ></v-autocomplete>
      <v-text-field
        class="col-3 my-5"
        label="Buscar por Tutor"
        v-model="tutorSelecionado"
        v-if="tipoFiltro == 2"
      ></v-text-field>
      <v-text-field
        class="col-3 my-5"
        label="Buscar por Pet"
        v-model="petSelecionado"
        v-if="tipoFiltro == 3"
      ></v-text-field>
      <v-text-field
        class="col-3 my-5"
        label="Buscar por Id do tutor"
        v-model="tutorId"
        v-if="tipoFiltro == 4"
      ></v-text-field>
      <v-text-field
        class="col-3 my-5"
        label="Buscar por Id do pet"
        v-model="petId"
        v-if="tipoFiltro == 5"
      ></v-text-field>
      <v-btn @click="buscarSenhas" class="btn-style-green" v-if="tipoFiltro"
        >Buscar</v-btn
      >
    </v-row>
    <v-data-table
      :headers="headers"
      :items="senhas"
      :loading="loadingTable"
      :expanded.sync="expanded"
      item-key="SenhaId"
      show-expand
      class="border mt-2"
    >
      <template v-slot:item.Senha="{ item }">
        <button @click="detalharSenha(item)" class="success--text ">
          {{ item.Senha }}
        </button>
      </template>
      <template v-slot:item.status="{ item }">
        <h5 :class="statusColor(item.status)">{{ item.status }}</h5>
      </template>
      <template v-slot:item.realocar="{ item }">
        <v-btn
          @click="realocarSetor(item)"
          small
          color="warning"
          :disabled="!item.IdPet || item.status == 'Em atendimento'"
        >
          realocar
        </v-btn>
      </template>
      <template v-slot:item.urgencia="{ item }">
        <h5 :class="urgenciaAtendimento(item.TipoSenha)">
          {{ item.TipoSenha }}
        </h5>
      </template>
      <!-- <template v-slot:item.obs="{ item }">
        <v-btn @click="dialogObservacoes = true" small color="error">
          Observações
        </v-btn>
        <v-dialog
          v-model="dialogObservacoes"
          v-if="dialogObservacoes"
          max-width="450px"
        >
          <v-card>
            <v-card-title class="text-h5 bg-danger text-white lighten-2">
              Observações
            </v-card-title>

            <h5 class="text-justify pt-5 px-5">
              {{ item.ObservacaoExtra }}
            </h5>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="dialogObservacoes = false">
                Ok
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template> -->
      <template
        v-slot:expanded-item="{ headers, item }"
        class="border border-dark"
      >
        <td
          class="font-weight-bold text-left py-3 bg-white"
          :colspan="headers.length"
        >
          Observações:
          <b class="red--text font-weight-regular">{{
            item.ObservacaoExtra
          }}</b>
        </td>
      </template>
    </v-data-table>
    <v-dialog
      v-model="dialogDetalhesSenha"
      v-if="dialogDetalhesSenha"
      persistent
      fullscreen
    >
      <v-card>
        <v-toolbar style="background-color: #1daf80; color: #fff">
          <v-btn icon dark @click="dialogDetalhesSenha = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            <span class="headline">Histórico da Senha</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items> </v-toolbar-items>
        </v-toolbar>

        <v-data-table
          :headers="headersHistorico"
          :items="historicoSenha"
          :loading="loadingTable"
          class="border m-3"
        >
        </v-data-table>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogEncaminhar"
      v-if="dialogEncaminhar"
      max-width="500px"
    >
      <v-card>
        <v-card-title>Encaminhar</v-card-title>
        <!-- <v-card-text>
          <v-autocomplete
            v-model="selectedTutor"
            :loading="loadingSearch"
            :items="tutores"
            :search-input.sync="searchTutor"
            hide-no-data
            hide-selected
            label="Selecione um tutor"
            item-text="nome"
            return-object
            v-if="!petEncaminhar"
          ></v-autocomplete>
        </v-card-text> -->
        <!-- <v-card-text>
          <v-select
            :items="pets"
            v-model="selectedPet"
            label="Selecione um pet"
            item-text="nome"
            return-object
            v-if="!petEncaminhar"
          ></v-select>
          <v-text-field
            v-else
            :value="petEncaminhar.nome_pet"
            label="Pet"
            disabled
          ></v-text-field>
        </v-card-text> -->

        <v-card-text>
          <v-autocomplete
            :items="filas"
            v-model="especialidadeSelecionada"
            label="Selecione para onde deseja encaminhar"
            item-text="Nome"
            item-value="Id"
            hide-no-data
            hide-selected
          ></v-autocomplete>
        </v-card-text>

        <v-card-text>
          <v-radio-group v-model="urgencia" row>
            <v-radio value="1"></v-radio>
            <span class="ml-n5 mt-1">Normal</span>
            <v-radio value="4" class="ml-8"></v-radio>
            <span class="ml-n5 mt-1">Urgente</span>
          </v-radio-group>
        </v-card-text>

        <textarea
          rows="4"
          cols="57"
          class="p-2 border mb-5"
          placeholder="Observações"
          maxlength="400"
          v-model="observacoes"
        ></textarea>

        <v-card-actions>
          <v-btn color="gray" text @click="dialogEncaminhar = false">
            Fechar
          </v-btn>
          <v-btn
            small
            depressed
            color="warning"
            :disabled="!especialidadeSelecionada || loadingEncaminhar"
            @click="encaminharPara"
          >
            <span v-if="!loadingEncaminhar">Encaminhar</span>
            <v-progress-circular
              v-else
              :width="3"
              :size="20"
              indeterminate
              color="white"
            ></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { global } from "@/config/constants";
import axios from "axios";
import Mixin from "@/mixins/vuex_mixin.js";

export default {
  mixins: [Mixin],
  data() {
    return {
      loadingFilas: false,
      tokenChamefacil: "",
      escolherFiltro: [
        { id: 1, filtro: "Fila" },
        { id: 2, filtro: "Nome do tutor" },
        { id: 3, filtro: "Nome do pet" },
        { id: 3, filtro: "Nome do pet" },
        { id: 4, filtro: "Id do tutor" },
        { id: 5, filtro: "Id do pet" },
      ],
      tipoFiltro: null,
      loadingTable: false,
      filas: [],
      filaSelecionada: {},
      headers: [
        { text: "Senha", value: "Senha" },
        { text: "Setor Atual", value: "SetorAtual" },
        { text: "Enviado por", value: "RedirecionadoPor" },
        { text: "Tutor", value: "NomeTutor" },
        { text: "Pet", value: "NomePet" },
        { text: "Atendido por", value: "EmAtendimentoCom" },
        { text: "Data de criação", value: "data" },
        { text: "Hora de criação", value: "hora" },
        { text: "Urgência", value: "urgencia" },
        { text: "Status", value: "status" },
        { text: "", value: "realocar" },
      ],
      senhas: [],
      historicoSenha: [],
      dialogDetalhesSenha: false,
      headersHistorico: [
        { text: "Senha", value: "Senha" },
        { text: "Fila", value: "FilaNome" },
        { text: "Data", value: "data" },
        { text: "Chegada", value: "chegada" },
        { text: "Chamado", value: "chamado" },
        { text: "Espera", value: "espera" },
        { text: "Término", value: "termino" },
        { text: "Tutor", value: "NomeTutor" },
        { text: "Pet", value: "NomePet" },
        { text: "Atendente", value: "NomeAtendente" },
      ],
      dialogObservacoes: false,
      tutorSelecionado: "",
      petSelecionado: "",
      tutorId: "",
      petId: "",
      observacoes: "",
      dialogEncaminhar: false,
      loadingEncaminhar: false,
      selectEncaminhar: [],
      especialidadeSelecionada: "",
      petEncaminhar: {},
      urgencia: "1",
    };
  },
  watch: {
    tipoFiltro() {
      this.tutorSelecionado = "";
      this.petSelecionado = "";
      this.filaSelecionada = "";
    },
  },
  async beforeMount() {
    this.tokenChamefacil = await localStorage.getItem("tokenChamefacil");
    this.listarFilasDisponiveis();
  },
  methods: {
    buscarSenhas() {
      this.senhas = [];
      this.loadingTable = true;
      const headers = { token: this.tokenChamefacil };

      axios
        .post(
          global.api.baseUrlChamefacil +
            "/Atendimento/ListarSenhasInternasPorFilaInterna",
          {
            TipoFiltro: this.tipoFiltro,
            TutorNome: this.tutorSelecionado,
            PetNome: this.petSelecionado,
            FilaInternaId: this.filaSelecionada.Id,
            TutorCodigo: this.tutorId,
            PetCodigo: this.petId,
          },
          { headers }
        )
        .then(async (resp) => {
          resp.data.senhasInternasEmAtendimento.map((item) =>
            this.senhas.push({
              ...item,
              data: item.Criacao.slice(0, 10)
                .split("-")
                .reverse()
                .join("/"),
              hora: item.Criacao.slice(11),
              status: "Em atendimento",
            })
          );
          resp.data.senhasInternasPendentes.map((item) =>
            this.senhas.push({
              ...item,
              data: item.Criacao.slice(0, 10)
                .split("-")
                .reverse()
                .join("/"),
              hora: item.Criacao.slice(11),
              status: "Aguardando",
            })
          );
          this.loadingTable = false;
        })
        .catch(() => {
          this.$_ACTIONS_showSnackbarMessage({
            message: this.$global.messages.internalServerError,
            color: "error",
          });
          this.loadingTable = false;
        });
    },
    detalharSenha(item) {
      this.loadingTable = true;
      console.log(item);
      const headers = { token: this.tokenChamefacil };
      axios
        .get(
          global.api.baseUrlChamefacil +
            `/atendimento/DetalhesSenha?senhaId=${item.SenhaId}`,
          // `/atendimento/DetalhesSenha?senhaId=294`,
          {
            headers,
          }
        )
        .then(async (resp) => {
          const result = resp.data;
          let espera = "";
          if (result.Sucesso == true) {
            this.historicoSenha = result.HistoricoSenha.map((senha) => {
              if (senha.DataInicioAtendimento) {
                let inicio = new Date(senha.DataCriacao.slice(0, 19));
                let fim = new Date(senha.DataInicioAtendimento.slice(0, 19));
                let diferenca = Math.abs(inicio - fim) / 1000;

                let hours = diferenca > 3600 ? Math.floor(diferenca / 3600) : 0;
                let minutes =
                  diferenca > 60
                    ? Math.floor((diferenca - hours * 3600) / 60)
                    : 0;
                let seconds = diferenca - hours * 3600 - minutes * 60;

                espera =
                  hours.toString().padStart(2, "0") +
                  ":" +
                  minutes.toString().padStart(2, "0") +
                  ":" +
                  seconds.toString().padStart(2, "0");
              }

              return {
                ...senha,
                data: senha.DataCriacao.slice(0, 10)
                  .split("-")
                  .reverse()
                  .join("/"),
                chegada: `${senha.DataCriacao.slice(11, 19)}h`,
                chamado: senha.DataInicioAtendimento
                  ? `${senha.DataInicioAtendimento.slice(11, 19)}h`
                  : "-",
                termino: senha.DataFimAtendimento
                  ? `${senha.DataFimAtendimento.slice(11, 19)}h`
                  : "-",
                espera: espera ? `${espera}h` : "-",
              };
            });
          } else {
            this.$_ACTIONS_showSnackbarMessage({
              message: this.$global.messages.internalServerError,
              color: "error",
            });
            this.loadingTable = false;
          }
        })
        .finally(() => {
          this.loadingTable = false;
        });
      this.dialogDetalhesSenha = true;
    },
    listarFilasDisponiveis() {
      this.loadingFilas = true;
      const headers = { token: this.tokenChamefacil };
      axios
        .get(
          global.api.baseUrlChamefacil +
            "/filainterna/listarPorLojaUsuarioLogado",
          {
            headers,
          }
        )
        .then(async (resp) => {
          this.filas = resp.data.Filas;
        })
        .finally(() => {
          this.loadingFilas = false;
        });
    },
    statusColor(status) {
      if (status == "Finalizada") return "gray--text";
      if (status == "Em atendimento") return "success--text";
      if (status == "Aguardando") return "gray--text";
    },
    urgenciaAtendimento(urgencia) {
      return urgencia.toLowerCase() == "urgente" ? "red--text" : "";
    },
    async realocarSetor(item) {
      this.dialogEncaminhar = false;
      if (item.IdPet) {
        await this.listarFilasDisponiveis();
        console.log(item);
        this.petEncaminhar = item;
        this.dialogEncaminhar = true;
      } else {
        return;
      }
    },
    encaminharPara() {
      let usuarioNome = JSON.parse(
        sessionStorage.vuex
      ).usuario.dados.nome.split(" ");
      usuarioNome = `${usuarioNome[0]} ${usuarioNome[1] ? usuarioNome[1] : ""}`;
      const headers = { token: this.tokenChamefacil };
      axios
        .post(
          global.api.baseUrlChamefacil + "/Atendimento/MoverInterna",
          {
            chamadaInternaId: this.petEncaminhar.ChamadaInternaId,
            filaInternaId: this.especialidadeSelecionada,
            TutorCodigo: this.petEncaminhar.IdTutor,
            PetCodigo: this.petEncaminhar.IdPet,
            RequisicaoId: this.petEncaminhar.RequisicaoId,
            ObservacaoExtra: `${this.petEncaminhar.ObservacaoExtra || ""}
             ${this.observacoes && "|" + usuarioNome + ":"} ${
              this.observacoes
            }`,
            NomePet: this.petEncaminhar.NomePet,
            NomeTutor: this.petEncaminhar.NomeTutor,
            Prioridade: parseInt(this.urgencia),
          },
          { headers }
        )
        .then((resp) => {
          if (resp.data.Sucesso) {
            this.loadingEncaminhar = false;
            this.dialogEncaminhar = false;
            this.observacoes = "";
            this.especialidadeSelecionada = "";
            this.buscarSenhas();
          } else {
            this.loadingEncaminhar = false;
            this.$_ACTIONS_showSnackbarMessage({
              message: resp.data.Mensagem,
              color: "error",
            });
          }
        })
        .catch((error) => {
          this.loadingEncaminhar = false;
          this.dialogEncaminhar = false;
          this.especialidadeSelecionada = "";
          console.log(error.request.statusText);
          this.$_ACTIONS_showSnackbarMessage({
            message: this.$global.messages.internalServerError,
            color: "error",
          });
        });
    },
  },
};
</script>
<style scoped>
.status {
  width: 8rem;
  display: flex;
  justify-content: center;
}
.dialogButtonEncaminhar {
  height: 20px;
  width: 120px;
}
.btn-style-green {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #1daf80 !important;
  height: 30px !important;
  font-size: 12px !important;
  padding: 10px !important;
  border-radius: 50px;
  margin: 20px auto;
}
</style>
