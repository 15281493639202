import HTTPService from '@/services/http_service.js'
import vuex from '@/store/index.js'
import RouterService from "./router_service";

export default class AuthService extends HTTPService {
    routerService = RouterService.build()
    static build() {
        return new AuthService();
    }
    resource = 'auth'
    login (responseFunctions, usuario, perfil) {
        const urlRequest = this.resource + '/'+perfil
        this.request(responseFunctions, 'POST', urlRequest, usuario)
    }

    register () {

    }
    temPermissao(endpointId) {
        const usuario = vuex.getters.getUsuario;
        const { permissoes } = usuario.dados || {};
        return (permissoes || []).includes(endpointId);
    }
    async redirectToUnauthorizedRoute() {
        alert("Você não tem autorização para acessar essa tela.");
        await this.routerService.toDashboard();
    }

}
