import HTTPService from "@/services/http_service.js";

export default class RelatorioAtendimentosService extends HTTPService {
  static build() {
    return new RelatorioAtendimentosService();
  }

  getProfissionaisAssistentesMedicos(unidadeId) {
    return this.get(`profissionais/unidade/${unidadeId}`);
  }

  pdfRelatorioAtendimentosProfissional(
    dataInicial,
    dataFinal,
    unidadeId,
    profissionalId,
    procedimentoId
  ) {
    return this.get(
      `relatorio/atendimento-profissional/pdf/${dataInicial}/${dataFinal}/${unidadeId}/${profissionalId}/${procedimentoId}`
    ).then((resp) => {
      if (resp.status !== 200) return resp;
      return resp.blob();
    });
  }
}
