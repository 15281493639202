<template>
  <div class="d-flex col-12">
     <label>{{ pergunta.texto_pergunta }}</label>
     <v-text-field
        class="col-10"
        v-if="pergunta.obrigatorio"
        v-model="resposta.resposta"
        :rules="regras"
        @blur="respondido"
      ></v-text-field>
     <v-text-field
        class="col-10"
        v-else
        v-model="resposta.resposta"
        :rules="regraSimples"
        @blur="respondido"
      ></v-text-field>
  </div>
</template>
<script>
import Mixin from "@/mixins/vuex_snackbar_mixin.js";

export default {
  mixins: [Mixin],
  props:{
    pergunta:Object,
  },
  beforeMount(){
    this.resposta = {
      id_pergunta:this.pergunta.id,
      resposta:""
    }
  },
  methods:{
    respondido(){
        if (this.resposta.resposta != "") {
          this.resposta.resposta = +this.resposta.resposta
          this.$emit("resposta", this.resposta)
        }
    }
  },
  data: () => ({
    resposta:{},
    regras: [
        value => !!value || 'Obrigatório.',
        value => {
          const pattern = /\d|,/g
          return pattern.test(value) || 'Deve ser valor numérico.'
        }
    ],
    regraSimples: [
        value => {
          const pattern = /\d|,/g
          return pattern.test(value) || 'Deve ser valor numérico.'
        },
    ]
  }),
};
</script>
<style scoped>
.v-progress-circular {
  margin: 1rem;
}
label{
  font-weight: 900;
  font-size: 1.2rem;
}
.v-application {
    line-height: .01;
}
</style>