<template>
    <div>
        <div>Agendamentos a realizar</div>
        <table-agendamentos-futuros :horariosAgendados="horariosAgendados" :loading="loading || !allowsRender"/>
    </div>
</template>

<script>
import DashboardService from "@/services/dashboard_service";
import TableAgendamentosFuturos from '@/components/dashboards/dashboard_paciente/table_agendamentos_futuros.vue';
import VuexMixin from "@/mixins/vuex_mixin";

export default {
    mounted() {
        this.$_GETTERS_usuario.idPaciente && this.updateDashboard();
    },
    data: () => ({
        horariosAgendados: [],
        loading: false
    }),
    watch: {
        $_GETTERS_usuario() {
            // Como essa consulta depende diretamente dos dados de paciente do usuário,
            // para começar a consulta deve-se aguardar o resultado da consulta de dados
            // cadastrais.
            if (this.$_GETTERS_usuario.idPaciente) {
                this.updateDashboard(); 
            }
        }
    },
    mixins: [VuexMixin],
    components: { TableAgendamentosFuturos },
    computed: {
        allowsRender () {
            return !!this.$_GETTERS_usuario.idPaciente;
        }
    },
    methods: {
        updateDashboard() {
            const dashboardService = new DashboardService();
            this.loading = true;
            dashboardService.getDashboardPaciente({
                onSucess: status => body => {
                    if (status === 200) {
                        this.horariosAgendados = body.data.map(obj => {
                            obj.data = (date => {
                                let dateArr = date.split('-');
                                return `${dateArr[2]}/${dateArr[1]}/${dateArr[0]}`
                            })(obj.data)
                            return obj
                        });
                    } else if (status > 299){
                        this.$_ACTIONS_showSnackbarMessage({
                            message: body.message || this.$global.messages.internalServerError,
                            color: status === 200 ? 'sucess' : 'error'
                        })
                    }
                },
                onError: error => {
                    console.error(error);
                    this.$_ACTIONS_showSnackbarMessage({
                        message: this.$global.messages.internalServerError,
                        color: 'error'
                    });
                },
                onEnd: () => {
                    this.loading = false;
                }
            }, this.$_GETTERS_usuario.idPaciente)
        }
    }
}
</script>
