// import CEPService from "../services/cep_service"

export default {
  install: function(Vue) {
    // 1. add global method or property
    // Vue.myGlobalMethod = ...

    // 2. add a global asset
    // Vue.directive('my-directive', {})

    // 3. add an instance method
    Vue.prototype.$buscarCep = (obj, cep) => {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
        // mode: 'no-cors'
      };

      fetch(`https://viacep.com.br/ws/${cep}/json`, requestOptions)
        .then(async (response) => {
          const body = await response.json();
          obj.logradouro = body.logradouro;
          obj.estado = body.uf;
          obj.cidade = body.localidade;
          obj.bairro = body.bairro;
          obj.complemento = body.complemento;
          // obj.estado = body.estado
        })
        .then((result) => console.log(result))
        .catch((error) => console.log("error", error));

      // var xhr = new XMLHttpRequest();
      // xhr.withCredentials = true;

      // xhr.addEventListener("readystatechange", function() {
      //   if(this.readyState === 4) {
      //     const body = JSON.parse(this.responseText);

      //     // console.log();
      //   }
      // });

      // xhr.open("GET", );

      // xhr.send();

      // // const service  = new CEPService()
      // // service.buscaCep({
      // //     onSucess: (status) => (body) => {
      // //         console.log({body})
      // //         if(status === 200){
      // //             obj.logradouro = body.logradouro
      // //             obj.uf = body.uf
      // //             obj.cidade = body.localidade
      // //             obj.bairro = body.bairro
      // //             obj.complemento = body.complemento
      // //             obj.estado = body.estado
      // //         }

      // //     },

      // //     onError: function () {
      // //         //
      // //     },

      // //     onEnd: function () {
      // //         //
      // //     }
      // // }, cep)
    };
  },
};
