<template>
  <div v-if="!loadingInicial">
    <div class="p-lg-3">
      <div class="row justify-content-between">
        <h3 class="mt-0 mb-2 font-weight-bold text-left">
          Agendar Tele-Consulta
        </h3>
      </div>
    </div>

    <form class="p-lg-3 row">
      <v-row class="p-lg-3">
        <div class="col-lg-6 form-group clearfix mb-0">
          <label class="mb-0">Selcione o Pet</label>
          <v-select
            style="padding-top: 0 !important"
            :items="pets"
            v-model="petId"
          />
        </div>

        <div class="col-lg-6 form-group clearfix mb-0">
          <label class="mb-0">Selcione a Especialidade</label>
          <v-select
            style="padding-top: 0 !important"
            :items="especialidades"
            v-model="especialidadeId"
          />
        </div>

        <div class="col-lg-6 form-group clearfix mb-0">
          <label class="mb-0">Selcione o Procedimento</label>
          <v-select
            style="padding-top: 0 !important"
            :items="procedimentos"
            v-model="procedimentoId"
          />
        </div>
        <v-row class="p-lg-3">
          <div class="mx-auto text-right col-6">
            <button
              :disabled="!agendarIsAllowed"
              @click.prevent="agendarConsulta"
              class="btn btn-app-primary btn-rounded font-weight-bold col-12"
            >
              <span v-if="!loading"> Agendar Tele-Consulta </span>
              <v-progress-circular
                v-else
                indeterminate
                color="white"
              ></v-progress-circular>
            </button>
          </div>
        </v-row>
      </v-row>
    </form>
  </div>
  <div v-else>
    <v-progress-circular
      width="3"
      size="80"
      indeterminate
      color="green"
    ></v-progress-circular>
  </div>
</template>

<script>
import Mixin from "@/mixins/vuex_snackbar_mixin.js";
import VuexUsuarioMixin from "@/mixins/vuex_usuario_mixin.js";
import PetsService from "@/services/pets_service.js";
import EspecialidadeService from "@/services/especialidade_service.js";
import ProcedimentoEspecialidadeService from "@/services/procedimento_especialidade_service.js";
import AgendamentoService from "@/services/agendamento_service.js";

export default {
  mixins: [Mixin, VuexUsuarioMixin],
  components: {},
  data: () => ({
    rules: {
      required: (value) => !!value || "Campo obrigatório.",
    },
    loadingSearch: false,
    loading: false,
    loadingInicial: false,
    petId: 0,
    pets: [],
    especialidades: [],
    especialidadeId: 0,
    procedimentos: [],
    procedimentoId: 0,
    petService: PetsService.build(),
    especialidadeService: EspecialidadeService.build(),
    procedimentoEspecialidadeService: ProcedimentoEspecialidadeService.build(),
    agendamentoService: AgendamentoService.build(),
  }),
  watch: {
    especialidadeId(espeicalidadeId) {
      this.loadingInicial = true;
      this.setProcedimentos(espeicalidadeId).finally(() => {
        this.loadingInicial = false;
      });
    },
  },
  mounted() {
    this.loadingInicial = true;
    this.iniciar().finally(() => {
      this.loadingInicial = false;
    });
  },
  computed: {
    agendarIsAllowed() {
      return this.petId && this.especialidadeId && this.procedimentoId;
    },
  },
  methods: {
    async iniciar() {
      Promise.all([this.setPets(), this.setEspecialidades()]);
    },
    async setPets() {
      await this.petService.getPetsByCliente().then(async (response) => {
        if (response.status !== 200) return;
        const { data } = await response.json();
        const { pets } = data;
        this.pets = pets.map((pet) => {
          return {
            text: pet.nome_pet,
            value: parseInt(pet.id_pet),
          };
        });
      });
    },
    async setEspecialidades() {
      await this.especialidadeService
        // .getEspecialidadeList()
        .getEspecialidadeListByUnidadeId(24)
        .then(async (response) => {
          if (response.status !== 200) return;
          const { data } = await response.json();
          this.especialidades = data.map((especialidade) => {
            return {
              text: especialidade.descricao,
              value: especialidade.id_especialidade,
            };
          });
        });
    },
    async setProcedimentos() {
      await this.procedimentoEspecialidadeService
        .getProcedimentoEspecialidadePaginatedList({
          page: null,
          per_page: null,
          order: null,
          desc: null,
          search: null,
          filter: { id_especialidade: this.especialidadeId },
        })
        .then(async (response) => {
          if (response.status !== 200) return;
          const { data } = await response.json();
          const { especialidade_procedimentos } = data;
          this.procedimentos = especialidade_procedimentos.map(
            ({ descricao_procedimento, id_procedimento }) => {
              return {
                text: descricao_procedimento,
                value: parseInt(id_procedimento),
              };
            }
          );
        });
    },
    getDataConsulta() {
      return {
        id_cliente: this.$_GETTERS_usuario.dados.clienteId,
        id_pet: this.petId,
        id_procedimento: this.procedimentoId,
        id_especialidade: this.especialidadeId,
      };
    },
    async agendarConsulta() {
      this.loadingInicial = true;

      await this.agendamentoService
        .agendarTeleConsulta(this.getDataConsulta())
        .then((response) => {
          if (response.status !== 200) {
            this.$_ACTIONS_showSnackbarMessage({
              message: "Erro ao agendar consulta",
              color: "error",
            });
            return;
          }
          this.$_ACTIONS_showSnackbarMessage({
            message: "Consulta agendada com sucesso",
            color: "sucess",
          });
          this.resetForm();
        });
      this.loadingInicial = false;
    },
    resetForm() {
      this.petId = 0;
      this.especialidadeId = 0;
      this.procedimentoId = 0;
    },
  },
};
</script>
