import HTTPService from "@/services/http_service.js";

export default class InternacaoService extends HTTPService {
  static build() {
    return new InternacaoService();
  }

  async listarInternados(
    { page, per_page, pet, executado, status },
    unidadeId
  ) {
    console.log(page + per_page);
    const filter = {
      // page,
      // per_page,
      pet,
      executado,
      status,
    };
    const resp = await this.get(
      `requisicao/internacao/listagem2/${unidadeId}`,
      filter
    );

    return resp;
  }

  async requisitarSaidaInternacao(clienteId, petId, unidadeId) {
    return this.post(
      `requisicao/saida-internacao/${clienteId}/${petId}/${unidadeId}`
    );
  }

  async getIdFormularioAltaMedica(tipoSaida) {
    const resp = await this.get(
      `requisicao/internacao/formulario/${tipoSaida}`
    );

    return resp;
  }

  async getFormulario(formId) {
    const resp = await this.get(`formulario/${formId}`);

    return resp;
  }

  registerAltaMedica(
    responseFunctions,
    procedimentoId,
    petId,
    clienteId,
    unidadeId,
    consulta
  ) {
    this.jsonRequest(
      responseFunctions,
      "POST",
      `requisitar/alta-internacao/${procedimentoId}/${clienteId}/${petId}/${unidadeId}`,
      consulta
    );
  }
}
