import HTTPService from "@/services/http_service.js";
import { global } from "@/config/constants";

export default class TipoProcedimentoService extends HTTPService {
  resource = "tipo-procedimento";

  static build() {
    return new TipoProcedimentoService();
  }

  getTipoProcedimentoPaginatedList({ page, per_page, search, order, desc }) {
    return this.get(this.resource, {
      page,
      per_page,
      search,
      order,
      desc,
    });
  }

  async getTipoProcedimentos() {
    return await this.get(this.resource, {
      page: null,
      per_page: null,
      search: null,
      order: null,
      desc: null,
    });
  }

  async register(tipo_procedimento) {
    const headers = new Headers();

    const token = JSON.parse(sessionStorage.getItem("vuex")).token;
    headers.append("Authorization", "Bearer " + token);

    const requestOptions = {
      method: "POST",
      body: tipo_procedimento,
      headers: headers,
    };
    return await fetch(
      `${global.api.baseURL}/${this.resource}`,
      requestOptions
    ).then(async (response) => {
      return response;
    });
  }

  async editarTipoProcedimento(tipoProcedimentoId, tipoProcedimento) {
    const headers = new Headers();
    const token = JSON.parse(sessionStorage.getItem("vuex")).token;
    headers.append("Authorization", "Bearer " + token);

    const requestOptions = {
      method: "POST",
      body: tipoProcedimento,
      headers: headers,
    };
    return await fetch(
      `${global.api.baseURL}/${this.resource}/${tipoProcedimentoId}`,
      requestOptions
    ).then(async (response) => {
      return response;
    });
  }

  async deletarTipoProcedimento(tipoProcedimentoId) {
    const headers = new Headers();
    const token = JSON.parse(sessionStorage.getItem("vuex")).token;
    headers.append("Authorization", "Bearer " + token);

    const requestOptions = {
      method: "POST",
      headers: headers,
    };
    return fetch(
      `${global.api.baseURL}/${this.resource}/${tipoProcedimentoId}/deletar`,
      requestOptions
    ).then(async (response) => {
      return response;
    });
  }
}
