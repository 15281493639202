export default {
    data: () => ({
        CEP_MIXIN_logradouro: '',
        CEP_MIXIN_bairro: '',
        CEP_MIXIN_cidade: '',
        CEP_MIXIN_estado: '',
        CEP_MIXIN_cep: '',
        CEP_MIXIN_loading: false,
    }),
    watch: {
        CEP_MIXIN_cep () {
            const cepRegex = /^\d{5}-\d{3}$/
            if (cepRegex.test(this.CEP_MIXIN_cep)) {
                const cep = this.CEP_MIXIN_cep
                const xhr = new XMLHttpRequest();
                this.CEP_MIXIN_loading = true
                 xhr.addEventListener("load", () => {
                    let body = JSON.parse(xhr.response)
                    if (xhr.status === 200) {
                        body = body.data
                        this.CEP_MIXIN_logradouro = body.logradouro
                        this.CEP_MIXIN_bairro = body.bairro
                        this.CEP_MIXIN_cidade = body.cidade
                        this.CEP_MIXIN_estado = body.estado
                    } else {
                        this.CEP_MIXIN_logradouro = ''
                        this.CEP_MIXIN_bairro = ''
                        this.CEP_MIXIN_cidade = ''
                        this.CEP_MIXIN_estado = ''
                    }
                    this.CEP_MIXIN_loading = false
                 });
                 const url = this.$global.api.baseURL
                 xhr.open("GET", `${url}/cep/${cep}`);
                 xhr.send();
            } else {
                this.CEP_MIXIN_logradouro = ''
                this.CEP_MIXIN_bairro = ''
                this.CEP_MIXIN_cidade = ''
                this.CEP_MIXIN_estado = ''
            }
        }
    }
}