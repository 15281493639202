<template>
  <div class="col-lg-12" >
    <div v-if="compromissosEmAtraso.length">
      <v-card class="ma-4" elevation="2">
        <v-card-title>Clientes atrasados</v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item-group>
              <template v-for="(compromisso, i) in compromissosEmAtraso">
                <v-list-item :key="i">
                  <template>
                    <card-cliente-atrasado
                        :hora-inicial="compromisso.horarioInicio"
                        :hora-final="compromisso.horarioFinal"
                        :nome-paciente="compromisso.nomePaciente"
                        :procedimento="compromisso.procedimento"
                        :id-agendamento="compromisso.idAgendamento"
                        @iniciar-consulta="iniciarConsulta"
                        @remover="removerDaListaDeAtrasados($event)"
                    />
                  </template>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import CardClienteAtrasado from "@/components/dashboards/dashboard_medico/clientes_atrasados/card_cliente_atrasado";
export default {
  components: { CardClienteAtrasado },
  props: {
    compromissos: {type: Array, required: true},
  },
  computed: {
    compromissosEmAtraso () {
      return this.compromissos.filter(compromisso => compromisso.emAtraso)
    }
  },
  methods: {
    iniciarConsulta() {
      this.$emit('iniciar-consulta')
    },
    removerDaListaDeAtrasados(event) {
      this.$emit('remover', event)
    },
  }
};
</script>
<style scoped>
.titulo-cards-fila span {
  margin-right: 1rem;
  font-size: 1rem;
}

.titulo-cards-fila hr {
  display: block;
  width: 100%;
  color: #fff;
  margin: 0;
}
</style>