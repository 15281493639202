<template>
  <v-row
      class="mx-0 my-3"
      style="background-color: #efefef;border-radius: 0 4px 4px 0;"
  >
    <v-col cols="3" class="p-0">
      <div
          class="px-2 rounded"
          style="border-left-color: #bbcb3a;border-left-style: solid; border-left-width: 6px;"
      >
        <v-list-item-content class="pb-0 pt-1">
          <h6 class="text-h6 text-left my-0">
            {{ horaInicial }}
          </h6>
        </v-list-item-content>
        <p class="text-left mb-0">Duração:</p>
        <v-list-item-subtitle class="text-subtitle text-left">
          <p class="mb-0">
            {{
              horaInicial +
              " - " +
              horaFinal
            }}
          </p>
        </v-list-item-subtitle>
      </div>
    </v-col>
    <v-col cols="6" class="p-0">
      <div class="px-2">
        <v-list-item-content class="pb-0">
          <h4 class="text-left" style="color: #000">
            {{ nomePaciente }}
          </h4>
          <p class="text--primary text-left">
            {{ procedimento }}
          </p>
        </v-list-item-content>
      </div>
    </v-col>
    <v-col cols="3" class="d-flex align-items-center">
      <div class="px-2">
        <v-btn
            color="green darken-4"
            dark
            class="mt-3"
            @click="iniciarConsulta"
        >
          Atender paciente
        </v-btn>
        <v-btn
            color="red darken-4"
            dark
            class="mt-3"
            icon
            @click="removerDaListaDeAtrasados"
        >
          <v-icon>mdi-call-split</v-icon>
        </v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    horaInicial: {type: String, required: true, validator: str => /^\d{2}:\d{2}$/.test(str)},
    horaFinal: {type: String, required: true, validator: str => /^\d{2}:\d{2}$/.test(str)},
    nomePaciente: {type: String, required: true},
    procedimento: {type: String, required: true},
    idAgendamento: {type: Number, required: true},
  },
  methods: {
    iniciarConsulta() {
      this.$emit('iniciar-consulta')
    },
    removerDaListaDeAtrasados() {
      this.$emit('remover', { idAgendamento: this.idAgendamento })
    }
  }
}
</script>