import { render, staticRenderFns } from "./edicao_loja.vue?vue&type=template&id=086b678a&scoped=true&"
import script from "./edicao_loja.vue?vue&type=script&lang=js&"
export * from "./edicao_loja.vue?vue&type=script&lang=js&"
import style0 from "./edicao_loja.vue?vue&type=style&index=0&id=086b678a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "086b678a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VForm } from 'vuetify/lib/components/VForm';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VAutocomplete,VForm,VProgressCircular,VSwitch})
