import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { global } from '@/config/constants.js'
import vuetify from './plugins/vuetify';
import viacep from './plugins/viacep';
import VueMask from 'v-mask'

Vue.use(VueMask);

Vue.use(viacep);

Vue.filter('data', function (data) {
    if (!data) return '';
    let date = data.split('-');
    
    let dia = date[2]
    let mes = date[1]
    let ano = date[0]

    let dataAtualFormatada = dia + '/' + mes + '/' + ano
    return dataAtualFormatada;
});

Vue.config.productionTip = false
Vue.prototype.$global = global

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
