<template>
    <v-card>
      <v-card-title class="headline">
        <h3 class="text-break">
          Tem certeza que deseja apagar o formulário {{form.titulo}} ?
        </h3>
      </v-card-title>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn text @click="cancelar"> Cancelar </v-btn>

        <v-btn
            color="red darken-2"
            dark
            @click="confirmar"
        >
          Apagar
        </v-btn>
      </v-card-actions>
    </v-card>
</template>

<script>
import FormularioService from '@/services/formulario_service.js'
import VuexSnackbarMixin from "@/mixins/vuex_snackbar_mixin.js";

export default {
  mixins:[VuexSnackbarMixin],
  props:{
    form:Object
  },
  methods: {
    cancelar() {
      this.$emit('cancel')
    },
    confirmar() {
      const formularioService = new FormularioService()
      formularioService.desativar({
        onSucess: status => body => {
          if(status===200){
            this.$_ACTIONS_showSnackbarMessage({
                message: body.message,
                color:'sucess' 
            })
            this.$emit('confirm')
          }else{
            this.$_ACTIONS_showSnackbarMessage({
              message: body.message || this.$global.messages.internalServerError,
                color: status === 200 ? 'sucess' : 'error'
            })
            this.$emit('cancel')
          }
        },
        onError: error => {
          this.$_ACTIONS_showSnackbarMessage({
            message: this.$global.messages.internalServerError,
                color: 'error'
            })
            console.log(error)
            this.$emit('cancel')
        },
        onEnd: () => {
        }
      },this.form.id )
    },
   
  }
}
</script>