import HTTPService from '@/services/http_service.js'

export default class FotosService extends HTTPService {
    resource = 'galeria'
    register(responseFunctions, foto){
        this.request(responseFunctions, 'POST',  this.resource, foto)
    }
    buscar_fotos_classificadas (responseFunctions, idPaciente) {
        this.request(responseFunctions, 'GET', 'classificacao', null, idPaciente)
    }

    buscar_fotos_nao_classificadas (responseFunctions, idPaciente ) {
        this.request(responseFunctions, 'GET',  this.resource,null, idPaciente)

    }

    classificar (responseFunctions, params) {
        this.request(responseFunctions, 'POST','/classificacao',params )
    }

    getFoto (responseFunctions, idFoto) {
        this.request(responseFunctions, 'GET',this.resource+"/"+idFoto, null)
    }

    deleteFoto (responseFunctions, idFoto) {
        this.request(responseFunctions, 'POST',this.resource+"/"+idFoto+'/deletar', {id:idFoto})
    }

    deleteFotoClassificada (responseFunctions, idFoto) {
        this.request(responseFunctions, 'POST',"classificacao/"+idFoto+'/deletar', {id:idFoto})
    }
    

} 