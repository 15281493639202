<template>
  <div class="d-flex flex-wrap col-12">
    <div class="d-flex flex-colunm align-items-center col-12">
      <v-textarea
        class="col-9"
        rows="3"
        v-model="pergunta.texto_pergunta"
        :required="pergunta.obrigatorio"
        :messages="pergunta.obrigatorio ? 'obrigatória' : 'não obrigatória'"
        :disabled="disabled"
      ></v-textarea>

      <div class="col-2 mr-4">
        <v-switch
          v-model="mudar_obrigatoria"
          :disabled="disabled"
          label="Obrigatória"
        ></v-switch>
        <v-switch
          v-model="ativa"
          :disabled="disabled"
          :label="ativa ? 'Ativa' : 'Inativa'"
        ></v-switch>
      </div>

      <v-text-field
        type="number"
        v-model="ordem"
        :disabled="disabled"
        label="Ordem"
        class="col-1 ml-2"
      ></v-text-field>
    </div>

    <div class="d-flex flex-wrap col-12">
      <div v-for="(opt_check, i) in opts_check" :key="i">
        <div class="d-flex">
          <v-checkbox></v-checkbox>
          <v-text-field
            :disabled="disabled"
            v-model="opt_check.descricao"
          ></v-text-field>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Mixin from "@/mixins/vuex_snackbar_mixin.js";

export default {
  mixins: [Mixin],
  props: {
    pergunta: Object,
    disabled: Boolean,
  },
  beforeMount() {
    console.log(this.pergunta);
    this.mudar_obrigatoria = this.pergunta.obrigatorio;
    this.ordem = this.pergunta.ordem;
    this.ativa = this.pergunta.ativo == 1 ? true : false;
    this.opts_check = Object.values(this.pergunta.opcoes);
  },
  data: () => ({
    mudar_obrigatoria: null,
    ordem: 0,
    ativa: null,
    opts_check: [],
  }),
  watch: {
    mudar_obrigatoria(value) {
      this.pergunta.obrigatorio = value;
    },
    ordem(value) {
      this.pergunta.ordem = value;
    },
    ativa(value) {
      this.pergunta.ativo = value ? 1 : 0;
    },
  },
};
</script>
<style scoped>
.v-progress-circular {
  margin: 1rem;
}
</style>
