<template>
  <div class="w-100">
    <v-row
      class="mx-0 my-3"
      style="background-color: #efefef; border-radius: 0 4px 4px 0"
    >
      <v-col cols="4" class="p-0">
        <div
          class="px-2 rounded"
          :style="{ borderLeftColor: cor }"
          style="border-left-style: solid; border-left-width: 6px"
        >
          <v-list-item-content class="pb-0 pt-1">
            <h6 class="text-h6 text-left my-0">
              {{ horaInicial }}
            </h6>
          </v-list-item-content>
          <p class="text-left mb-0">Duração:</p>
          <v-list-item-subtitle class="text-subtitle text-left">
            <p class="mb-0 tempo-procedimento">
              {{ horaInicial + " - " + horaFinal }}
            </p>
          </v-list-item-subtitle>
        </div>
      </v-col>
      <v-col cols="8" class="p-0">
        <div class="px-2">
          <v-list-item-content class="pb-0">
            <h4 class="text-left consultaPaciente mt-0" style="color: #000">
              {{ nomePaciente }}
            </h4>
            <v-list-item-subtitle
              class="text--primary text-left descricao-procedimento pb-0"
              v-text="procedimento"
            ></v-list-item-subtitle>
          </v-list-item-content>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    procedimento: { type: String, required: true },
    nomePaciente: { type: String, required: true },
    horaInicial: {
      type: String,
      required: true,
      validator: (str) => /^\d{2}:\d{2}$/.test(str),
    },
    horaFinal: {
      type: String,
      required: true,
      validator: (str) => /^\d{2}:\d{2}$/.test(str),
    },
    cor: { type: String, required: true },
  },
};
</script>

<style scoped>
.tempo-procedimento {
  font-size: 0.8rem;
}

.consultaPaciente {
  font-size: 1rem;
}

.descricao-procedimento {
  font-size: 0.8rem;
}
</style>