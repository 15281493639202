<template>
  <div>
    <v-form class="p-lg-3 row" ref="form" lazy-validation>
      <div class="col-lg-12 row">
        <!-- CAMPO DE DATA INÍCIO -->
        <div class="col-lg-3 form-group clearfix mb-3">
          <v-text-field
            label="Data"
            v-model="data"
            clear-icon="clear"
            required
            style="margin: 0 5px; padding-top: 0px !important"
            :type="'date'"
            :outlined="false"
          />
        </div>
        <!-- CAMPO DE HORA INÍCIO -->
        <div class="col-lg-3 form-group clearfix mb-3">
          <v-text-field
            label="Hora Início"
            v-model="horaInicio"
            :type="'text'"
            required
            v-mask="'##:##'"
            placeholder="Hora Início"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>
        <!-- CAMPO DE HORA FINAL -->
        <div class="col-lg-3 form-group clearfix mb-s3">
          <v-text-field
            label="Hora Final"
            v-model="horaFinal"
            :type="'text'"
            v-mask="'##:##'"
            required
            placeholder="Hora Final"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>
      </div>
      <!-- CAMPO DE PROCEDIMENTO -->
      <div class="col-lg-12 form-group clearfix mb-3 text-dark">
        <label for="Procedimento">Tipo de procedimento</label>
        <v-select
          v-model="TIPO_PROCEDIMENTO_MIXIN_tipo_procedimento"
          :items="TIPO_PROCEDIMENTO_MIXIN_tipos_procedimento"
          :loading="TIPO_PROCEDIMENTO_MIXIN_loading"
          required
          label="Tipo de Procedimento"
          item-text="descricao"
          item-value="id"
          color="black"
        >
        </v-select>
      </div>
      <div class="col-lg-12 form-group clearfix mb-3 text-dark">
        <label for="Procedimento">Paciente</label>

        <v-autocomplete
          v-model="pacienteID"
          :items="pacientes"
          :loading="loading"
          color="#1daf80"
          hide-no-data
          hide-details
          :search-input.sync="search"
          label="Buscar por nome"
          item-text="nome"
          item-value="id"
        >
          <template v-slot:item="data">
            <v-list-item-content @click="paciente = data.item">
              <v-list-item-title v-html="data.item.nome"></v-list-item-title>
              <v-list-item-subtitle
                v-html="data.item.cpf"
              ></v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </div>
      <div class="col-lg-12">
        <v-autocomplete
          v-model="procedimento"
          :items="procedimentos"
          :loading="loading_procedimento"
          placeholder=""
          background-color="white"
          :search-input.sync="search_procedimentos"
          label="Procedimento"
          item-text="descricao"
          return-object
          color="black"
        >
        </v-autocomplete>
      </div>

      <!-- BOTÃO SUBMIT FORMULÁRIO -->
      <div class="row-lg-12">
        <v-btn class="btn-app-primary" @click="criarAgenda" :loading="loading">
          <span>Continuar</span>
        </v-btn>
      </div>
    </v-form>
  </div>
</template>
<script>
import ImportacaoService from "@/services/importacao_service.js";
import ValidationService from "@/services/validation_service.js";
import VuexSnackbarMixin from "@/mixins/vuex_snackbar_mixin.js";
import VuexUsuarioMixin from "@/mixins/vuex_usuario_mixin.js";
import TipoProcedimentoMixin from "@/mixins/tipo_procedimento_mixin";
import PacienteService from "@/services/paciente_service.js";
import ProcedimentoService from "@/services/procedimento_service.js";
import Mixin from "@/mixins/vuex_mixin.js";

export default {
  mixins: [VuexSnackbarMixin, VuexUsuarioMixin, TipoProcedimentoMixin, Mixin],
  beforeMount() {
    this.validationService = ValidationService;
    this.TIPO_PROCEDIMENTO_MIXIN_atualizarLista();
  },
  data: () => ({
    loading: false, // Guarda o estado do loading de submit
    valid: false, // Guarda a variável que guarda o estado da validação do formulário
    data: "",
    horaInicio: "",
    horaFinal: "",
    validationService: {},
    pacientes: [],
    pacienteID: -1,
    paciente: {},
    procedimento: null,
    procedimentos: null,
    loading_procedimento: false,
    search_procedimentos: null,
    search: null,
    defaultFotos: {
      F:
        "https://thumbs.dreamstime.com/z/female-default-avatar-profile-icon-woman-face-silhouette-person-placeholder-vector-illustration-female-default-avatar-profile-icon-189495134.jpg",
      M:
        "https://us.123rf.com/450wm/thesomeday123/thesomeday1231709/thesomeday123170900021/85622928-default-avatar-profile-icon-grey-photo-placeholder-illustrations-vectors.jpg?ver=6",
    },
  }),
  watch: {
    search_procedimentos(descricao) {
      const procedimentoService = new ProcedimentoService();
      procedimentoService.searchByDescricao(this)(descricao);
    },
    search(nome) {
      if (!nome) {
        this.pacientes = [];
      }
      if (!nome || !(nome = nome.trim()) || nome.length < 3 || this.loading) {
        return;
      }
      this.loading = true;
      this.pacientes = [];
      const pacienteService = new PacienteService();
      pacienteService.getPacienteByName(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.pacientes = body.data.pacientes;
            } else {
              this.pacientes = [];
            }
          },
          onError: (error) => {
            console.error(error);
            this.pacientes = [];
          },
          onEnd: () => {
            this.loading = false;
          },
        },
        nome
      );
    },
  },
  methods: {
    criarAgenda() {
      const requestBody = {
        agenda: {
          id_medico: 1,
          data: this.data,
          hora_inicio: this.horaInicio,
          hora_final: this.horaFinal,
          id_tipo_procedimento: this.TIPO_PROCEDIMENTO_MIXIN_tipo_procedimento,
          adicionado_por: this.$_GETTERS_usuario.id,
        },
        agendamento: {
          id_paciente: this.paciente.id,
          id_cliente: this.paciente.id_cliente,
          alterado_por: this.$_GETTERS_usuario.id,
          adicionado_por: this.$_GETTERS_usuario.id,
        },
        consulta: {
          respostas: [
            {
              id_pergunta: 1,
              resposta: "",
            },
          ],
        },
      };

      this.loading = true;
      new ImportacaoService().register(
        {
          onSucess: (status) => (body) => {
            this.$_ACTIONS_showSnackbarMessage({
              message:
                body.message || this.$global.messages.internalServerError,
              color: status === 200 ? "sucess" : "error",
            });
            status === 200 && this.$emit("response");

            this.$refs.form.reset();
            this.$router.push({
              path: "/prontuarios",
              query: {
                search: this.paciente.nome,
              },
            });
          },
          onError: () => {
            this.$_ACTIONS_showSnackbarMessage({
              message: this.$global.messages.internalServerError,
              color: "error",
            });
          },
          onEnd: () => {
            this.loading = false;
          },
        },
        requestBody
      );
    },
  },
};
</script>

<style scoped>
.btn-app-primary {
  background-color: rgb(19, 125, 91) !important;
  border-color: rgb(19, 125, 91);
  color: white;
}
.form-group label {
  text-align: left !important;
  display: block;
}
</style>
