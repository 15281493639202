<template>
  <v-card>
    <v-card-title class="text-left py-6">
      <h2 class="font-weight-bold">
        Legado Vetus
      </h2>
    </v-card-title>

    <div class="px-6">
      <v-tabs v-model="tab" background-color="transparent" color="basil">
        <v-tab :key="'Cliente'">
          Cliente
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item :key="'Cliente'">
          <v-card color="basil" flat>
            <v-card-text>
              <vetus-cliente />
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </v-card>
</template>

<script>
import VetusCliente from "@/components/legado_vetus/vetus-cliente.vue";
import Mixin from "@/mixins/vuex_mixin.js";

export default {
  components: { VetusCliente },
  mixins: [Mixin],
  data: () => ({
    tab: null,
  }),
};
</script>

<style scoped>
.nestor-color {
  background-color: #1daf80 !important;
}
</style>
