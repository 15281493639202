<template>
  <v-card :elevation="0">
    <v-form ref="form" class="mt-4">
      <div class="col-12 row">
        <!-- <v-text-field
                    v-model="tipo_procedimento"
                    label="Tipo procedimento"
                ></v-text-field> -->
        <!-- <div class="col-4"> -->
        <!-- <v-autocomplete
            v-model="procedimento"
            :items="procedimentos"
            :loading="loading_procedimento"
            hide-no-data
            placeholder=""
            background-color="white"
            :search-input.sync="search"
            label="Procedimento"
            item-text="descricao"
            item-value="id"
            color="black"
            solo
          >
          </v-autocomplete> -->
        <!-- </div> -->
        <div class="col-4">
          <v-select
            v-model="medicoId"
            :items="medicos"
            :loading="loadingListarMedicos"
            label="Médico"
            item-text="nome"
            item-value="medicoId"
            color="black"
            solo
            >/
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title v-html="item.nome"></v-list-item-title>
                <v-list-item-subtitle
                  class="mt-1"
                  v-html="item.email"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-select>
        </div>

        <div class="col-4">
          <v-text-field v-model="data" label="Data" type="date"></v-text-field>
        </div>
      </div>
      <div class="col-12 row justify-end">
        <div class="d-flex flex-lg-row justify-content-center col-2">
          <v-btn
            :disabled="bloqueiaFiltro"
            color="teal darken-1"
            class="mr-4"
            style="color: white !important"
            @click="reset"
          >
            Limpar Filtro
          </v-btn>
          <v-btn
            :disabled="bloqueiaFiltro"
            color="success"
            class="mr-4"
            @click="filtrar"
          >
            Filtrar
          </v-btn>
        </div>
      </div>
    </v-form>
  </v-card>
</template>

<script>
import ProcedimentoService from "@/services/procedimento_service.js";
import PacienteService from "@/services/paciente_service.js";
import MedicoService from "../../services/medico_service";
import DadosCadastraisMixin from "@/mixins/dados_cadastrais_mixin.js";

export default {
  mixins: [DadosCadastraisMixin],
  data: () => ({
    tipo_procedimento: "",
    procedimento: "",
    paciente: "",
    data: "",
    search: "",
    search_paciente: "",
    loading_procedimento: false,
    loading_paciente: false,
    procedimentos: [],
    pacientes: [],
    medicoService: MedicoService.build(),
    medicos: [],
    medicoId: 0,
    loadingListarMedicos: false,
  }),
  async mounted() {
    this.loadingListarMedicos = true;
    this.medicos = await this.medicoService.getMedicoListByUnidadeId(
      this.$_GETTER_Unidade.id
    );
    this.loadingListarMedicos = false;
  },
  watch: {
    search(descricao) {
      const procedimentoService = new ProcedimentoService();
      procedimentoService.searchByDescricao(descricao);
    },
    search_paciente(nome) {
      // const pacienteService = new PacienteService();
      // pacienteService.searchByNome(this)(nome)
      if (!nome) {
        this.pacientes = [];
        this.paciente = "";
      }
      if (!nome || !(nome = nome.trim()) || nome.length < 3 || this.loading) {
        return;
      }
      this.loading = true;
      this.pacientes = [];
      const pacienteService = new PacienteService();
      pacienteService.getPacienteByName(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.pacientes = body.data.pacientes;
            } else {
              this.pacientes = [];
            }
          },
          onError: (error) => {
            console.error(error);
            this.pacientes = [];
          },
          onEnd: () => {
            this.loading = false;
          },
        },
        nome
      );
    },
  },
  computed: {
    bloqueiaFiltro() {
      return (
        this.tipo_procedimento == "" &&
        this.procedimento == "" &&
        this.paciente == "" &&
        this.data == "" &&
        !this.medicoId
      );
    },
  },
  methods: {
    filtrar() {
      console.log("opa, filtrando...");
      console.log(this.procedimento);
      console.log(this.paciente);
      console.log(this.data);
      this.$emit("filtro", [
        this.procedimento,
        this.paciente,
        this.data,
        this.medicoId,
      ]);
    },
    reset() {
      this.$refs.form.reset();
      this.$emit("limparFiltro");
    },
  },
};
</script>
