<template>
  <div class="w-100">
    <span class="row">
      <v-sheet class="mx-auto mt-4" elevation="0" max-width="100%">
        <v-slide-group
          v-model="indexCompromissoSelecionado"
          class="pa-4"
          center-active
          show-arrows
        >
          <v-slide-item
            v-for="(compromisso, i) in compromissosNaoAtrasados"
            :key="i"
            v-slot="{ active, toggle }"
          >
            <div @click="openProntuariosAgenda">
              <component
                :active="active"
                :is="'CardPacienteAtual'"
                :procedimento="compromisso.procedimento"
                :nome-paciente="compromisso.nomePaciente"
                :paciente_id="compromisso.paciente_id"
                :hora-inicial="compromisso.horarioInicio"
                :hora-final="compromisso.horarioFinal"
                :tipo-procedimento="compromisso.tipoProcedimento"
                @select="selecionarAgendamento(toggle, compromisso)"
                @unselect="limparSelecao"
              />
            </div>
          </v-slide-item>
        </v-slide-group>
        <v-dialog
          v-model="dialog"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <prontuarios-agenda
            :atendimento="procedimentoAtendido"
            :formularios="formularios"
            :consultas="prontuarios"
            @close="dialog = false"
            @reloadConsultas="reloadConsultas"
          >
          </prontuarios-agenda>
        </v-dialog>

        <v-expand-transition>
          <v-sheet v-if="indexCompromissoSelecionado != null" tile>
            <div
              class="row mx-auto mb-4 py-6 px-4 shadow bg-cinza rounded"
              style="max-width: 300px"
            >
              <div class="col-md-12">
                <v-row class="mt-6" justify="center">
                  <v-btn
                    color="grey darken-2"
                    dark
                    @click.stop="registrarPacienteAusente"
                  >
                    Paciente ausente
                  </v-btn>
                </v-row>
              </div>
            </div>
          </v-sheet>
        </v-expand-transition>
      </v-sheet>
    </span>
  </div>
</template>

<script>
import CardPacienteAtual from "@/components/dashboards/dashboard_medico/fila_atual/card_paciente_atual";
import ProntuariosAgenda from "@/components/prontuarios/prontuarios_agenda";
import FormularioService from "@/services/formulario_service.js";
import ConsultaService from "@/services/consulta_service.js";

export default {
  components: {
    CardPacienteAtual,
    ProntuariosAgenda,
  },
  props: {
    compromissos: { type: Array, required: true },
  },
  beforeMount() {
    this.selectNext();
  },

  data: () => ({
    prontuarios: [],
    formularios: [],
    formulario: "",
    procedimento: "",
    indexCompromissoSelecionado: 0,
    idAgendamentoSelecionado: null,
    loadingFormularios: false,
    telaCarregada: false,
    dialog: false,
    procedimentoAtendido: {},
  }),
  computed: {
    compromissosNaoAtrasados() {
      return this.compromissos.filter((compromisso) => !compromisso.emAtraso);
    },
    procedimentoPossuiFormularios() {
      return this.formularios.length > 0;
    },
  },
  methods: {
    reloadConsultas() {
      this.selectNext();
    },
    openProntuariosAgenda() {
      this.carregarTiposFormularios();
      this.listProntuarios();
      this.dialog = true;
    },
    selectNext() {
      let compromisso = {};
      for (
        let index = 0;
        index < this.compromissosNaoAtrasados.length;
        index++
      ) {
        compromisso = this.compromissosNaoAtrasados[index];
        if (compromisso.idConsulta == null) {
          this.selecionarAgendamento(function () {}, compromisso);
          this.procedimento = compromisso.idProcedimento;
          this.idAgendamentoSelecionado = compromisso.idAgendamento;
          this.indexCompromissoSelecionado = index;
          this.carregarTiposFormularios();
          this.$emit("paciente", { nome: compromisso.nomePaciente });
          break;
        }
      }
    },
    carregarTiposFormularios() {
      this.loadingFormularios = true;
      this.formularios = [];
      const formularioService = new FormularioService();
      formularioService.getAll(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.telaCarregada = true;
              body.data.formularios.forEach((form) => {
                let formulario = [];
                (formulario["id"] = form.id),
                  (formulario["titulo"] = form.titulo);
                formulario["descricao"] = form.descricao;
                formulario["quantidade_perguntas"] = form.quantidade_perguntas;
                this.formularios.push(formulario);
              });
            }
          },
          onError: (error) => {
            console.log(error);
          },
          onEnd: () => {
            this.loadingFormularios = false;
          },
        },
        {
          procedimento: this.procedimento,
          ativo: 1,
        }
      );
    },
    iniciarConsulta() {
      this.$emit("iniciar-consulta", {
        idAgendamentoSelecionado: this.idAgendamentoSelecionado,
        idFormulario: this.formulario,
      });
    },
    listProntuarios() {
      const consultaService = new ConsultaService();
      consultaService.getListByPacienteId(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.prontuarios = body.data.consultas;
            }
          },
          onError: (error) => {
            console.log(error);
          },
          onEnd: () => {
            this.dialogLoading = false;
          },
        },
        this.procedimentoAtendido.paciente_id
      );
    },

    limparSelecao() {
      this.indexCompromissoSelecionado = null;
      this.idAgendamentoSelecionado = null;
    },
    registrarPacienteAusente() {
      this.$emit("paciente-ausente", {
        idAgendamentoSelecionado: this.idAgendamentoSelecionado,
      });
    },
    selecionarAgendamento(toggle, compromisso) {
      toggle();
      this.procedimento = compromisso.idProcedimento;
      this.procedimentoAtendido = compromisso;
      this.idAgendamentoSelecionado = compromisso.idAgendamento;
      this.carregarTiposFormularios();
      this.$emit("paciente", { nome: compromisso.nomePaciente });
    },
    componenteCard(active, idConsulta) {
      return {
        "00": "CardPacientePosterior",
        "01": "CardPacienteAnterior",
        10: "CardPacienteAtual",
        11: "CardPacienteAtual",
      }[`${+active}${+!!idConsulta}`];
    },
  },
};
</script>