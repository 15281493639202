import HTTPService from "@/services/http_service.js";

export default class FilaEspecialidadeService extends HTTPService {
  resource = "fila-especialidade";

  static build() {
    return new FilaEspecialidadeService();
  }

  async getListByEspecialidadeId(idEspecialidade, { page, per_page }) {
    return await this.get(this.resource, {
      especialidade: idEspecialidade,
      page,
      per_page,
    });
  }
}
