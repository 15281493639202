<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <div class="px-2 text-right">
      <v-btn text @click="editandoSessao3 = !editandoSessao3">
        <v-icon v-if="editandoSessao3"> mdi-close </v-icon>
        <v-icon v-else> mdi-pencil </v-icon>
      </v-btn>
    </div>
    <div class="row">
      <div class="col-md-3">
        <label for="CEP">CEP</label>
        <v-text-field
          v-mask="'#####-###'"
          v-model="cep"
          @blur="searchCep"
          placeholder="CEP"
          :disabled="!editandoSessao3"
          style="padding-top: 0 !important"
        ></v-text-field>
      </div>
      <div class="col-md-3">
        <label for="Rua">Logradouro</label>
        <v-text-field
          v-model="logradouro"
          :type="'text'"
          :disabled="!editandoSessao3"
          style="padding-top: 0px !important"
        ></v-text-field>
      </div>
      <div class="col-md-3">
        <label for="Bairro">Bairro</label>
        <v-text-field
          v-model="bairro"
          :type="'text'"
          :disabled="!editandoSessao3"
          style="padding-top: 0 !important"
        ></v-text-field>
      </div>
      <div class="col-md-3">
        <label for="Numero">Numero</label>
        <v-text-field
          v-model="numero"
          :type="'text'"
          :disabled="!editandoSessao3"
          style="padding-top: 0 !important"
        ></v-text-field>
      </div>
      <div class="col-md-3">
        <label for="complemento">Complemento</label>
        <v-text-field
          v-model="complemento"
          :type="'text'"
          :disabled="!editandoSessao3"
          style="padding-top: 0 !important"
        ></v-text-field>
      </div>
      <div class="col-md-3">
        <label for="Referencia">Referência</label>
        <v-text-field
          v-model="referencias"
          :type="'text'"
          :disabled="!editandoSessao3"
          style="padding-top: 0 !important"
        ></v-text-field>
      </div>
      <div class="col-md-3">
        <label for="Cidade">Cidade</label>
        <v-text-field
          v-model="cidade"
          :type="'text'"
          :disabled="!editandoSessao3"
          style="padding-top: 0 !important"
        ></v-text-field>
      </div>
      <div class="col-md-3">
        <label for="Estado">Estado</label>
        <v-text-field
          :disabled="!editandoSessao3"
          v-model="estado"
          :type="'text'"
          style="padding-top: 0 !important"
        ></v-text-field>
      </div>
    </div>
    <div class="px-2 text-right">
      <v-btn
        :disabled="!editandoSessao3"
        color="success"
        @click="salvar"
        :loading="DADOS_CADASTRAIS_MIXIN_loading"
      >
        Salvar
      </v-btn>
    </div>
  </v-form>
</template>

<script>
import DadosCadastraisMixin from "@/mixins/dados_cadastrais_mixin.js";
import CEPMixin from "@/mixins/cep_mixin.js";

export default {
  mixins: [DadosCadastraisMixin, CEPMixin],
  data: () => ({
    editandoSessao3: false,
    cep: "",
    numero: "",
    complemento: "",
    referencias: "",
    valid: false,
  }),
  watch: {
    cep() {
      let cep = this.cep.replaceAll("-", "").replaceAll(".", "");
      if (cep.length == 8) {
        this.searchCep();
      }
    },
  },
  mounted() {
    const dados = this.$_GETTERS_usuario.dados;
    this.cep = dados.cep;
    this.logradouro = dados.logradouro;
    this.cidade = dados.cidade;
    this.estado = dados.estado;
    this.bairro = dados.bairro;
    this.numero = dados.numero;
    this.complemento = dados.complemento;
    this.referencias = dados.referencias;
  },
  methods: {
    searchCep() {
      let cep = this.cep.replaceAll("-", "").replaceAll(".", "");
      if (cep.length == 8) {
        this.$buscarCep(this, this.cep);
      }
    },
    salvar() {
      if (!this.$refs.form.validate() || !this.valid) {
        return;
      }
      const dados = {};
      [
        "cep",
        "numero",
        "complemento",
        "referencias",
        "logradouro",
        "cidade",
        "estado",
        "bairro",
      ].forEach((param) => {
        dados[param] = this[param];
      });
      // TODO: Implementar validações
      this.DADOS_CADASTRAIS_MIXIN_updateDadosCadastrais(dados);
      this.$emit("reloadData");
    },
  },
};
</script>
