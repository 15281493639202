import HTTPService from "@/services/http_service.js";
import { global } from "@/config/constants";

export default class ConvenioService extends HTTPService {
  resource = "convenio";

  static build() {
    return new ConvenioService();
  }

  /**
     @returns {Promise<Response>}
     */
  getConvenioPaginatedList({ page, per_page, search, order, desc }) {
    return this.get(this.resource, {
      page,
      per_page,
      search,
      order,
      desc,
    });
  }

  getConvenios() {
    return this.get(this.resource, { page: 1, per_page: 1000 });
  }

  async getTipoConvenio() {
    return await this.get("tipo-convenio");
  }

  async register(convenio) {
    const headers = new Headers();

    const token = JSON.parse(sessionStorage.getItem("vuex")).token;
    headers.append("Authorization", "Bearer " + token);

    const requestOptions = {
      method: "POST",
      body: convenio,
      headers: headers,
    };
    return await fetch(
      `${global.api.baseURL}/${this.resource}`,
      requestOptions
    ).then(async (response) => {
      return response;
    });
  }

  async editarConvenio(convenioId, convenio) {
    return this.post(`${this.resource}/${convenioId}`, {
      ...convenio,
    });
  }

  async deletarConvenio(convenioId) {
    const headers = new Headers();
    const token = JSON.parse(sessionStorage.getItem("vuex")).token;
    headers.append("Authorization", "Bearer " + token);

    const requestOptions = {
      method: "POST",
      headers: headers,
    };
    return await fetch(
      `${global.api.baseURL}/${this.resource}/${convenioId}/deletar`,
      requestOptions
    ).then(async (response) => {
      return response;
    });
  }

  getProcedimentosPorUnidadeConvenio(unidadeId, convenioId) {
    return this.get(`procedimento/unidade/${unidadeId}/convenio/${convenioId}`);
  }

  getConvenioPorUnidadeProcedimento(unidadeId, procedimentoId) {
    return this.get(
      `convenio/unidade/procedimento/${unidadeId}/${procedimentoId}`
    );
  }

  getProcedimentos(unidadeId, search) {
    return this.get(`procedimento-exame/${unidadeId}?descricao=${search}`);
  }

  getProcedimentosInternacao(unidadeId, search) {
    return this.get(`procedimento-internacao/${unidadeId}?descricao=${search}`);
  }

  getProcedimentosCirurgia(unidadeId, search) {
    return this.get(`procedimento-cirurgia/${unidadeId}?descricao=${search}`);
  }

  getMedicacoes(unidadeId, search) {
    return this.get(`procedimento-medicacao/${unidadeId}?descricao=${search}`);
  }

  getProcedimentosConsultas(unidadeId, search) {
    if (search >= 3) {
      return this.get(`procedimento-consulta/${unidadeId}?descricao=${search}`);
    } else {
      return this.get(`procedimento-consulta/${unidadeId}`);
    }
  }
}
