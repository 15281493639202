import { mapActions, mapGetters } from "vuex";

export default {
    computed: {
        ...mapGetters(
            {
                $_GETTERS_usuario: 'getUsuario',
            }
        ),
    },
    methods: {
        ...mapActions({
            $_ACTIONS_setUsuario: 'setUsuario',
        }),
    }
}