<template>
  <div>
    <v-card min-width="400px" style="width: 100%">
      <v-card-title>
        <span class="headline">Marcar agendamento</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <div class="row">
            <div class="col-lg-12">
              <label for="nome">Tutor</label>
              <v-text-field
                v-model="pet.clienteNome"
                readonly
                placeholder="Seu nome completo"
                style="padding-top: 0 !important"
              ></v-text-field>
            </div>
            <div class="col-lg-12">
              <v-autocomplete
                v-model="procedimento"
                :items="procedimentos"
                :loading="loading_procedimento"
                hide-no-data
                placeholder=""
                background-color="white"
                :search-input.sync="search_procedimentos"
                label="Procedimento"
                item-text="descricao"
                return-object
                color="black"
                solo
              >
              </v-autocomplete>
            </div>
            <div class="col-lg-12">
              <label for="nome">Nome</label>
              <v-text-field
                v-model="pet.nome"
                readonly
                placeholder="Seu nome completo"
                style="padding-top: 0 !important"
              ></v-text-field>
            </div>
          </div>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('close')"> Fechar </v-btn>
        <v-btn
          v-if="allowMarcarAgendamento"
          style="background-color: #1daf80; color: #fff !important"
          text
          :disabled="!procedimento"
          @click="agendar"
        >
          <span v-if="!loadingAgendamento"> Agendar </span>
          <v-progress-circular
            v-if="loadingAgendamento"
            indeterminate
            color="white"
          ></v-progress-circular>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import PacienteService from "@/services/paciente_service.js";
import ProcedimentoService from "@/services/procedimento_service.js";
import AgendaService from "@/services/agenda_service.js";
import Mixin from "@/mixins/vuex_mixin.js";
import AuthService from "../../services/auth_service";
import endpoints from "../../router/endpoints";

export default {
  mixins: [Mixin],
  components: {},
  props: {
    detalhesAgenda: {
      type: Object,
      validator: (obj) => {
        return obj ? !!obj.idAgenda : false;
      },
    },
    pet: {
      type: Object,
      required: true,
    },
  },
  beforeMount() {
    this.tipo_procedimento = this.detalhesAgenda.idTipoProcedimento;
  },
  data: () => ({
    colorSnackbar: "",
    dialogCadastroPaciente: false,
    loading: false,
    loadingAgendamento: false,
    pacientes: [],
    pacienteID: null,
    procedimento: "",
    procedimentos: [],
    tipo_procedimento: "",
    search_procedimentos: null,
    loading_procedimento: false,
    search: null,
    defaultFotos: {
      F:
        "https://thumbs.dreamstime.com/z/female-default-avatar-profile-icon-woman-face-silhouette-person-placeholder-vector-illustration-female-default-avatar-profile-icon-189495134.jpg",
      M:
        "https://us.123rf.com/450wm/thesomeday123/thesomeday1231709/thesomeday123170900021/85622928-default-avatar-profile-icon-grey-photo-placeholder-illustrations-vectors.jpg?ver=6",
    },
    services: {
      authService: AuthService.build(),
    },
  }),
  computed: {
    allowCadastrarNovoPacienteBtn() {
      return this.services.authService.temPermissao(
        endpoints["PacienteController::POST->register"]
      );
    },
    allowMarcarAgendamento() {
      return this.services.authService.temPermissao(
        endpoints["AgendamentoController::POST->registerAgendamento"]
      );
    },
    pets() {
      const pets = this.pacienteEscolhido.pets || [];
      pets.sort((a, b) => (a.nome === b.nome ? 0 : a.nome > b.nome ? 1 : -1));
      return pets;
    },
    pacienteEscolhido() {
      return this.pacientes.find(({ id }) => id === this.pacienteID) || {};
    },
  },
  methods: {
    agendar() {
      console.log({ pet: this.pet });

      const idAgenda = this.detalhesAgenda.idAgenda;
      const request = {
        id_paciente: this.pet.pacienteId,
        id_procedimento: this.procedimento.id,
        id_cliente: this.pet.clienteId,
        id_agenda: idAgenda,
        id_pet: ~~this.pet.petId,
      };
      const agendaService = new AgendaService();
      this.loadingAgendamento = true;
      agendaService.agendar(
        {
          onSucess: (status) => (body) => {
            request.id_agendamento = body.data.id_agendamento;
            if (status === 200) {
              this.$emit("submit", {
                status,
                message: body.message,
                request,
                procedimento: this.procedimento.descricao,
              });
              this.pacienteID = null;
              this.procedimento = "";
            } else {
              this.$_ACTIONS_showSnackbarMessage({
                message: this.$global.messages.internalServerError,
                color: "error",
              });
            }
          },
          onError: (error) => {
            this.$emit("submit", { error });
          },
          onEnd: () => {
            this.loadingAgendamento = false;
          },
        },
        request
      );
    },
  },
  watch: {
    search_procedimentos(descricao) {
      const procedimentoService = new ProcedimentoService();
      procedimentoService.searchByDescricao(this)(descricao);
    },
    search(nome) {
      if (!nome) {
        this.pacientes = [];
      }
      if (!nome || !(nome = nome.trim()) || nome.length < 3 || this.loading) {
        return;
      }
      this.loading = true;
      this.pacientes = [];
      const pacienteService = new PacienteService();
      pacienteService.getPacienteByName(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.pacientes = body.data.pacientes;
            } else {
              this.pacientes = [];
            }
          },
          onError: (error) => {
            console.error(error);
            this.pacientes = [];
          },
          onEnd: () => {
            this.loading = false;
          },
        },
        nome
      );
    },
  },
};
</script>
